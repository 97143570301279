import * as React from 'react';
import { ArticleType, MoreDetails } from '../../types/article';
import styles from "./Article.module.scss"
import { Accordion, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Article component definition
function Article(props: {
    content: ArticleType
}) {
    // Navigation hook
    const navigate = useNavigate();

    // Function to generate accordion items
    function generateAccordion(v: MoreDetails, i: number) {
        return <Accordion.Item key={i} eventKey={i + ""}>
            <Accordion.Header>{v.name}</Accordion.Header>
            <Accordion.Body>
                {v.block_description && <div className={styles.block_description}>{v.block_description}</div>}
                {v.descriptions &&
                    <>
                        <ul className={styles.descriptions}>
                            {v.descriptions.map((description, idx) => {
                                return <li key={idx}>{description}</li>
                            })}
                        </ul>
                    </>}
                {v.causes &&
                    <>
                        <div className={styles.subtitle}>Causes</div>
                        <ul>
                            {v.causes.map((cause, idx) => {
                                return <li key={idx}>{cause}</li>
                            })}
                        </ul>
                    </>}
                {v.symptoms &&
                    <>
                        <div className={styles.subtitle}>Symptoms</div>
                        <ul>
                            {v.symptoms.map((symptom, idx) => {
                                return <li key={idx}>{symptom}</li>
                            })}
                        </ul>
                    </>}
                {v.button && v.buttonLink &&
                    <>
                        <button onClick={() => { navigate(v.buttonLink!) }} className={styles.button}>{v.button} <i className="fa-solid fa-arrow-right"></i></button>

                    </>}
            </Accordion.Body>
        </Accordion.Item>
    }

    // Rendering Article component
    return (
        <>
            {/* Title */}
            <div className={styles.title}>
                {props.content.title}
            </div>
            {/* Aspect Description */}
            <div className={styles.aspect_description}>
                {props.content.title === "Dementia Prevention" &&
                    <><a target="_blank" rel="noopener noreferrer" href='https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(20)30367-6/fulltext'>
                        <u>Research</u>
                    </a> has shown that targeting risk factors can prevent up to 40% of dementia cases.</>}
                {props.content.aspect_description}
            </div>
            {/* Keypoints */}
            <div className={styles.keypoints}>
                <ul>
                    {props.content.keyPoints.map((v, i) => {
                        return <li key={i}>{v}</li>
                    })}
                </ul>
            </div>
            {/* Accordion */}
            <Accordion defaultActiveKey="" flush>
                <Row>
                    {/* Splitting accordions into two columns */}
                    <Col xs={12} lg={6}>
                        {props.content.more_details.map((v, i) => {
                            return i % 2 === 0 ? generateAccordion(v, i) : <React.Fragment key={i} />
                        })}
                    </Col>
                    <Col xs={12} lg={6}>
                        {props.content.more_details.map((v, i) => {
                            return i % 2 === 1 ? generateAccordion(v, i) : <React.Fragment key={i} />
                        })}
                    </Col>
                </Row>
            </Accordion>
            {/* Button */}
            {props.content.button && props.content.buttonLink &&
                <button onClick={() => { navigate(props.content.buttonLink!) }} className={styles.button}>{props.content.button} <i className="fa-solid fa-arrow-right"></i></button>}
        </>
    );
}

export default Article;
