import * as React from 'react';
import styles from "./HomePrediction.module.scss"
import { Col, Container, Row } from 'react-bootstrap';
import predict from "../../../../pictures/homePrediction/predict.png"
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../types/paths';

// HomePrediction component definition
function HomePrediction() {
    // Navigation hook
    const navigate = useNavigate();

    // Rendering HomePrediction component
    return (
        <>
            {/* Background section */}
            <div className={styles.bg}>
                <Container>
                    {/* Row containing image and text */}
                    <Row className={styles.row}>
                        {/* Column for the image */}
                        <Col xs={12} lg={4}>
                            {/* Image */}
                            <img className={styles.image} src={predict} alt="Hello" />
                        </Col>
                        {/* Column for the text */}
                        <Col xs={12} lg={8}>
                            <div className={styles.wrapper}>
                                {/* Small word */}
                                <div className={styles.smallword}><i className="fa-solid fa-star"></i> Powered by Light Gradient Boost Machine</div>
                                {/* Title */}
                                <div className={styles.title}>Risk Prediction</div>
                                {/* Subtitle */}
                                <div className={styles.subtitle}>Predict your risk of developing dementia based on your lifestyle.</div>
                                {/* Message */}
                                <div className={styles.message}>Note that the prediction result is intended for advice only and not as a formal diagnosis. Please consult a doctor for a comprehensive assessment if needed.</div>
                                {/* Button */}
                                <button onClick={() => navigate(paths.Prediction)} className={styles.mainButton}>Check Your Risk <i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default HomePrediction;
