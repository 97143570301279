import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface windowWidthState {
    width: number
}

const initialState: windowWidthState = {
    width: window.innerWidth
}

export const windowWidthSlice = createSlice({
    name: 'windowWidth',
    initialState,
    reducers: {
        windowWidthReducer: (state:windowWidthState, action: PayloadAction<number>) => {
            state.width = action.payload
        }
    }
})

// const { originalPropertyName: newVariableName } = objectToDestructure;
export const { windowWidthReducer: windowWidthActionCreator } = windowWidthSlice.actions;