import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { Outlet } from 'react-router'; // Importing Outlet from react-router
import NavBar from './components/Navbar/NavBar'; // Importing the NavBar component
import { useAppDispatch } from '../redux/hooks'; // Importing custom hook useAppDispatch from Redux
import { windowWidthActionCreator } from '../redux/windowWidthSlice'; // Importing windowWidthActionCreator from Redux slice
import { windowScrollActionCreator } from '../redux/windowScrollSlice'; // Importing windowScrollActionCreator from Redux slice

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false); // State to manage authentication status
    const isAuthenticatedRef = useRef(false); // Using ref to avoid re-renders for authentication
    const [username, setUsername] = useState(''); // State to store username
    const dispatch = useAppDispatch(); // Dispatch function from Redux

    useEffect(() => {
        // Effect to handle window resize and dispatch window width action
        const handleResize = () => {
            // Dispatch window width to Redux store on resize
            dispatch(windowWidthActionCreator(window.innerWidth));
        };
        // Effect to handle window scroll and dispatch window scroll action
        const handleScroll = () => {
            // Dispatch scroll position to Redux store on scroll
            dispatch(windowScrollActionCreator(window.scrollY));
        };
        // Adding event listeners for scroll and resize
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        // Cleaning up event listeners on component unmount
        return () => {
            // Clean up event listeners to prevent memory leaks
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch]); // Dependency array with dispatch

    // Effect to prompt for password and authenticate user
    useEffect(() => {
        // // Effect to handle authentication
        // if (!isAuthenticatedRef.current) {
        //     // If not authenticated, prompt for username and password
        //     const enteredUsername = window.prompt("Please enter username: ");
        //     const enteredPassword = window.prompt("Please enter password: ");
        //     // Check credentials
        //     if (enteredUsername === "admin27" && enteredPassword === "%#*5R&_N#$$f!") {
        //         setIsAuthenticated(true); // Set authentication status to true
        //         isAuthenticatedRef.current = true; // Update ref for authentication
        //         setUsername(enteredUsername); // Set the username
        //     }
        // }
    }, []); // Empty dependency array for running only once

    // if (!isAuthenticated) {
    //     return null; // If not authenticated, return null
    // }

    return (
        // Return NavBar and Outlet if authenticated
        <>
            <NavBar />  {/* Render the navigation bar */}
            <Outlet />  {/* Render the current route's component*/}
        </>
    );
}

export default App; // Exporting the App component
