import * as React from 'react';
import styles from "./ToggleSwitch.module.scss";
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { games, paths } from '../../types/paths';

function ToggleSwitch(props: {
    checked: string
}) {
    const navigate = useNavigate();
    const handleChange = (event) => {
        // Update the URL based on the selected game
        const game = event.target.value;
        navigate(`${paths.BrainHealth}/${game}`, { replace: true });
    };
    return (
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className={styles.switch}>
                            <input checked={props.checked === games.Crossword} onChange={handleChange} id="switch_y" name="tripple" type="radio" value={games.Crossword} className={styles.switch_input} />
                            <label htmlFor="switch_y" className={`${styles.switch_label} ${styles.switch_label_y}`}>Crossword</label>
                            <input checked={props.checked === games.Tetris} onChange={handleChange} id="switch_i" name="tripple" type="radio" value={games.Tetris} className={styles.switch_input} />
                            <label htmlFor="switch_i" className={`${styles.switch_label} ${styles.switch_label_i}`}>Tetris</label>
                            <input checked={props.checked === games.Sudoku} onChange={handleChange} id="switch_n" name="tripple" type="radio" value={games.Sudoku} className={styles.switch_input} />
                            <label htmlFor="switch_n" className={`${styles.switch_label} ${styles.switch_label_n}`}>Sudoku</label>
                            <span className={styles.switch_selector}></span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {props.checked === games.Crossword && <div className={styles.title}>Crossword</div>}
                        {props.checked === games.Tetris && <div className={styles.title}>Tetris</div>}
                        {props.checked === games.Sudoku && <div className={styles.title}>Sudoku</div>}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ToggleSwitch;
