import * as React from 'react';
import * as d3 from "d3";
import styles from "./DementiaTypesBubbleChart.module.scss"

function DementiaTypesBubbleChart(props) {
    const svgRef = React.useRef(null);
    const tooltipRef = React.useRef(null);
    const data_url = props.data_url

    React.useEffect(() => {
        async function fetchDataAndCreateSVG() {
            try {
                const response = await fetch(data_url, { method: 'GET' });
                const data = {
                    name: "Dementia Types",
                    children: await response.json()
                };
                // Create a color scale
                const colorScale = d3.scaleOrdinal()
                    .domain(data.children.map(d => d.name))
                    .range(['#6EB2FB', '#F49F42', '#78B9A7', '#E95C47', '#9888A5', '#F6CF71', '#72ACBE']);

                // Set up pack layout
                const pack = d3.pack()
                    .size([425, 430])
                    .padding(3);

                // Convert data to hierarchical structure
                const root = d3.hierarchy(data)
                    .sum(d => d.percentage);

                // Create SVG element
                const svg = d3.select(svgRef.current);
                // Compute pack layout
                const nodes = pack(root).descendants();

                // Draw circles
                const parentCircle = svg.selectAll("parentCircle")
                    .data([root])
                    .enter().append("circle")
                    .attr("cx", root.x)
                    .attr("cy", root.y)
                    .attr("r", root.r) // Set initial radius to 0 for animation
                    .attr("fill", "none")
                    .attr("stroke", "white")
                    .style("stroke-width", "2px");

                const largestChild = nodes[1];
                const largestChildCirle = svg.selectAll("largestChildCirle")
                    .data([largestChild])
                    .enter().append("circle")
                    .attr("cx", largestChild.x)
                    .attr("cy", largestChild.y)
                    .attr("r", largestChild.r)
                    .attr("fill", d => colorScale(largestChild.data.name))
                    .attr("stroke", "white")
                    .style("stroke-width", "2px");

                // Add label inside the largest child circle
                const largestChildLabel = svg.append("text")
                    .attr("class", "largestChildLabel")
                    .attr("x", largestChild.x)
                    .attr("y", largestChild.y)
                    .attr("dy", "0.35em") // Adjust vertical alignment if needed
                    .style("text-anchor", "middle")
                    .style("font-size", "16px"); // Adjust font size if needed
                //.text(`${largestChild.data.Name}${largestChild.data.Percentage}%`);

                largestChildLabel.append("tspan")
                    .text(largestChild.data.name);

                largestChildLabel.append("tspan")
                    .attr("x", largestChild.x)
                    .attr("dy", "1.2em") // Adjust line spacing if needed
                    .text(`${largestChild.data.percentage}%`);

                // Draw circles
                const circles = svg.selectAll("circle")
                    .data(nodes)
                    .enter().append("circle")
                    .attr("cx", d => d.x)
                    .attr("cy", d => d.y)
                    .attr("r", 0) // Set initial radius to 0 for animation
                    .attr("fill", d => colorScale(d.data.name))
                    .attr("stroke", "white")
                    .style("stroke-width", "2px")
                    .on("mouseover", handleMouseOver)
                    .on("mouseout", handleMouseOut)
                    .transition()
                    .duration(d => d.depth > 0 ? 800 : 0)
                    .attr("r", d => d.r); // Transition to actual radius

                // Tooltip
                const tooltip = d3.select(tooltipRef.current);

                // Function to handle mouseover event
                function handleMouseOver(event, d) {
                    d3.select(event.target)
                        .raise() // Bring the focused circle to the front
                        .transition()
                        .duration(200)
                        .attr("r", d => d.r * 1.2); // Increase radius by 20%

                    tooltip.transition()
                        .duration(200)
                        .style("opacity", .9);
                    tooltip.html(`${d.data.name}<br>${d.data.percentage}%`)
                        .style("left", (event.pageX + 10) + "px")
                        .style("top", (event.pageY - 30) + "px");
                }

                // Function to handle mouseout event
                function handleMouseOut(event, d) {
                    d3.select(event.target)
                        .transition()
                        .duration(200)
                        .attr("r", d => d.r); // Restore original radius

                    tooltip.transition()
                        .duration(500)
                        .style("opacity", 0);
                }

                // Handle appending and positioning of legend rectangles.
                const legend = d3.select(".legend");
                legend.selectAll("rect")
                    .data(data.children)// Assuming 'data' has a 'children' property for hierarchical data.
                    .enter()
                    .append("rect")
                    .attr("x", 10)
                    .attr("y", (_, i) => 10 + i * 20)// Position each rectangle vertically with spacing.
                    .attr("width", 15)
                    .attr("height", 12)
                    .attr("fill", d => colorScale(d.name));// Color each rectangle using a color scale based on 'name'.

                // Handle appending and positioning of legend text labels.
                legend.selectAll("text")
                    .data(data.children)
                    .enter()
                    .append("text")
                    .attr("x", 30)
                    .attr("y", (_, i) => 20 + i * 20)// Position text next to its corresponding rectangle.
                    .text(d => d.name)// Display the name of each data item.
                    .attr("fill", "white")// Set text color to white.
            } catch (e) {
                // Handle errors by displaying an error message within the chart container.
                document.querySelector(".bubbleChartWrapper").innerHTML = `<div>${e}</div>`
            }
        }
        fetchDataAndCreateSVG()
    }, [])

    return (
        <div className="bubbleChartWrapper" style={{
            margin: props.margin + "px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <table>
                <tbody>
                    <tr>
                        <td className="3">
                            <svg ref={svgRef} width={props.width / 7 * 4.5} height={props.height}></svg>
                            <div ref={tooltipRef} className={styles.tooltip_bubble} style={{ opacity: "0" }}></div>
                        </td>
                        <td><svg className="legend" width={props.width / 7 * 2.5} height="250"></svg></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default DementiaTypesBubbleChart;