import styles from "./Main.module.scss"

// Main component definition
function Main(props:{
    mainTitle:string, // Main title passed as props
    image:string // Image source passed as props
}) {
    // Rendering Main component
    return (
        <>
            {/* Image wrapper with overlay and text */}
            <div className={styles.imageWrapper}>
                {/* Image */}
                <img className={styles.picture} src={props.image} alt="Hello"></img>
                {/* Overlay */}
                <div className={styles.overlay}></div>
                {/* Text wrapper */}
                <div className={styles.textWrapper}>
                    {/* Main title */}
                    {props.mainTitle}
                </div>
            </div>
        </>
    );
}

export default Main;
