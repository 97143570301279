export enum GameSpeed {
    level_10 = 100,
    level_9 = 200,
    level_8 = 300,
    level_7 = 400,
    level_6 = 500,
    level_5 = 600,
    level_4 = 700,
    level_3 = 800,
    level_2 = 900,
    level_1 = 1000
}

export enum GameButton {
    start = "Start",
    pause = "Pause",
    continue = "Continue",
    newGame = "New Game"
}

export type TetrisState = {
    droppingBlock: Block | null,
    droppingBlockMutation: number,
    droppingRow: number,
    droppingColumn: number,
    justSwitched: boolean,
    board: BoardCell[][],
    score: number,
    level: number,
    hold: Block | null,
    upcoming: [Block | null, Block | null, Block | null],
    isLose: boolean,
    isPlaying: boolean,
    justRotated: boolean,
    message?: Msg,
    linesSendingToOpponent: number,
    linesReceivingDuringThisTick: number,
    projectionRow: number
}

export type Action = {
    type: ActionType,
    value?: any
}

export type ActionType =
    "Init" |
    "Blank" |
    "Commit" |
    "Left" |
    "Right" |
    "Down" |
    "RotateClockwise" |
    "RotateAntiClockWise" |
    "Jump" |
    "Hold" |
    "Tick" |
    "SetMessage"

export enum ProjectBlock {
    V = 'V'
}

export enum GreyBlock {
    G = 'G'
}

export enum Block {
    I = 'I',
    J = 'J',
    L = 'L',
    O = 'O',
    S = 'S',
    T = 'T',
    Z = 'Z',
}

export type BoardCell = Block | GreyBlock | ProjectBlock | null

export type BlockShape = boolean[][]

export type BlockShapeMutations = {
    [key in Block]: BlockShape[]
}

export enum Msg {
    three = "3",
    two = "2",
    one = "1",
    go = "Go!",
    tetris = "Tetris",
    bigTetris = "Big Tetris!",
    greatAtk = "Great Attack!",
    niceAtk = "Nice Attack!",
    atk = "Attack!",
    defeat = "Defeat!",
    victory = "Victory!",
    null = ""
}

export const msgStyle: { [key in Msg]: { [key: string]: string } } = {
    [Msg.three]: {
        backgroundColor: "yellow",
        color: "black",
        fontSize: "3rem",
        animation: "fadeInOut 2s ease-in-out",
    },
    [Msg.two]: {
        backgroundColor: "yellow",
        color: "black",
        fontSize: "3rem",
        animation: "fadeInOut 2s ease-in-out",
    },
    [Msg.one]: {
        backgroundColor: "yellow",
        color: "black",
        fontSize: "3rem",
        animation: "fadeInOut 2s ease-in-out",
    },
    [Msg.go]: {
        backgroundColor: "green",
        color: "white",
        fontSize: "4rem",
        animation: "fadeInOut 2s ease-in-out",
    },
    [Msg.tetris]: {
        backgroundColor: "blue",
        color: "white",
        fontSize: "2.5rem",
        animation: "fadeInOut 2s ease-in-out",
    },
    [Msg.bigTetris]: {
        backgroundColor: "purple",
        color: "white",
        fontSize: "2.5rem",
        animation: "fadeInOut 2s ease-in-out",
    },
    [Msg.greatAtk]: {
        backgroundColor: "orange",
        color: "white",
        fontSize: "2.5rem",
        animation: "fadeInOut 2s ease-in-out",
    },
    [Msg.niceAtk]: {
        backgroundColor: "lightgreen",
        color: "black",
        fontSize: "2.5rem",
        animation: "fadeInOut 2s ease-in-out",
    },
    [Msg.atk]: {
        backgroundColor: "red",
        color: "white",
        fontSize: "2.5rem",
        animation: "fadeInOut 2s ease-in-out",
    },
    [Msg.victory]: {
        backgroundColor: "gold",
        color: "black",
        fontSize: "3rem",
        animation: "fadeIn 1s ease-in",
    },
    [Msg.defeat]: {
        backgroundColor: "black",
        color: "white",
        fontSize: "3rem",
        animation: "fadeIn 1s ease-in",
    },
    [Msg.null]: {},
}

export const blockShapes: BlockShapeMutations = {
    [Block.I]: [
        [
            [false, true, false, false],
            [false, true, false, false],
            [false, true, false, false],
            [false, true, false, false]
        ], [
            [false, false, false, false],
            [true, true, true, true],
            [false, false, false, false],
            [false, false, false, false]
        ]],
    [Block.J]: [
        [
            [false, false, false],
            [false, false, true],
            [true, true, true]
        ], [
            [false, true, true],
            [false, false, true],
            [false, false, true]
        ], [
            [true, true, true],
            [true, false, false],
            [false, false, false]
        ], [
            [true, false, false],
            [true, false, false],
            [true, true, false]
        ]],
    [Block.L]: [
        [
            [false, false, false],
            [true, false, false],
            [true, true, true]
        ], [
            [false, false, true],
            [false, false, true],
            [false, true, true]
        ], [
            [true, true, true],
            [false, false, true],
            [false, false, false]
        ], [
            [true, true, false],
            [true, false, false],
            [true, false, false]
        ]],
    [Block.O]: [
        [
            [true, true],
            [true, true]
        ]
    ],
    [Block.S]: [
        [
            [false, true, true],
            [true, true, false],
            [false, false, false]
        ], [
            [true, false, false],
            [true, true, false],
            [false, true, false]
        ]
    ],
    [Block.Z]: [
        [
            [true, true, false],
            [false, true, true],
            [false, false, false]
        ], [
            [false, false, true],
            [false, true, true],
            [false, true, false]
        ]
    ],
    [Block.T]: [
        [
            [false, true, false],
            [true, true, true],
            [false, false, false]
        ], [
            [false, true, false],
            [true, true, false],
            [false, true, false]
        ], [
            [false, false, false],
            [true, true, true],
            [false, true, false]
        ], [
            [false, true, false],
            [false, true, true],
            [false, true, false]
        ]
    ],
}
