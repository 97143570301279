import styles from "./Aspect.module.scss" // Import SCSS module for styling

function Aspect(props: {
    title: string,
    clicked: boolean[],
    index: number,
    onClick: (index: number) => void
}) {
    return (
        <>
            {/* Wrapper div for aspect item */}
            <div className={styles.wrapper} onClick={() => { props.onClick(props.index) }}>
                 {/* Text div for aspect title */}
                <div className={props.clicked[props.index] ? styles.text_clicked : styles.text}>
                    {props.title}
                </div>
                {/* Arrow div for indicating click state */}
                <div className={props.clicked[props.index] ? styles.arrow_clicked : styles.arrow}>
                    <i className="fa-solid fa-arrow-right"></i>
                </div>
            </div>
        </>
    );
}

export default Aspect;