import React from 'react';
import Main from '../../components/Mains/Main';
import brainHealth from "../../../pictures/mains/brain_health2.png"
import RoutePath from '../../components/RoutePath/RoutePath';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import { sections } from '../../types/sections';
import { useLocation, useParams } from 'react-router-dom';
import { games } from '../../types/paths';
import TetrisEntryPage from "../../brainGames/Tetris/TetrisEntryPage/TetrisEntryPage";
import SudokuEntryPage from '../../brainGames/Sudoku/SudokuEntryPage/SukokuEntryPage';
import CrosswordEntryPage from '../../brainGames/Crossword/CrosswordEntryPage/CrosswordEntryPage';
import styles from "./BrainHealthPage.module.scss"


function BrainHealth() {
    const { games: current_games } = useParams()
    const location = useLocation();

    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get("goTop") === "true") {
            window.scrollTo(0, 0);
        }
    }, [location])

    return (
        <>
            <Main image={brainHealth} mainTitle='Brain Games' />
            <RoutePath path={[sections.Home, sections.BrainHealth]} />
            <div className={styles.bg}>
                <ToggleSwitch checked={current_games!} />
                {current_games === games.Crossword && <CrosswordEntryPage />}
                {current_games === games.Tetris && <TetrisEntryPage />}
                {current_games === games.Sudoku && <SudokuEntryPage />}
            </div>
        </>
    );
}

export default BrainHealth;