import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from "./DataVisPageDementiaSymptoms.module.scss"
import DementiaZoomableSunburst from '../../D3Graphs/DementiaZoomableSunburst/DementiaZoomableSunburst';

/**
 * DataVisPageDementiaSymptoms is a functional component that renders a data visualization page
 * for displaying information about dementia behaviors and symptoms across different age groups in Australia.
 */
function DataVisPageDementiaSymptoms() {
    return (<>
        {/* Page title */}
        <Row><Col><div className={styles.title}>Dementia Behaviour and Symptoms in different age groups in Australia (2021–22)</div></Col></Row>
        <Row>
            {/* Bar Chart Column: Renders a bar chart showing the distribution of dementia cases */}
            <Col xs={12}>
                <DementiaZoomableSunburst
                    width={700}
                    padding={{ top: 0, bottom: 0, left: 10, right: 10 }}
                    data_url={process.env.REACT_APP_DEMENTIA_SYMPTOMS!}
                />
            </Col>
        </Row>
        <Row><Col>
            {/* Subtitle for section */}
            <div className={styles.subtitle}>What Information is Shown Here?</div>

            {/* Example to explain the chart data */}
            <div className={styles.content}>The double donut charts feature two concentric rings. The inner ring displays the percentage of the total population with and without dementia within different age groups. The outer ring illustrates the percentage of individuals with and without dementia who exhibit specific symptoms or behaviors.</div>
            <div className={styles.content}>For example, 40% of the total dementia population is over 85 years old, and within this group, 11% show symptoms of agitation. This is compared to only 3% of the non-dementia population in the same age group exhibiting the same behavior.</div>
            
            {/* Subtitle for findings */}
            <div className={styles.subtitle}>Findings from the Charts</div>

            {/* Summary of findings */}
            <div className={styles.content}>The behaviors and symptoms that show the most significant differences between the dementia and non-dementia populations include:</div>
            <div className={styles.sentences}>
                <div className={styles.left}>
                    <div className={styles.content}>1. <span style={{ color: "yellow" }}>Agitation</span>: </div>
                    <div className={styles.content}>2. <span style={{ color: "yellow" }}>Hallucinations/delusions</span>: </div>
                    <div className={styles.content}>3. <span style={{ color: "yellow" }}>Risky behaviour</span>: </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.content}>approximately <span style={{ color: "yellow" }}>4</span> times likely for dementia people having this behaviour over non-dementia people across all age groups.</div>
                    <div className={styles.content}>approximately <span style={{ color: "yellow" }}>5</span> times likely for dementia people having this behaviour over non-dementia people across all age groups.</div>
                    <div className={styles.content}>approximately <span style={{ color: "yellow" }}>4-5</span> times likely for dementia people having this behaviour over non dementia-people across all age groups.</div>

                </div>
            </div>
        </Col></Row>
    </>);
}

export default DataVisPageDementiaSymptoms; // Exporting the component for use in other parts of the application.