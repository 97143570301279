import { useCallback, useEffect, useState, useRef } from 'react';
import { Block, BlockShape, BoardShape, EmptyCell, SHAPES } from '../components/types';
import { useInterval } from './useInterval';
import { useTetrisBoard, hasCollisions, BOARD_HEIGHT, getEmptyBoard, getRandomBlock, } from './useTetrisBoard';

enum TickSpeed {
  Normal = 800,
  Faster = 700,
  Fastest = 600,
  SuperFast = 500,
  UltraFast = 400,
  Sliding = 100,
  Fast = 50,
  SoFast = 0
}

export function useTetris() {
  const [score, setScore] = useState(0);
  const [upcomingBlocks, setUpcomingBlocks] = useState<Block[]>([]);
  const [isCommitting, setIsCommitting] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [tickSpeed, setTickSpeed] = useState<TickSpeed | null>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [gameTime, setGameTime] = useState(0); // 用于追踪游戏进行的时间
  const hasSavedScore = useRef(false);
  const [gameStartTime, setGameStartTime] = useState<number | null>(null);

  const [
    { board, droppingRow, droppingColumn, droppingBlock, droppingShape },
    dispatchBoardState,
  ] = useTetrisBoard();

  const startGame = useCallback(() => {
    const startingBlocks = [
      getRandomBlock(),
      getRandomBlock(),
      getRandomBlock(),
    ];
    setGameTime(0);
    setGameStartTime(Date.now());
    setScore(0);
    setUpcomingBlocks(startingBlocks);
    setIsCommitting(false);
    setIsPlaying(true);
    setIsPaused(false)
    setIsGameOver(false)
    setTickSpeed(TickSpeed.Normal);
    dispatchBoardState({ type: 'start' });
  }, [dispatchBoardState]);


  //提交当前位置
  const commitPosition = useCallback(() => {
    /*     //如果下一行没有碰撞
        if (!hasCollisions(board, droppingShape, droppingRow + 1, droppingColumn)) {
          setIsCommitting(false);
          setTickSpeed(TickSpeed.Normal);
          return;
        } */
    if (!isGameOver) {
      const newBoard = structuredClone(board) as BoardShape;
      addShapeToBoard(
        newBoard,
        droppingBlock,
        droppingShape,
        droppingRow,
        droppingColumn
      );

      let numCleared = 0;
      for (let row = BOARD_HEIGHT - 1; row >= 0; row--) {
        if (newBoard[row].every((entry) => entry !== EmptyCell.Empty)) {
          numCleared++;
          newBoard.splice(row, 1);
        }
      }

      const newUpcomingBlocks = structuredClone(upcomingBlocks) as Block[];
      const newBlock = newUpcomingBlocks.pop() as Block;
      newUpcomingBlocks.unshift(getRandomBlock());
      //game over
      if (hasCollisions(board, SHAPES[upcomingBlocks[upcomingBlocks.length - 1]].shape, 0, Math.floor(board[0].length / 2))) {
        // 如果是，设置游戏为结束状态
        setIsGameOver(true);
        setIsPlaying(false);
        setTickSpeed(null);
      } else {
        setTickSpeed(TickSpeed.Normal);
      }
      setUpcomingBlocks(newUpcomingBlocks);


      setScore((prevScore) => (prevScore) + getPoints(numCleared));
      dispatchBoardState({
        type: 'commit',
        newBoard: [...getEmptyBoard(BOARD_HEIGHT - newBoard.length), ...newBoard],
        newBlock,
      });
      setIsCommitting(false);
    }

  }, [
    board,
    dispatchBoardState,
    droppingBlock,
    droppingColumn,
    droppingRow,
    droppingShape,
    upcomingBlocks,
    isGameOver
  ]);

  //方块下降
  const gameTick = useCallback(() => {
    if (isGameOver) {
      // 如果游戏已结束，不执行任何操作
      return;
    }
    if (isCommitting) {
      commitPosition();
    } else {
      // 检查向下一行是否有碰撞。如果没有，继续下降。
      if (!hasCollisions(board, droppingShape, droppingRow + 1, droppingColumn)) {
        dispatchBoardState({ type: 'drop' });
      } else {
        // 如果即将发生碰撞，处理逻辑
        setIsCommitting(true);
        setTickSpeed(TickSpeed.Sliding);
      }
    }

  }, [
    board,
    commitPosition,
    dispatchBoardState,
    droppingColumn,
    droppingRow,
    droppingShape,
    isCommitting,
    isGameOver
  ]);

  //暂停
  const togglePause = useCallback(() => {
    if (isPlaying) {
      setIsPaused(!isPaused);
      setTickSpeed(isPaused ? TickSpeed.Normal : null);
    }
  }, [isPaused, isPlaying, setTickSpeed]);

  // 每秒更新游戏时间
  useInterval(() => {
    setGameTime((prevTime) => prevTime + 1);
  }, 1000);

  // 根据分数调整下落速度
  useEffect(() => {
    if (isGameOver) {
      return;
    }
    if (score >= 1000 && score < 2000) {
      setTickSpeed(TickSpeed.Faster);
    } else if (score >= 2000 && score < 3000) {
      setTickSpeed(TickSpeed.Fastest);
    } else if (score >= 3000 && score < 5000) {
      setTickSpeed(TickSpeed.SuperFast);
    } else if (score >= 5000) {
      setTickSpeed(TickSpeed.UltraFast);
    }
  }, [score, gameTime, isGameOver]);

  useInterval(() => {
    if (!isPlaying || isPaused || isGameOver) {
      return;
    }
    gameTick();
  }, tickSpeed);


  useEffect(() => {
    if (!isPlaying || isGameOver) {
      return;
    }

    let isPressingLeft = false;
    let isPressingRight = false;
    let moveIntervalID: number | undefined;

    const updateMovementInterval = () => {
      clearInterval(moveIntervalID);
      dispatchBoardState({
        type: 'move',
        isPressingLeft,
        isPressingRight,
      });

      moveIntervalID = window.setInterval(() => {
        dispatchBoardState({
          type: 'move',
          isPressingLeft,
          isPressingRight,
        });
      }, 100) as unknown as number;
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.repeat) {
        return;
      }

      if (event.key === 'ArrowDown') {
        setTickSpeed(TickSpeed.Fast);
      }

      if (event.key === 'ArrowUp') {
        dispatchBoardState({
          type: 'move',
          isRotating: true,
        });
      }

      if (event.key === 'ArrowLeft') {
        isPressingLeft = true;
        updateMovementInterval();
      }

      if (event.key === 'ArrowRight') {
        isPressingRight = true;
        updateMovementInterval();
      }

      if (event.key === ' ') {
        setTickSpeed(TickSpeed.SoFast)
      }
    };


    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        setTickSpeed(TickSpeed.Normal);
      }

      if (event.key === 'ArrowLeft') {
        isPressingLeft = false;
        updateMovementInterval();
      }

      if (event.key === 'ArrowRight') {
        isPressingRight = false;
        updateMovementInterval();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      clearInterval(moveIntervalID);
      setTickSpeed(TickSpeed.Normal);
    };
  }, [dispatchBoardState, isPlaying, isGameOver]);

  const renderedBoard = structuredClone(board) as BoardShape;
  if (isPlaying && !isGameOver) {
    addShapeToBoard(
      renderedBoard,
      droppingBlock,
      droppingShape,
      droppingRow,
      droppingColumn
    );
  }
  useEffect(() => {
    if (isGameOver && !hasSavedScore.current) {
      // 保存分数逻辑
      const gameEndTime = Date.now();
      const gameDuration = gameEndTime - gameStartTime!; // 游戏时长，单位为毫秒

      // 将游戏时长转换为更合适的格式，如分钟或秒
      const gameDurationSeconds = Math.floor(gameDuration / 1000); // 游戏时长，单位为秒

      // 获取当前分数和之前的记录
      const currentHighScores = JSON.parse(localStorage.getItem('tetrisHighScores') || '[]');

      // 将当前游戏的分数和时长添加到记录中
      currentHighScores.push({ score: score, duration: gameDurationSeconds });

      // 更新 localStorage
      localStorage.setItem('tetrisHighScores', JSON.stringify(currentHighScores));
      hasSavedScore.current = true;
    }

    // 重置 ref
    return () => {
      hasSavedScore.current = false;
    };
  }, [isGameOver, gameStartTime, score]);

  return {
    board: renderedBoard,
    startGame,
    isPlaying,
    score,
    upcomingBlocks,
    togglePause,
    isPaused,
    isGameOver,
    gameTime
  };
}

function getPoints(numCleared: number): number {
  switch (numCleared) {
    case 0:
      return 0;
    case 1:
      return 100;
    case 2:
      return 300;
    case 3:
      return 500;
    case 4:
      return 800;
    default:
      throw new Error('Unexpected number of rows cleared');
  }
}

function addShapeToBoard(
  board: BoardShape,
  droppingBlock: Block,
  droppingShape: BlockShape,
  droppingRow: number,
  droppingColumn: number
) {

  droppingShape
    .filter((row) => row.some((isSet) => isSet))
    .forEach((row: boolean[], rowIndex: number) => {
      row.forEach((isSet: boolean, colIndex: number) => {
        if (isSet && droppingRow + rowIndex >= 0 &&
          droppingRow + rowIndex < board.length &&
          droppingColumn + colIndex < board[0].length) {
          board[droppingRow + rowIndex][droppingColumn + colIndex] =
            droppingBlock;
        }
      });
    });
}


