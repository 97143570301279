import styles from "./Aspect.module.scss"

function Aspect(props: {
    title: string,
    clicked: boolean[],
    index: number,
    onClick: (index: number) => void
}) {
    return (
        <>
            <div className={styles.wrapper} onClick={() => { props.onClick(props.index) }}>
                <div className={props.clicked[props.index] ? styles.text_clicked : styles.text}>
                    {props.title}
                </div>
                <div className={props.clicked[props.index] ? styles.arrow_clicked : styles.arrow}>
                    <i className="fa-solid fa-arrow-right"></i>
                </div>
            </div>
        </>
    );
}

export default Aspect;