import { useEffect, useRef, useState } from 'react';
import HomeMain from '../../components/Mains/HomeMain/HomeMain';
import HomeDementia from '../../components/Home/HomeDementia/HomeDementia';
import HomePrediction from '../../components/Home/HomePrediction/HomePrediction';
import HomeBrainGames from '../../components/Home/HomeBrainGames/HomeBrainGames';
import HomeDataVis from '../../components/Home/HomeDataVis/HomeDataVis';
import HomeHiking from "../../components/Home/HomeHiking/HomeHiking";
import styles from "./HomePage.module.scss"
import { useAppSelector } from '../../../redux/hooks';

function HomePage() {
    const scrolled = useAppSelector(state => state.windowScroll.scrolled)
    const [arrowUp, setArrowUp] = useState(false)

    const dementiaRef = useRef(null);
    const predictionRef = useRef(null);
    const brainGamesRef = useRef(null);
    const hikingRef = useRef(null);
    const dataVisRef = useRef(null);

    useEffect(()=>{
        if (Math.abs(window.innerHeight + scrolled - document.body.offsetHeight) <= 5){
            setArrowUp(true)
        }else{
            setArrowUp(false)
        }
    }, [scrolled])

    useEffect(() => {
        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.visible);
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });

        if (dementiaRef.current) observer.observe(dementiaRef.current);
        if (predictionRef.current) observer.observe(predictionRef.current);
        if (brainGamesRef.current) observer.observe(brainGamesRef.current);
        if (hikingRef.current) observer.observe(hikingRef.current);
        if (dataVisRef.current) observer.observe(dataVisRef.current);

        return () => {
            if (dementiaRef.current) observer.unobserve(dementiaRef.current);
            if (predictionRef.current) observer.unobserve(predictionRef.current);
            if (brainGamesRef.current) observer.unobserve(brainGamesRef.current);
            if (hikingRef.current) observer.unobserve(hikingRef.current);
            if (dataVisRef.current) observer.unobserve(dataVisRef.current);
        };
    }, []);

    
    const handleScrollButtonClicked = () => {
        const viewportHeight = window.innerHeight;
        const currentScroll = window.scrollY;
        const documentHeight = document.body.offsetHeight;
        if (Math.abs(viewportHeight + currentScroll - documentHeight) <= 5) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            window.scrollBy({ top: viewportHeight, left: 0, behavior: 'smooth' });
        }
    };
    return (
        <>
            <button onClick={handleScrollButtonClicked} className={styles.scrollBut}>
                {!arrowUp && <i className="fa-solid fa-arrow-down"></i>}
                {arrowUp && <i className="fa-solid fa-arrow-up"></i>}
            </button>
            <HomeMain />
            <div className={styles.fadeIn} ref={dementiaRef}><HomeDementia /></div>
            <div className={styles.fadeIn} ref={predictionRef}><HomePrediction /></div>
            <div className={styles.fadeIn} ref={brainGamesRef}><HomeBrainGames /></div>
            <div className={styles.fadeIn} ref={hikingRef}><HomeHiking /></div>
            <div className={styles.fadeIn} ref={dataVisRef}><HomeDataVis /></div>
        </>
    );
}

export default HomePage;