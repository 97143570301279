import { Block, BoardCell, GameSpeed, GreyBlock, Msg, ProjectBlock, TetrisState, blockShapes } from "../../gameTypes/types";

export const boardWidth = 10;
export const boardHeight = 20;
const double_scaler = 3;
const single_scaler = 3

export function score_speed_conversion_single(score: number) {
    if (score > 140 * single_scaler) return [GameSpeed.level_10, 10]
    if (score > 120 * single_scaler) return [GameSpeed.level_9, 9]
    if (score > 100 * single_scaler) return [GameSpeed.level_8, 8]
    if (score > 80 * single_scaler) return [GameSpeed.level_7, 7]
    if (score > 60 * single_scaler) return [GameSpeed.level_6, 6]
    if (score > 40 * single_scaler) return [GameSpeed.level_5, 5]
    if (score > 20 * single_scaler) return [GameSpeed.level_4, 4]
    if (score > 10 * single_scaler) return [GameSpeed.level_3, 3]
    if (score > 5 * single_scaler) return [GameSpeed.level_2, 2]
    return [GameSpeed.level_1, 1]
}

export function score_speed_conversion_double(score: number) {
    if (score > 280 * double_scaler) return [GameSpeed.level_10, 10]
    if (score > 240 * double_scaler) return [GameSpeed.level_9, 9]
    if (score > 200 * double_scaler) return [GameSpeed.level_8, 8]
    if (score > 160 * double_scaler) return [GameSpeed.level_7, 7]
    if (score > 120 * double_scaler) return [GameSpeed.level_6, 6]
    if (score > 80 * double_scaler) return [GameSpeed.level_5, 5]
    if (score > 40 * double_scaler) return [GameSpeed.level_4, 4]
    if (score > 20 * double_scaler) return [GameSpeed.level_3, 3]
    if (score > 10 * double_scaler) return [GameSpeed.level_2, 2]
    return [GameSpeed.level_1, 1]
}

export function cloneTetrisState(state: TetrisState): TetrisState {
    return {
        ...state,
        upcoming: [...state.upcoming],
        board: state.board.map(row => [...row])
    }
}

export function switchHoldingBlock(tetrisState: TetrisState) {
    if (!tetrisState.justSwitched) {
        tetrisState.droppingBlockMutation = 0
        tetrisState.droppingColumn = 3
        tetrisState.droppingRow = 0
        if (tetrisState.hold) {
            const holding = tetrisState.hold
            tetrisState.hold = tetrisState.droppingBlock
            tetrisState.droppingBlock = holding
        } else {
            const [firstBlock, ...remainingBlocks] = tetrisState.upcoming;
            tetrisState.upcoming = [...remainingBlocks, getRandomBlock()];
            tetrisState.hold = tetrisState.droppingBlock
            tetrisState.droppingBlock = firstBlock;
        }
    }
    tetrisState.justSwitched = true
    return updateProjectRow(tetrisState)
}

function rotateTetris(tetrisState: TetrisState, clockwise: boolean) {
    const tetrisStateCopy = cloneTetrisState(tetrisState)
    const mut = tetrisState.droppingBlockMutation
    const len = blockShapes[tetrisState.droppingBlock!].length
    if (clockwise) {
        tetrisState.droppingBlockMutation = (mut + 1) % len
    } else {
        tetrisState.droppingBlockMutation = (mut + 3) % len
    }
    const shape = blockShapes[tetrisState.droppingBlock!][tetrisState.droppingBlockMutation]

    if (checkCollision(tetrisState)) {
        let totalOutsideWallBlocks = 0
        let leftHandSide = false
        for (let i = 0; i < shape.length; i++) {
            for (let j = 0; j < shape[0].length; j++) {
                if (shape[i][j]) {
                    if (tetrisState.droppingColumn + j < 0) {
                        leftHandSide = true
                        totalOutsideWallBlocks += 1
                    } else if (tetrisState.droppingColumn + j >= boardWidth) {
                        totalOutsideWallBlocks += 1
                    }
                }
            }
        }
        if (totalOutsideWallBlocks !== 0) {
            if (leftHandSide) {
                tetrisState.droppingColumn += totalOutsideWallBlocks
            } else {
                tetrisState.droppingColumn -= totalOutsideWallBlocks
            }
            if (checkCollision(tetrisState)) {
                return tetrisStateCopy
            } else {
                return updateProjectRow(tetrisState)
            }
        }
        return tetrisStateCopy
    }
    return updateProjectRow(tetrisState)
}

export function rotateCurrentShapeAntiClockwise(tetrisState: TetrisState) {
    return rotateTetris(tetrisState, false)
}

export function rotateCurrentShapeClockwise(tetrisState: TetrisState) {
    return rotateTetris(tetrisState, true)
}

export function moveCurrentShapeToRight(tetrisState: TetrisState) {
    tetrisState.droppingColumn += 1
    if (checkCollision(tetrisState)) {
        tetrisState.droppingColumn -= 1
        return tetrisState
    }
    return updateProjectRow(tetrisState)
}

export function moveCurrentShapeToLeft(tetrisState: TetrisState) {
    tetrisState.droppingColumn -= 1
    if (checkCollision(tetrisState)) {
        tetrisState.droppingColumn += 1
        return tetrisState
    }
    return updateProjectRow(tetrisState)
}

export function moveCurrentShapeToDown(tetrisState: TetrisState) {
    tetrisState.droppingRow += 1
    if (checkCollision(tetrisState)) {
        tetrisState.droppingRow -= 1
        return tetrisState
    }
    return tetrisState
}

export function jumpCurrentShapeToBottom(tetrisState: TetrisState) {
    do {
        tetrisState.droppingRow += 1
    } while (!checkCollision(tetrisState))
    tetrisState.droppingRow -= 1
    return handleGameTick(tetrisState);
}

export function updateProjectRow(state: TetrisState) {
    const newState = cloneTetrisState(state)
    do {
        newState.droppingRow += 1
    } while (!checkCollision(newState));
    newState.droppingRow -= 1
    state.projectionRow = newState.droppingRow
    return state
}

export function addProjectionToBoard(state: TetrisState) {
    if (state.droppingBlock) {
        const shape = blockShapes[state.droppingBlock][state.droppingBlockMutation]
        for (let i = 0; i < shape.length; i++) {
            for (let j = 0; j < shape[0].length; j++) {
                if (shape[i][j]) {
                    state.board[state.projectionRow + i][state.droppingColumn + j] = ProjectBlock.V
                }
            }
        }
    }
    return state
}

export function addShapeToBoard(tetrisState: TetrisState) {
    if (tetrisState.droppingBlock) {
        let shp = blockShapes[tetrisState.droppingBlock][tetrisState.droppingBlockMutation]
        let row = tetrisState.droppingRow
        let col = tetrisState.droppingColumn
        for (let i = 0; i < shp.length; i++) {
            for (let j = 0; j < shp[0].length; j++) {
                if (shp[i][j]) {
                    tetrisState.board[row + i][col + j] = tetrisState.droppingBlock
                }
            }
        }
    }
    return tetrisState
}

export function checkCollision(tetrisState: TetrisState) {
    const row = tetrisState.droppingRow
    const col = tetrisState.droppingColumn
    const shp = blockShapes[tetrisState.droppingBlock!][tetrisState.droppingBlockMutation]
    const brd = tetrisState.board

    for (let i = 0; i < shp.length; i++) {
        for (let j = 0; j < shp[0].length; j++) {
            if (shp[i][j]) {
                if (row + i >= boardHeight ||
                    col + j >= boardWidth ||
                    col + j < 0 ||
                    (brd[row + i][col + j] !== null && brd[row + i][col + j] !== ProjectBlock.V)
                ) {
                    return true
                }
            }
        }
    }
    return false
}


export function getRandomBlock(): Block {
    const blocks = Object.values(Block) as Block[];
    const randomIndex = Math.floor(Math.random() * blocks.length);
    return blocks[randomIndex];
}

export function createBlankState(): TetrisState {
    return {
        justSwitched: false,
        droppingBlock: null,
        droppingBlockMutation: 0,
        droppingRow: 0,
        droppingColumn: 3,
        board: [...Array(boardHeight)].map(() => Array(boardWidth).fill(null)),
        score: 0,
        level: 0,
        hold: null,
        upcoming: [null, null, null],
        isLose: false,
        isPlaying: false,
        justRotated: false,
        linesSendingToOpponent: 0,
        projectionRow: 0,
        linesReceivingDuringThisTick: 0
    }
}

export function initializeTetrisState(): TetrisState {
    const new_block = getRandomBlock()
    const newState: TetrisState = {
        justSwitched: false,
        droppingBlock: new_block,
        droppingBlockMutation: 0,
        droppingRow: (new_block === Block.L) || (new_block === Block.J) ? -1 : 0,
        droppingColumn: 3,
        board: [...Array(boardHeight)].map(() => Array(boardWidth).fill(null)),
        score: 0,
        level: 0,
        hold: null,
        upcoming: [getRandomBlock(), getRandomBlock(), getRandomBlock()],
        isLose: false,
        justRotated: false,
        linesSendingToOpponent: 0,
        projectionRow: (new_block === Block.L) || (new_block === Block.J) ? -1 : 0,
        linesReceivingDuringThisTick: 0,
        isPlaying: true
    }
    return updateProjectRow(newState)
}

export function putNextBlockFromUpcomingToBoard(state: TetrisState): TetrisState {
    const [nextBlock, ...remainingBlocks] = state.upcoming
    state.upcoming = [...remainingBlocks, getRandomBlock()]
    state.droppingBlock = nextBlock
    state.droppingBlockMutation = 0
    state.droppingColumn = 3
    state.droppingRow = 0
    state.justSwitched = false
    return state
}


function checkSmallTetris(state: TetrisState): boolean {
    if (state.droppingBlockMutation === 0) {
        const upperLeftCorner = state.board[state.droppingRow][state.droppingColumn]
        const upperRightCorner = state.board[state.droppingRow][state.droppingColumn + 2]
        if (upperLeftCorner !== null || upperRightCorner !== null) {
            return true
        }
    }
    return false
}

function checkBigTetris(state: TetrisState): boolean {
    if (state.droppingBlockMutation === 2) {
        const upperLeftCorner = state.board[state.droppingRow][state.droppingColumn]
        const upperRightCorner = state.board[state.droppingRow][state.droppingColumn + 2]
        if (upperLeftCorner !== null || upperRightCorner !== null) {
            return true
        }
    }
    return false
}

export function eliminateRowsAndCalScore(state: TetrisState): TetrisState {
    let totalRowsEliminated = 0
    let rowsToEliminate: number[] = []
    let isTetris = false
    for (let i = 0; i < boardHeight; i++) {
        if (!state.board[i].some(v => (v === null || v === ProjectBlock.V))) {
            rowsToEliminate.push(i)
            if (state.droppingBlock === Block.T && state.justRotated) {
                isTetris = true
            }
            totalRowsEliminated += 1
        }
    }
    if (isTetris === true && totalRowsEliminated === 1 && checkSmallTetris(state)) {
        state.linesSendingToOpponent = 1
        state.message = Msg.tetris
    } else if (isTetris === true && totalRowsEliminated === 2 && checkBigTetris(state)) {
        state.linesSendingToOpponent = 2
        state.message = Msg.bigTetris
    } else if (totalRowsEliminated === 2) {
        state.message = Msg.atk
        state.linesSendingToOpponent = 1
    } else if (totalRowsEliminated === 3) {
        state.message = Msg.niceAtk
        state.linesSendingToOpponent = 2
    } else if (totalRowsEliminated === 4) {
        state.message = Msg.greatAtk
        state.linesSendingToOpponent = 3
    }
    state.board = state.board.filter((_, i) => !rowsToEliminate.includes(i))
    for (let i = 0; i < totalRowsEliminated; i++) {
        state.board.unshift(Array(boardWidth).fill(null))
    }

    state.score += (totalRowsEliminated * 100 + state.linesSendingToOpponent * 100) / 2
    return state
}

function generateRandomLine(): BoardCell[] {
    const randomIndex = Math.floor(Math.random() * boardWidth);
    const line = new Array(boardWidth).fill(GreyBlock.G);
    line[randomIndex] = null;
    return line
}

export function updateSufferAndCheckLose(state: TetrisState): TetrisState {
    const lines = state.linesReceivingDuringThisTick
    if (state.droppingRow === 0) {
        state.isLose = true
        state.isPlaying = false
    } else {
        for (let i = 0; i < lines; i++){
            state.board.push(generateRandomLine())
            state.board.splice(0, 1)
        }
        state.linesReceivingDuringThisTick = 0
    }
    return state
}

export function handleGameTick(state: TetrisState): TetrisState {
    state.droppingRow += 1
    if (checkCollision(state)) {
        state.droppingRow -= 1
        state = updateSufferAndCheckLose(state)
        if (!state.isLose){
            state = addShapeToBoard(state)
            state = eliminateRowsAndCalScore(state)
            state = putNextBlockFromUpcomingToBoard(state)
            state = updateProjectRow(state)
        }
        return state
    } else {
        return state
    }
}