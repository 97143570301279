import { Col, Container, Row } from 'react-bootstrap';
import RoutePath from '../../components/RoutePath/RoutePath';
import { sections } from '../../types/sections';
import styles from "./NotFoundPage.module.scss"
import Main from '../../components/Mains/Main';
import got_lost from "../../../pictures/mains/got_lost.png"

function NotFoundPage() {
    return (
        <>
            <Main image={got_lost} mainTitle='404'/>
            <RoutePath path={[sections.Home, sections.NotFound]} />
            <Container>
                <Row>
                    <Col>
                        <h1 className={styles.content}>404 not found.<br/>You has come to the wrong place.</h1>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default NotFoundPage;