import * as React from 'react';
import styles from "./TetrisGame.module.scss"

function TetrisGameGuide(props: {
    goBack: () => void
}) {
    return (<div className={styles.gameWrapper}>
        <button className={styles.backButton} onClick={() => {
            props.goBack()
        }}>
            <i className="fa-solid fa-arrow-left"></i> Back
        </button>
        {/* <div className={styles.tetris_guide}>
            <div className={styles.guide_title}>Single Mode</div>
            <div className={styles.guide_content}>1. Use </div>
            <div className={styles.guide_content}>2.</div>
            <div className={styles.guide_content}>3.</div>
        </div> */}
        <div className={styles.tetris_guide}>
            <div className={styles.guide_title}>Two Players Battle</div>
            <div className={styles.guide_content}>Player 1: "<span className={styles.word}>`</span>", "<span className={styles.word}>TAB</span>" and "<span className={styles.word}>CAPS LOCK</span>" for hold, Jump and Rotate Anti-clockwise respectively.</div>
            <div className={styles.guide_content}><span className={styles.word}>w</span> for Rotate clockwise, <span className={styles.word}>a</span>, <span className={styles.word}>s</span> and <span className={styles.word}>d</span> for left, down and right respectively.</div>
            <hr/>
            <div className={styles.guide_content}>Player 2: "<span className={styles.word}>,</span>", "<span className={styles.word}>.</span>" and "<span className={styles.word}>/</span>" for hold, Jump and Rotate Anti-clockwise respectively.</div>
            <div className={styles.guide_content}><span className={styles.word}>arrow-up</span> for Rotate clockwise, <span className={styles.word}>arrow-left</span>, <span className={styles.word}>arrow-down</span> and <span className={styles.word}>arrow-right</span> for left, down and right respectively.</div>
        </div>
    </div>);
}

export default TetrisGameGuide;