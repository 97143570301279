import Main from '../../components/Mains/Main';
import brainHealth from "../../../pictures/mains/brain_health2.png"
import RoutePath from '../../components/RoutePath/RoutePath';
import { sections } from '../../types/sections';
import Tetris from '../../brainGames/TetrisGame/Tetris';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import { useLocation, useParams } from 'react-router-dom';
import { games } from '../../types/paths';
import Crossword from '../../brainGames/Crossword/components/Crossword';
import Sudoku from '../../brainGames/Sudoku/App';
import React from 'react';


function BrainHealth() {
    const { games: current_games } = useParams()
    const location = useLocation();
    
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get("goTop") === "true") {
            window.scrollTo(0, 0);
        }
    }, [location])

    return (
        <>
            <Main image={brainHealth} mainTitle='Brain Health' />
            <RoutePath path={[sections.Home, sections.BrainHealth]} />
            <ToggleSwitch checked={current_games!} />
            {current_games === games.Crossword && <Crossword />}
            {current_games === games.Tetris && <Tetris />}
            {current_games === games.Sudoku && <Sudoku />}
        </>
    );
}

export default BrainHealth;