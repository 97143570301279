import React, { useEffect, useState } from 'react';
import './App.css';
import { Outlet } from 'react-router';
import NavBar from './components/Navbar/NavBar';
import { useAppDispatch } from '../redux/hooks'
import { windowWidthActionCreator } from '../redux/windowWidthSlice';
import { windowScrollActionCreator } from '../redux/windowScrollSlice';

function App() {
    // State to track authentication status
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // Redux dispatch hook
    const dispatch = useAppDispatch();

    // Effect hook to update window width and scroll position on resize and scroll events
    useEffect(() => {
        const handleResize = () => {
            dispatch(windowWidthActionCreator(window.innerWidth));
        };
        const handleScroll = () => {
            dispatch(windowScrollActionCreator(window.scrollY));
        }
        // Adding event listeners for resize and scroll events
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        // Removing event listeners on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch]);

    // Effect hook to authenticate user
    useEffect(() => {
        // Simulating authentication
        if (isAuthenticated === false) {
            // Prompting user for password (commented out for demonstration)
            // const pw = window.prompt("Please enter password: ");
            // if (pw === "1234") {
            //     setIsAuthenticated(true);
            // }
            setIsAuthenticated(true);
        }
    }, [isAuthenticated]);

    // Rendering null if user is not authenticated
    if (!isAuthenticated) {
        return null; 
    }
    
    // Rendering NavBar and Outlet components if user is authenticated
    return (
        <>
            <NavBar />
            <Outlet />
        </>
    );
}

export default App;
