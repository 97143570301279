import * as React from 'react';
import * as d3 from "d3"
import styles from "./DementiaDistributionBarChart.module.scss"
// Functional component for rendering a bar chart showing dementia distribution.
function DementiaDistributionBarChart(props) {

    // Refs to manage SVG and tooltip DOM elements dynamically.
    const tooltipRef = React.useRef(null)
    const chartRef = React.useRef(null)
    const data_url = props.data_url// URL to fetch the chart data.

    // Effect hook to fetch data and create the chart once component is mounted.
    React.useEffect(() => {
        // Asynchronously fetch data and set up the SVG for the chart.
        async function fetchDataAndCreateSVG() {
            try {
                // Fetch data from provided URL.
                const response = await fetch(data_url, { method: 'GET' });
                const data = await response.json()
                // Parse JSON data.
                // Extract margin and dimension properties from props.
                const margin = props.margin
                const width = props.width - margin.left - margin.right;
                const height = props.height - margin.top - margin.bottom;
                // Function to display tooltip with dynamic content.
                const showTooltip = (x, y, text) => {
                    const tooltip = d3.select(tooltipRef.current);
                    tooltip.style('left', x + 'px')
                        .style('top', y + 'px')
                        .style('display', 'block')
                        .text(text);
                }

                // Function to hide tooltip
                const hideTooltip = () => {
                    d3.select(tooltipRef.current).style('display', 'none');
                }
                // Initialize SVG element.
                const svg = d3.select(chartRef.current)
                svg.selectAll("*")
                    .remove()// Clear previous SVG contents.

                // Set up the main group element with margins.
                const g = svg.attr('width', width + margin.left + margin.right)
                    .attr('height', height + margin.top + margin.bottom)
                    .append('g')
                    .attr('transform', `translate(${margin.left},${margin.top})`);

                // Define scales and axes for the chart.
                const ageRanges = data.map(d => d.ageRange); // Age ranges from data.
                const maxCases = d3.max(data, d => d.incidences); // Maximum number of cases.
                const x = d3.scaleLinear() // Scale for males.
                    .domain([0, maxCases])
                    .range([0, width / 2]);

                const x_left = d3.scaleLinear()// Scale for females (inverted).
                    .domain([maxCases, 0])
                    .range([0, width / 2]);

                const y = d3.scaleBand()// Band scale for age ranges.
                    .domain(ageRanges)
                    .range([height, 0])
                    .padding(0.1);
                // Interaction handlers.
                const handleMouseOver = (e, d) => {
                    showTooltip(e.pageX, e.pageY, `Male: ${d.incidences}`);
                }

                // Create bars for males
                g.selectAll('.bar.male')
                    .data(data.filter(d => d.gender === 'Male'))
                    .enter()
                    .append('rect')
                    .attr('class', `${styles.bar} ${styles.male}`)
                    .attr('x', width / 2) // Starting from the middle
                    .attr('y', d => y(d.ageRange))
                    .attr('width', d => x(d.incidences)) // Positive value for males
                    .attr('height', y.bandwidth())
                    .on('mouseover', handleMouseOver)
                    .on('mouseout', hideTooltip);

                // Create bars for females
                g.selectAll('.bar.female')
                    .data(data.filter(d => d.gender === 'Female'))
                    .enter().append('rect')
                    .attr('class', `${styles.bar} ${styles.female}`)
                    .attr('x', d => width / 2 - x(d.incidences)) // Starting from the middle and shifting left
                    .attr('y', d => y(d.ageRange))
                    .attr('width', d => x(d.incidences)) // Positive value for females
                    .attr('height', y.bandwidth())
                    .on('mouseover', function (event, d) {
                        showTooltip(event.pageX, event.pageY, `Female: ${d.incidences}`);
                    })
                    .on('mouseout', function () {
                        hideTooltip();
                    });


                // Add x-axis on the left side
                const xAxisL = g.append('g')
                    .attr('class', 'x-axis')
                    .attr('transform', `translate(0, ${height})`)
                    .call(d3.axisBottom(x_left).ticks(5))

                xAxisL.selectAll('text') // Select all parts of the axis
                    .style('font-size', "12px")
                    .attr('fill', 'white')

                xAxisL.selectAll('path, line')
                    .style('stroke', 'white') // Set the color of lines and ticks to white
                    .style('stroke-width', '2')

                // Add x-axis on the right side
                const xAxisR = g.append('g')
                    .attr('class', 'x-axis')
                    .attr('transform', `translate(${width / 2}, ${height})`)
                    .call(d3.axisBottom(x).ticks(5))

                xAxisR.selectAll('text') // Select all parts of the axis
                    .style('font-size', "12px")
                    .attr('fill', 'white')

                xAxisR.selectAll('path, line')
                    .style('stroke-width', '2')
                    .style('stroke', 'white') // Set the color of lines and ticks to white


                // Add y-axis
                const yAxis = g.append('g')
                    .attr('class', 'y-axis')
                    .call(d3.axisLeft(y))

                yAxis.selectAll('text')
                    .style('text-anchor', 'end') // Align labels to the start
                    .attr('dx', '-10px')
                    .attr('dy', '.15em')
                    .style('font-size', "12px")
                    .attr("fill", "white")

                yAxis.selectAll("path, line")
                    .attr("stroke-width", "2")
                    .style('stroke', 'white')

                // Add labels
                g.append('text')
                    .attr('x', width / 4)
                    .attr('y', height + margin.top + 20)
                    .style('text-anchor', 'middle')
                    .text('Cases (Female)')
                    .attr("fill", "white")

                g.append('text')
                    .attr('x', width * 3 / 4)
                    .attr('y', height + margin.top + 20)
                    .style('text-anchor', 'middle')
                    .text('Cases (Male)')
                    .attr("fill", "white")

                g.append('text')
                    .attr('transform', 'rotate(-90)')
                    .attr('x', -height / 2)
                    .attr('y', -margin.left + 30)
                    .style('text-anchor', 'middle')
                    .text('Age Range')
                    .attr("fill", "white")
            } catch (e) {
                // Handle errors by showing a message in the chart container.
                document.querySelector(".barchartWrapper").innerHTML = `<div>${e}</div>`
            }
        }
        fetchDataAndCreateSVG()
    }, [])

    return (
        <div className="barchartWrapper">
            <center>
                <svg ref={chartRef} className={styles.chart}></svg>
                <div ref={tooltipRef} className={styles.tooltip}></div>
            </center>
        </div>
    );
}

export default DementiaDistributionBarChart;