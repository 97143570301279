export enum Block {
    I = 'I', // Enumeration for the 'I' block shape
    J = 'J', // Enumeration for the 'J' block shape
    L = 'L', // Enumeration for the 'L' block shape
    O = 'O', // Enumeration for the 'O' block shape
    S = 'S', // Enumeration for the 'S' block shape
    T = 'T', // Enumeration for the 'T' block shape
    Z = 'Z', // Enumeration for the 'Z' block shape
}

export enum EmptyCell {
    Empty = 'Empty', // Enumeration for an empty cell
}

export type CellOptions = Block | EmptyCell; // Union type for cell options, either a block or an empty cell

export type BoardShape = CellOptions[][]; // Type definition for the shape of the game board

export type BlockShape = boolean[][]; // Type definition for the shape of a block

type ShapesObj = {
    [key in Block]: {
        shape: BlockShape; // Object defining the shape of each block
    };
};

export const SHAPES: ShapesObj = {
    I: { // Shape for the 'I' block
        shape: [
            [false, false, false, false],
            [false, false, false, false],
            [true, true, true, true],
            [false, false, false, false],
        ],
    },
    J: { // Shape for the 'J' block
        shape: [
            [false, false, false],
            [true, false, false],
            [true, true, true],
        ],
    },
    L: { // Shape for the 'L' block
        shape: [
            [false, false, false],
            [false, false, true],
            [true, true, true],
        ],
    },
    O: { // Shape for the 'O' block
        shape: [
            [true, true],
            [true, true],
        ],
    },
    S: { // Shape for the 'S' block
        shape: [
            [false, false, false],
            [false, true, true],
            [true, true, false],
        ],
    },
    T: { // Shape for the 'T' block
        shape: [
            [false, false, false],
            [false, true, false],
            [true, true, true],
        ],
    },
    Z: { // Shape for the 'Z' block
        shape: [
            [false, false, false],
            [true, true, false],
            [false, true, true],
        ],
    },
};
