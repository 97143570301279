import './Tetris.css'; // Importing CSS styles for Tetris component
import Board from './components/Board' // Importing the Board component
import UpcomingBlocks from './components/UpcomingBlocks' // Importing the UpcomingBlocks component
import { useTetris } from './hooks/useTetris' // Importing the useTetris custom hook
import React, { useEffect, useState } from 'react'; // Importing React library, useEffect and useState hooks
import Button from 'react-bootstrap/Button'; // Importing the Button component from React Bootstrap
import Alert from 'react-bootstrap/Alert'; // Importing the Alert component from React Bootstrap

interface ScoreRecord { // Interface for ScoreRecord type
  score: number; // Score property of type number
  duration: number; // Duration property of type number
}
function Tetris() { // Tetris component
  const { board, startGame, isPlaying, score, upcomingBlocks, togglePause, isPaused, isGameOver } = useTetris(); // Destructuring values from useTetris hook
  const [highScores, setHighScores] = useState<ScoreRecord[]>([]); // State for highScores array
  // Function to clear high scores
  const clearScores = () => {
    localStorage.removeItem('tetrisHighScores'); // Remove high scores from local storage
    setHighScores([]); // Clear high scores state
  };

  useEffect(() => { // Effect hook to update high scores when game is over
    if (isGameOver) {

      const savedScores = JSON.parse(localStorage.getItem('tetrisHighScores') || '[]') as ScoreRecord[];; // Retrieve high scores from local storage
      setHighScores(savedScores); // Update high scores state

    }
  }, [isGameOver]); // Depend on isGameOver state



  return (
    <div className="app"> {/* Main container */}
      {isGameOver ? (
        <Alert key='danger' variant='danger' className='alert'>
          Game Over
        </Alert>
      ) : (
        <div /> // Otherwise, render nothing
      )
      }
      <div className="game-container"> {/* Game container */}
        <Board currentBoard={board} /> {/* Render the Board component */}
        <div className="controls"> {/* Controls container */}
          <h2>Score: {score}</h2> {/* Display the current score */}
          {isPlaying ? ( // Render upcoming blocks if game is playing
            <UpcomingBlocks upcomingBlocks={upcomingBlocks} />
          ) : (
            <div /> // Otherwise, render nothing
          )}
          <div className="mb-2"> {/* Buttons container */}
            <Button variant="primary" size="lg" onClick={(e) => { // Button to start a new game
              startGame(); // Call startGame function
              e.currentTarget.blur(); // Remove focus from button after click
            }}>
              New Game
            </Button>

            <Button variant={isPaused ? 'primary' : 'danger'} size="lg" onClick={(e) => { // Button to toggle pause
              togglePause(); // Call togglePause function
              e.currentTarget.blur(); // Remove focus from button after click
            }}>
              {isPaused ? 'Continue' : 'Pause'} {/* Display 'Continue' if game is paused, otherwise 'Pause' */}
            </Button>

          </div>

          <ul>
            {highScores.map((record, index) => ( // Map over high scores array
              <li key={index}>Game {index + 1}: {record.score}, Game Time: {record.duration} s</li> // Render high score record
            ))}
          </ul>
          {highScores.length > 0 && ( // Render button to clear high scores if there are high scores
            <button onClick={clearScores}>Clear Records</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Tetris; // Export Tetris component as default
