import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { Outlet } from 'react-router'; // Importing Outlet from react-router
import NavBar from './components/Navbar/NavBar'; // Importing the NavBar component
import { useAppDispatch } from '../redux/hooks'; // Importing custom hook useAppDispatch from Redux
import { windowWidthActionCreator } from '../redux/windowWidthSlice'; // Importing windowWidthActionCreator from Redux slice
import { windowScrollActionCreator } from '../redux/windowScrollSlice'; // Importing windowScrollActionCreator from Redux slice

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(true); // State to manage authentication status
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState(''); // State to store username
    const dispatch = useAppDispatch(); // Dispatch function from Redux

    useEffect(() => {
        // Effect to handle window resize and dispatch window width action
        const handleResize = () => {
            // Dispatch window width to Redux store on resize
            dispatch(windowWidthActionCreator(window.innerWidth));
        };
        // Effect to handle window scroll and dispatch window scroll action
        const handleScroll = () => {
            // Dispatch scroll position to Redux store on scroll
            dispatch(windowScrollActionCreator(window.scrollY));
        };
        // Adding event listeners for scroll and resize
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        // Cleaning up event listeners on component unmount
        return () => {
            // Clean up event listeners to prevent memory leaks
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch]); // Dependency array with dispatch

    const handleSubmit = () => {
        if (username == "admin27" && password == "%#*5R&_N#$$f!") {
            setIsAuthenticated(true)
        } else {
            window.alert("Wrong username or password.")
            setIsAuthenticated(false)
        }
        setUsername("")
        setPassword("")
    }

    return (
        !isAuthenticated ? <>
            <form onSubmit={handleSubmit}>
                <br />
                Username:
                <input type="text" value={username} onChange={(e) => { setUsername(e.currentTarget.value) }} />
                <br />
                <br />
                Password:
                <input type="password" value={password} onChange={(e) => { setPassword(e.currentTarget.value) }} />
                <br />
                <br />
                <input type="submit" />
            </form>
        </> : <>
            <NavBar />  {/* Render the navigation bar */}
            <Outlet />  {/* Render the current route's component*/}
        </>
    );
}

export default App; // Exporting the App component
