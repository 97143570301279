import holding_hands from "../../../../pictures/old_people/holding_hands.png"
import styles from "./HomeMain.module.scss"
import flower from "../../../../pictures/flower/flower.svg"
import { useNavigate } from "react-router-dom";
import { knowDementaParams, paths } from "../../../types/paths";

function Main() {
    const navigate = useNavigate()
    return (
        <>
            <div className={styles.imageWrapper}>
                <img src={holding_hands} alt="Holding hands" />
                <div className={styles.overlay}></div>
                <div className={styles.textBlock}>
                    <div className={styles.bigWord}>
                        <span className={styles.kelso}>AGESHMART </span>
                        Is Here For you
                        <span className={styles.star}><i className="fa-solid fa-star"></i></span>
                    </div>
                    <div className={styles.smallWord}>
                        Help you to Lower your Risk of <span className={styles.dementia}>DEMENTIA</span>
                    </div>
                    <div className={styles.buttons}>
                        <button onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.What)} className={styles.mainButton}>Get Started<i className="fa-solid fa-arrow-right"></i></button>
                        {/* <button className={styles.mainButton}>Obtain Brain Health<i className="fa-solid fa-arrow-right"></i></button> */}
                    </div>
                </div>
                <div className={styles.animatedElement}>
                    <img src={flower} alt="flower" />
                </div>
            </div>
        </>
    );
}

export default Main;