export enum paths {
    Home = "/iteration1/",
    KnowDementia = "/iteration1/dementia",
    DataVis = "/iteration1/data_vis",
    BrainHealth = "/iteration1/brain_health",
    Prediction = "/iteration1/predict",
}

export enum knowDementaParams {
    What = "what",
    Types = "types",
    Symptoms = "symptoms",
    Prevention = "prevent",
    Facts = "facts",
}