import * as React from 'react';
import styles from "./GameBox.module.scss"

function GameBox(props: {
    // Props representing the picture URLs for the game box
    picture: string,
    picture_hover: string,
    // Title of the game
    title: string,
    // Description of the game
    description: string,
    // Width of the game box
    box_width: string,
    // Height of the game box
    box_height: string,
    // Game number or identifier
    game_num: string,
    // Callback function to handle click events
    onClick: ()=>void
}) {
    // State to manage the current picture URL
    const [currentPic, setCurrentPic] = React.useState(props.picture)
    return (
        <>
            {/* Game box container */}
            <div
                className={styles.box} // Apply styles from module.scss file
                style={{ width: props.box_width, height: props.box_height }} // Set dynamic width and height
                onMouseEnter={() => setCurrentPic(props.picture_hover)} // Change picture on mouse enter
                onMouseLeave={() => setCurrentPic(props.picture)} // Revert picture on mouse leave
                onClick={()=>{props.onClick()}} // Handle click event
            >
                {/* Game picture */}
                <img src={currentPic} alt="my_logo" />
                {/* Game number or identifier */}
                <div className={styles.gameNum}>{props.game_num}</div>
                {/* Game title */}
                <div className={styles.title}>{props.title}</div>
                {/* Game description */}
                <div className={styles.description}>{props.description}</div>
                {/* Arrow icon */}
                <div className={styles.arrow}><i className="fa-solid fa-arrow-right"></i></div>
            </div>
        </>
    );
}

export default GameBox;