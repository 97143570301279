import * as React from 'react';
import styles from "./TetrisGame.module.scss"
import { Block, GameButton, GameSpeed, Msg, TetrisState } from '../gameTypes/types';
import { useTetrisPlayer } from '../hooks/useTetrisPlayer';
import { useInterval } from '../hooks/useInterval';
import { score_speed_conversion_double } from './others/tetrisGameCommonLogics';
import TetrisGamePanel from '../components/TetrisGamePanel';



function TetrisGameDouble(props: {
    setPreviousMode: (mode: number) => void
    setGameMode: (mode: number) => void
}) {
    const [gameButton, setGameButton] = React.useState<GameButton>(GameButton.start)
    const [speed, setSpeed] = React.useState<GameSpeed | null>(null)
    const { tetrisState: p1_tetrisState, initalizeGame: p1_initalizeGame, newGame: p1_newGame, gameTick: p1_gameTick, commitChanges: p1_commit } = useTetrisPlayer({playerNumber: 1})
    const { tetrisState: p2_tetrisState, initalizeGame: p2_initalizeGame, newGame: p2_newGame, gameTick: p2_gameTick, commitChanges: p2_commit } = useTetrisPlayer({playerNumber: 2})
    useInterval(() => {
        // determine gameover
        if (p1_tetrisState.isLose || p2_tetrisState.isLose){
            p1_tetrisState.isPlaying = false
            p2_tetrisState.isPlaying = false
            if (p1_tetrisState.isLose){
                p1_tetrisState.message = Msg.defeat
                p2_tetrisState.message = Msg.victory
            }else{
                p1_tetrisState.message = Msg.victory
                p2_tetrisState.message = Msg.defeat
            }
            p1_commit()
            p2_commit()
            setGameButton(GameButton.newGame)
            setSpeed(null)
        }else{
            p1_tetrisState.linesReceivingDuringThisTick += p2_tetrisState.linesSendingToOpponent
            p2_tetrisState.linesReceivingDuringThisTick += p1_tetrisState.linesSendingToOpponent
            p1_tetrisState.linesSendingToOpponent = 0
            p2_tetrisState.linesSendingToOpponent = 0
            p1_gameTick()
            p2_gameTick()
            const result = score_speed_conversion_double(p1_tetrisState.score + p2_tetrisState.score)
            p1_tetrisState.level = result[1]
            p2_tetrisState.level = result[1]
            const newSpeed = result[0]
            if (speed !== newSpeed){
                setSpeed(newSpeed)
            }
        }
    }, speed)

    const handleGameButtonClicked = () => {
        if (gameButton === GameButton.start) {
            setGameButton(GameButton.pause)
            p1_initalizeGame()
            p2_initalizeGame()
            setSpeed(score_speed_conversion_double(p1_tetrisState.score + p2_tetrisState.score)[0])
        }else if (gameButton === GameButton.pause){
            setGameButton(GameButton.continue)
            setSpeed(null)
        }else if (gameButton === GameButton.continue){
            setGameButton(GameButton.pause)
            setSpeed(score_speed_conversion_double(p1_tetrisState.score + p2_tetrisState.score)[0])
        }else if (gameButton === GameButton.newGame){
            setGameButton(GameButton.start)
            p1_newGame()
            p2_newGame()
            setSpeed(null)
        }
    }

    return (
        <div className={styles.gameWrapper}>
            <div className={styles.buttons}>
                <button className={styles.backButton} onClick={() => { props.setGameMode(0) }}><i className="fa-solid fa-arrow-left"></i> Back</button>
                <button onClick={handleGameButtonClicked} className={styles.gameButton}>
                    {gameButton === GameButton.start && <i className="fa-sharp fa-solid fa-flag-checkered"></i>}
                    {gameButton === GameButton.pause && <i className="fa-solid fa-pause"></i>}
                    {gameButton === GameButton.continue && <i className="fa-sharp fa-regular fa-circle-play"></i>}
                    {gameButton === GameButton.newGame && <i className="fa-solid fa-rotate-right"></i>}
                    {" " + gameButton}
                </button>
                <button className={styles.instructionButton} onClick={() => { props.setPreviousMode(2); props.setGameMode(3) }}> Guides <i className="fa-solid fa-arrow-right"></i></button>
            </div>
            <div className={styles.panelsWrapper}>
                <TetrisGamePanel state={p1_tetrisState} />
                <TetrisGamePanel state={p2_tetrisState} />
            </div>
        </div>
    );
}

export default TetrisGameDouble;