import { CellOptions } from './types'; // Importing CellOptions type from types file
import React from 'react'; // Importing React library
interface Props { // Defining Props interface with type property of type CellOptions
    type: CellOptions; 
}

function Cell({ type }: Props) { // Cell component with Props interface as argument
    return <div className={`cell1 ${type}`} />; // Return a div element with class name based on type prop
}

export default Cell; // Export the Cell component as default
