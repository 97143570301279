import * as React from 'react'; // Importing React library
import { ArticleType, MoreDetails } from '../../types/article'; // Importing ArticleType and MoreDetails types from article
import styles from "./Article.module.scss" // Importing styles from Article.module.scss
import { Accordion, Col, Row } from 'react-bootstrap'; // Importing Accordion, Col, and Row components from react-bootstrap
import { useNavigate } from 'react-router-dom'; // Importing useNavigate hook from react-router-dom

function Article(props: {
    content: ArticleType  // Props interface defining the content property of type ArticleType
}) {
    const navigate = useNavigate()// Initializing useNavigate hook to enable navigation

    // Function to generate each Accordion item
    function generateAccordion(v: MoreDetails, i: number) {
        return <Accordion.Item key={i} eventKey={i + ""}>  {/* Accordion item */}
            <Accordion.Header>{v.name}</Accordion.Header> {/* Accordion header */}
            <Accordion.Body> {/* Accordion body */}
                {v.block_description && <div className={styles.block_description}>{v.block_description}</div>} {/* Block description */}
                {v.descriptions && // Descriptions list
                    <>
                        <ul className={styles.descriptions}> {/* Descriptions list */}
                            {v.descriptions.map((description, idx) => { // Mapping through descriptions
                                return <li key={idx}>{description}</li> // Description item
                            })}
                        </ul>
                    </>}
                {v.causes && // Causes list
                    <>
                        <div className={styles.subtitle}>Causes</div> {/* Causes subtitle */}
                        <ul>
                            {v.causes.map((cause, idx) => { // Mapping through causes
                                return <li key={idx}>{cause}</li> // Cause item
                            })}
                        </ul>
                    </>}
                {v.symptoms && // Symptoms list
                    <>
                        <div className={styles.subtitle}>Symptoms</div> {/* Symptoms subtitle */}
                        <ul>
                            {v.symptoms.map((symptom, idx) => { // Mapping through symptoms
                                return <li key={idx}>{symptom}</li> // Symptom item
                            })}
                        </ul>
                    </>}
                {v.button && v.buttonLink && // Button to navigate
                    <>
                        <button onClick={() => { navigate(v.buttonLink!) }} className={styles.button}>{v.button} <i className="fa-solid fa-arrow-right"></i></button>

                    </>}
                {v.prevention && 
                <>
                        <div className={styles.subtitle}>Risk Reduction</div>
                        <div className={styles.descriptions}>{v.prevention}</div>
                </>}
            </Accordion.Body>
        </Accordion.Item>
    }

    return (
        <>
            <div className={styles.title}> {/* Article component */}
                {props.content.title} {/* Article title */}
            </div>
            {/* <img className={styles.image} src={props.content.image} alt="image" /> */}
            <div className={styles.aspect_description}>
                {props.content.title === "Dementia Prevention" && // Aspect description
                    <><a target="_blank" rel="noopener noreferrer" href='https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(20)30367-6/fulltext'>
                        <u>Research</u>
                    </a> has shown that targeting risk factors can prevent up to 40% of dementia cases.</>} {/* Research information */}
                {props.content.aspect_description}  {/* Aspect description */}

            </div>
            <div className={styles.keypoints}>
                <ul>
                    {props.content.keyPoints.map((v, i) => { // Key points list
                        return <li key={i}>{v}</li>
                    })}
                </ul>
            </div>
            <Accordion defaultActiveKey="" flush> {/* Accordion component */}
                <Row> {/* Row container */}
                    <Col xs={12} lg={6}> {/* Column container */}
                        {props.content.more_details.map((v, i) => { // Mapping through more details
                            return i % 2 === 0 ? generateAccordion(v, i) : <React.Fragment key={i} /> // Generating Accordion items
                        })}
                    </Col>
                    <Col xs={12} lg={6}> {/* Column container */}
                        {props.content.more_details.map((v, i) => {
                            return i % 2 === 1 ? generateAccordion(v, i) : <React.Fragment key={i} />
                        })}
                    </Col>
                </Row>
            </Accordion>
            {props.content.button && props.content.buttonLink && // Button to navigate
                <button onClick={() => { window.open(props.content.buttonLink) }} className={styles.button}>{props.content.button} <i className="fa-solid fa-arrow-right"></i></button>}
        </>
    );
}

export default Article;