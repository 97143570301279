import * as React from 'react';
import HomeMain from '../../components/Mains/HomeMain/HomeMain';
import HomeDementia from '../../components/Home/HomeDementia/HomeDementia';
import HomePrediction from '../../components/Home/HomePrediction/HomePrediction';
import HomeBrainGames from '../../components/Home/HomeBrainGames/HomeBrainGames';
import HomeDataVis from '../../components/Home/HomeDataVis/HomeDataVis';
function HomePage() {
    return (
        <>
            <HomeMain/>
            <HomeDementia/>
            <HomePrediction/>
            <HomeBrainGames/>
            <HomeDataVis/>
        </>
    );
}

export default HomePage;