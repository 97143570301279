import { Block, SHAPES } from './types'; // Importing Block enum and SHAPES object from types
import React from 'react'; // Importing React library
interface Props {
    upcomingBlocks: Block[]; // Interface defining the props expected by the UpcomingBlocks component
}

function UpcomingBlocks({ upcomingBlocks }: Props) {
    return (
        <div className="upcoming">  {/* Container for upcoming blocks */}
            {upcomingBlocks.map((block, blockIndex) => { // Mapping through the upcoming blocks
                const shape = SHAPES[block].shape.filter((row) => // Filtering the shape of the current block
                    row.some((cell) => cell) // Retaining rows that contain at least one filled cell
                );
                return (
                    <div key={blockIndex}> {/* Container for each upcoming block */}
                        {shape.map((row, rowIndex) => { // Mapping through each row of the block's shape
                            return (
                                <div key={rowIndex} className="row"> {/* Container for each row of the block */}
                                    {row.map((isSet, cellIndex) => { // Mapping through each cell of the row
                                        const cellClass = isSet ? block : 'hidden'; // Determining the class for the cell
                                        return (
                                            <div
                                                key={`${blockIndex}-${rowIndex}-${cellIndex}`} // Unique key for each cell
                                                className={`cell ${cellClass}`} // Applying the appropriate class to the cell
                                            ></div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}

export default UpcomingBlocks; // Exporting the UpcomingBlocks component
