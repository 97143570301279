import React from 'react';
import * as d3 from "d3";
import { data_wrangling, extract_symptom_types, extract_symptom_status, get_dementia_status } from "./data_wrangling"
import styles from "./DementiaZoomableSunburst.module.scss"

function DementiaZoomableSunburst(props) {
    const dementiaTrueRef = React.useRef(null)
    const dementiaFalseRef = React.useRef(null)
    const [dementiaStatusKeys, setDementiaStatusKeys] = React.useState([])
    const [dementiaSymptomTypes, setDementiaSymptomTypes] = React.useState([])
    const [dementiaSymptomStatus, setDementiaSymptomStatus] = React.useState([])
    const [dementiaSymptomData, setDementiaSymptomData] = React.useState({})
    const [currentSymptomType, setCurrentSymptomType] = React.useState("")

    React.useEffect(() => {
        async function fetchDataAndCreateSVG() {
            try {
                const response = await fetch(props.data_url, { method: 'GET' });
                const data = await response.json();
                const datasets_for_d3 = data_wrangling(data)
                const symptom_types = extract_symptom_types(data)
                setDementiaSymptomStatus(extract_symptom_status(data))
                setDementiaStatusKeys(get_dementia_status())
                setDementiaSymptomTypes(symptom_types)
                setCurrentSymptomType(symptom_types[0])
                setDementiaSymptomData(datasets_for_d3)
            } catch (e) {
                alert(e)
            }
        }
        fetchDataAndCreateSVG()
    }, [])

    React.useEffect(() => {
        try {
            if (Object.keys(dementiaSymptomData).length === 0) return

            // Create the color scale.
            const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, dementiaSymptomData[dementiaStatusKeys[0]][currentSymptomType].children.length + 1));

            // Specify the chart’s dimensions.
            const radius = props.width / 6;

            dementiaStatusKeys.forEach((status, i) => {
                const data = dementiaSymptomData[status][currentSymptomType]
                const svg = d3.select(i === 0 ? dementiaTrueRef.current : dementiaFalseRef.current)

                // Compute the layout.
                const hierarchy = d3.hierarchy(data).sum(d => d.value).sort((a, b) => b.value - a.value);
                const root = d3.partition().size([2 * Math.PI, hierarchy.height + 1])(hierarchy);
                root.each(d => d.current = d);

                const arc = d3.arc()
                    .startAngle(d => d.x0)
                    .endAngle(d => d.x1)
                    .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
                    .padRadius(radius * 1.5)
                    .innerRadius(d => d.y0 * radius)
                    .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1))

                // Remove unnecessary elements
                svg.selectAll("*").remove()

                // Set svg viewBox and dimensions
                svg.attr("viewBox", [-props.width / 2, -props.width / 2, props.width, props.width])
                    .style("font", "11px sans-serif");

                // Append the arcs.
                const path = svg.append("g")
                    .selectAll("path")
                    .data(root.descendants().slice(1))
                    .join("path")
                    .attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
                    .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.9 : computeOpacity(d)) : 0)
                    .attr("pointer-events", d => arcVisible(d.current) ? "auto" : "none")
                    .attr("d", d => arc(d.current))

                // Make them clickable if they have children.
                path.filter(d => d.children)
                    .style("cursor", "pointer")
                    .on("click", clicked);

                const label = svg.append("g")
                    .attr("pointer-events", "none")
                    .attr("text-anchor", "middle")
                    .style("user-select", "none")
                    .selectAll("text")
                    .data(root.descendants().slice(1))
                    .join("text")
                    .attr("dy", "0.35em")
                    .attr("fill-opacity", d => +labelVisible(d.current))
                    .attr("fill", "white")  // Set text color to white
                    .attr("transform", d => labelTransform(d.current))
                    .text(d => d.data.name_text + ` ${d.data.percentage}`);

                const parent = svg.append("circle")
                    .datum(root)
                    .attr("r", radius)
                    .attr("fill", "none")
                    .attr("pointer-events", "all")
                    .style("cursor", "pointer")
                    .on("click", clicked);

                svg.append("text")  // Append central text element
                    .attr("class", "central_text")
                    .attr("text-anchor", "middle")
                    .attr("fill", "white")  // Color of the text
                    .style("font-size", "20px")  // Size of the text
                    .text("Age Group")


                // Handle zoom on click.
                function clicked(event, p) {
                    parent.datum(p.parent || root);

                    svg.selectAll(".central_text").remove();
                    svg.append("text")  // Append central text element
                        .attr("class", "central_text")
                        .attr("text-anchor", "middle")
                        .attr("fill", "white")  // Color of the text
                        .style("font-size", "20px")  // Size of the text
                        .text(p.data.name_text)

                    root.each(d => d.target = {
                        x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                        x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                        y0: Math.max(0, d.y0 - p.depth),
                        y1: Math.max(0, d.y1 - p.depth)
                    });

                    const t = svg.transition().duration(750);

                    // Transition the data on all arcs, even the ones that aren’t visible,
                    // so that if this transition is interrupted, entering arcs will start
                    // the next transition from the desired position.
                    path.transition(t)
                        .tween("data", d => {
                            const i = d3.interpolate(d.current, d.target);
                            return t => d.current = i(t);
                        })
                        .filter(function (d) {
                            return +this.getAttribute("fill-opacity") || arcVisible(d.target);
                        })
                        .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.9 : computeOpacity(d)) : 0)
                        .attr("pointer-events", d => arcVisible(d.target) ? "auto" : "none")
                        .attrTween("d", d => () => arc(d.current));

                    label.filter(function (d) {
                        return +this.getAttribute("fill-opacity") || labelVisible(d.target);
                    }).transition(t)
                        .attr("fill-opacity", d => +labelVisible(d.target))
                        .attrTween("transform", d => () => labelTransform(d.current));
                }

                function arcVisible(d) {
                    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
                }

                function labelVisible(d) {
                    // Increase the minimum segment angle and area required for displaying labels
                    const minAngle = 0.1; // Minimum angular width (in radians) to show label
                    const minArea = 0.05; // Minimum area proportion to show label

                    return d.y1 <= 3 && d.y0 >= 1 && // Keep the radial constraints
                        (d.x1 - d.x0) > minAngle && // Require a larger angle for visibility
                        (d.y1 - d.y0) * (d.x1 - d.x0) > minArea; // Require a larger area for visibility
                }


                function labelTransform(d) {
                    const angle = (d.x0 + d.x1) / 2 * 180 / Math.PI;
                    const adjustedRadius = (d.y0 + d.y1) / 2 * radius;
                    return `rotate(${angle - 90}) translate(${adjustedRadius},0) rotate(${angle < 180 ? 0 : 180})`;
                }

                function computeOpacity(d) {
                    if (d.data.name === dementiaSymptomStatus[0]) {
                        return 0.9; // More opaque
                    } else if (d.data.name === dementiaSymptomStatus[1]) {
                        return 0.7; // Less opaque
                    } else {
                        return 0.5
                    }
                }
            })
        } catch (e) {
            alert(e)
        }
    }, [currentSymptomType, dementiaSymptomData, dementiaSymptomStatus])

    return (<>
        <div className={styles.wrapper_left}>
            <div className={styles.tips_wrapper}>
                <div className={styles.guide}>User Guide</div>
                <div className={styles.tips_sentence}>1. Select dementia symptoms in the middle dropdown.</div>
                <div className={styles.tips_sentence}>2. Zoom-in by clicking sections on the inner ring.</div>
                <div className={styles.tips_sentence}>3. Zoom-out by clicking the center of donut chart.</div>
            </div>
        </div>
        <div className={styles.wrapper}>
            <div className={styles.absolute}>
                <div className={styles.label}>Dementia Behaviour and Symptoms:</div>
                <select
                    onChange={(e) => setCurrentSymptomType(e.currentTarget.value)}
                    value={currentSymptomType}
                    className="form-select"
                    style={{ cursor: "pointer" }}
                >
                    {dementiaSymptomTypes.map((type, i) => <option key={i} value={type}>{type}</option>)}
                </select>
            </div>
        </div>
        <div className={styles.wrapper}>
            <div className={styles.title}>With Dementia</div>
            <div style={{ width: "120px" }}></div>
            <div className={styles.title}>Without Dementia</div>
        </div>
        <div className={styles.wrapper}>
            <div className={styles.graph}><svg ref={dementiaTrueRef} /></div>
            <div style={{ width: "120px" }}></div>
            <div className={styles.graph}><svg ref={dementiaFalseRef} /></div>
        </div>
    </>);
}

export default DementiaZoomableSunburst;