import { useReducer, Dispatch } from 'react';
import { Action, TetrisState } from '../gameTypes/types';
import { handleGameTick, cloneTetrisState, initializeTetrisState, jumpCurrentShapeToBottom, moveCurrentShapeToDown, moveCurrentShapeToLeft, moveCurrentShapeToRight, switchHoldingBlock, rotateCurrentShapeAntiClockwise, rotateCurrentShapeClockwise, createBlankState } from '../TetrisGame/others/tetrisGameCommonLogics';


export function useTetris(): [TetrisState, Dispatch<Action>] {
    const [tetrisState, dispatchTetrisState] = useReducer(
        tetrisReducer,
        createBlankState()
    );
    return [tetrisState, dispatchTetrisState];
}

function tetrisReducer(state: TetrisState, action: Action): TetrisState {
    let newState = cloneTetrisState(state)
    switch(action.type){
        case "Init":
            return initializeTetrisState()
        case "Blank":
            return createBlankState()
        case "Commit":
            return newState;
    }
    if (newState.isPlaying) {
        switch (action.type) {
            case "Jump":
                return jumpCurrentShapeToBottom(newState)
            case "Down":
                return moveCurrentShapeToDown(newState)
            case "Left":
                return moveCurrentShapeToLeft(newState)
            case "Right":
                return moveCurrentShapeToRight(newState)
            case "RotateClockwise":
                return rotateCurrentShapeClockwise(newState)
            case "RotateAntiClockWise":
                return rotateCurrentShapeAntiClockwise(newState)
            case "Hold":
                return switchHoldingBlock(newState)
            case "Tick":
                return handleGameTick(newState)
        }
    }
    return newState
}
