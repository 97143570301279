import Main from '../../components/Mains/Main'; // Import the Main component
import brainHealth from "../../../pictures/mains/brain_health.png"; // Import the image for Brain Health
import RoutePath from '../../components/RoutePath/RoutePath'; // Import the RoutePath component
import { sections } from '../../types/sections'; // Import the sections type

// Function component for the BrainHealth page
function BrainHealth() {
    return (
        <>
            {/* Render the Main component with the image and mainTitle props */}
            <Main image={brainHealth} mainTitle='Brain Health' />
            {/* Render the RoutePath component with the path prop containing sections.Home and sections.BrainHealth */}
            <RoutePath path={[sections.Home, sections.BrainHealth]} />
        </>
    );
}

export default BrainHealth; // Export the BrainHealth component
