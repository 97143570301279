import { createBrowserRouter } from 'react-router-dom'
import It1App from '../iteration1/App'
import It1NotFoundPage from '../iteration1/pages/NotFoundPage/NotFoundPage'
import It1HomePage from '../iteration1/pages/HomePage/HomePage'
import { paths as it1_paths } from '../iteration1/types/paths'
import It1DementiaPage from '../iteration1/pages/DementiaPage/DementiaPage'
import It1DataVisPage from '../iteration1/pages/DataVisPage/DataVisPage'
import It1BrainHealthPage from '../iteration1/pages/BrainHealthPage/BrainHealthPage'
import It1PredictionPage from '../iteration1/pages/PredictionPage/PredictionPage'
import It2App from '../iteration2/App'
import It2NotFoundPage from '../iteration2/pages/NotFoundPage/NotFoundPage'
import It2HomePage from '../iteration2/pages/HomePage/HomePage'
import { paths as it2_paths } from '../iteration2/types/paths'
import It2DementiaPage from '../iteration2/pages/DementiaPage/DementiaPage'
import It2DataVisPage from '../iteration2/pages/DataVisPage/DataVisPage'
import It2BrainHealthPage from '../iteration2/pages/BrainHealthPage/BrainHealthPage'
import It2PredictionPage from '../iteration2/pages/PredictionPage/PredictionPage'
import It3App from '../iteration3/App'
import It3NotFoundPage from '../iteration3/pages/NotFoundPage/NotFoundPage'
import It3HomePage from '../iteration3/pages/HomePage/HomePage'
import { paths as it3_paths } from '../iteration3/types/paths'
import It3DementiaPage from '../iteration3/pages/DementiaPage/DementiaPage'
import It3DataVisPage from '../iteration3/pages/DataVisPage/DataVisPage'
import It3BrainHealthPage from '../iteration3/pages/BrainHealthPage/BrainHealthPage'
import It3PredictionPage from '../iteration3/pages/PredictionPage/PredictionPage'
import It3SuggestPlaces from '../iteration3/hiking/SuggestPlaces/suggestplaces'

export const router = createBrowserRouter([
    {
        path: "/",
        element: <It3App />,
        children: [
            { path: "", element: <It3HomePage /> },
            { path: "*", element: <It3NotFoundPage /> },
            { path: it3_paths.KnowDementia + "/:aspect", element: <It3DementiaPage /> },
            { path: it3_paths.DataVis + "/:visNum", element: <It3DataVisPage /> },
            { path: it3_paths.BrainHealth, element: <It3BrainHealthPage /> },
            { path: it3_paths.Prediction, element: <It3PredictionPage /> },
            { path: it3_paths.BrainHealth + "/:games", element: <It3BrainHealthPage /> },
            { path: it3_paths.Hiking, element: <It3SuggestPlaces /> },

        ]
    },
    {
        path: "/iteration2",
        element: <It2App />,
        children: [
            { path: "", element: <It2HomePage /> },
            { path: "*", element: <It2NotFoundPage /> },
            { path: it2_paths.KnowDementia + "/:aspect", element: <It2DementiaPage /> },
            { path: it2_paths.DataVis + "/:visNum", element: <It2DataVisPage /> },
            { path: it2_paths.BrainHealth, element: <It2BrainHealthPage /> },
            { path: it2_paths.Prediction, element: <It2PredictionPage /> },
            { path: it2_paths.BrainHealth + "/:games", element: <It2BrainHealthPage /> },

        ]
    },
    {
        path: "/iteration1",
        element: <It1App />,
        children: [
            { path: "", element: <It1HomePage /> },
            { path: "*", element: <It1NotFoundPage /> },
            { path: it1_paths.KnowDementia + "/:aspect", element: <It1DementiaPage /> },
            { path: it1_paths.DataVis, element: <It1DataVisPage /> },
            { path: it1_paths.BrainHealth, element: <It1BrainHealthPage /> },
            { path: it1_paths.Prediction, element: <It1PredictionPage /> },
        ]
    }
])