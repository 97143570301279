import * as React from 'react';
import Main from '../../components/Mains/Main';
import { sections } from '../../types/sections';
import dementia from "../../../pictures/mains/dementia.png"
import RoutePath from '../../components/RoutePath/RoutePath';
import { Col, Row, Container } from 'react-bootstrap';
import styles from "./Dementia.module.scss"
import Aspect from '../../components/Aspect/Aspect';
import { articleContents } from "./articleContents"
import Article from '../../components/Article/Article';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { knowDementaParams, paths } from '../../types/paths';

function DementiaPage() {
    const navigate = useNavigate();
    const { aspect } = useParams();
    const location = useLocation();
    const [clicked, setClicked] = React.useState([false, false, false, false, false])
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get("goTop") === "true"){
            window.scrollTo(0, 0);
        }
        if (aspect === knowDementaParams.What) {
            setClicked([true, false, false, false, false])
        } else if (aspect === knowDementaParams.Types) {
            setClicked([false, true, false, false, false])
        } else if (aspect === knowDementaParams.Symptoms) {
            setClicked([false, false, true, false, false])
        } else if (aspect === knowDementaParams.Prevention) {
            setClicked([false, false, false, true, false])
        } else if (aspect === knowDementaParams.Facts) {
            setClicked([false, false, false, false, true])
        } else {
            navigate("404")
        }
    }, [aspect, navigate, location]);
    return (
        <>
            <Main image={dementia} mainTitle='About Dementia' />
            <RoutePath path={[sections.Home, sections.KnowDementia]} />
            <div className={styles.wrapper}>
                <Container>
                    <Row className={styles.Row}>
                        <Col xs={12} md={4}>
                            <div className={styles.leftNav}>
                                <div className={styles.title}><i className="fa-solid fa-star"></i> Aspects</div>
                                <Aspect index={0} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.What) }} title="What is Dementia" />
                                <Aspect index={1} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Types) }} title="Types of Dementia" />
                                <Aspect index={2} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Symptoms) }} title="Symptoms of Dementia" />
                                <Aspect index={3} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Prevention) }} title="Dementia Prevention" />
                                <Aspect index={4} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Facts) }} title="Some Dementia Facts" />
                            </div>
                        </Col>
                        <Col xs={12} md={8}>
                            <div className={styles.rightContent}>
                                {clicked[0] && <Article content={articleContents[0]} />}
                                {clicked[1] && <Article content={articleContents[1]} />}
                                {clicked[2] && <Article content={articleContents[2]} />}
                                {clicked[3] && <Article content={articleContents[3]} />}
                                {clicked[4] && <Article content={articleContents[4]} />}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default DementiaPage;