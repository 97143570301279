import Cell from './Cell'; // Importing the Cell component
import { BoardShape } from './types'; // Importing the BoardShape type
import React from 'react'; // Importing React library

interface Props { // Props interface specifying the currentBoard prop of type BoardShape
    currentBoard: BoardShape;
}

function Board({ currentBoard }: Props) { // Board component accepting Props interface as props
    return (
        <div className="board23"> {/* Board container */}
            {currentBoard.map((row, rowIndex) => ( // Mapping over rows of the currentBoard
                <div className="row" key={`${rowIndex}`}> {/* Row container */}
                    {row.map((cell, colIndex) => ( // Mapping over cells in each row
                        <Cell key={`${rowIndex}-${colIndex}`} type={cell} /> // Rendering Cell component for each cell
                    ))}
                </div>
            ))}
        </div>
    );
}

export default Board; // Exporting the Board component as default
