import * as React from 'react';
import styles from "./HomeHiking.module.scss"
import hiking_logo from "../../../../pictures/homeHiking/hiking_logo.svg"
import { useNavigate } from 'react-router';
import { paths } from '../../../types/paths';

function HomeHiking() {
    const navigate = useNavigate()
    return (<>
        <div className={styles.bg}>
            <div className={styles.content_block}>
                <img className={styles.my_img} src={hiking_logo} alt="hiking"></img>
                <div className={styles.textWrapper}>
                    <div className={styles.title}>Try our Hiking Advisor</div>
                    <div className={styles.description}>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.healthline.com/health-news/experts-say-walk-far-and-walk-quickly-to-reduce-your-risk-of-dementia#:~:text=Researchers%20say%20walking%20quickly%20as,to%20112%20steps%20per%20minute.'>
                            <u>Hiking can potentially lower the risk of developing dementia</u>
                        </a> by reducing stress and anxiety while in nature, which are known as risk factors for dementia. Additionally, the mental challenges of navigating trails and natural environments promote cognitive exercises that enhance brain resilience, supporting overall brain health.
                    </div>
                </div>
                <button
                    className={styles.my_but}
                    onClick={()=>{navigate(paths.Hiking)}}
                >
                    Let's Try <i className="fa-solid fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </>);
}

export default HomeHiking;