const dementia_true = "dementia_true"
const dementia_false = "dementia_false"

// Function for restructuring data for visualization
export function data_wrangling(data: {
    dementiaStatus: boolean;
    ageGroup: string;
    occupiedPercent: number;
    populationPercent: number;
    symptomStatus: string;
    symptomType: string;
}[]) {
    // base population asumption in each dementia symptoms and dementia status
    const base_num = 1000000;

    // Initialize restructured data object
    let restructured_data: {
        [key: string]: { // dementia status
            [key: string]: { // dementia types
                name: "age group",
                name_text: "Age Group",
                children: {
                    name: string,
                    name_text: string,
                    percentage: string,
                    children: {
                        name: string,
                        name_text: string,
                        percentage: string,
                        value: number
                    }[]
                }[]
            }
        }
    } = {}

    // Iterate over input data
    for (let d of data) {
        // Round population and types percentage
        const r_population_percent = Math.round(d.populationPercent * 10) / 10
        const r_types_percent = Math.round(d.occupiedPercent * 1) / 1

        // Generate text for age group
        const ageGroupText = "Age: " + d.ageGroup

        // Generate text for symptom types
        const typesText = d.symptomStatus.toLowerCase()

        // Calculate data value
        const dataValue = d.populationPercent / 100 * d.occupiedPercent / 100 * base_num

        // Determine dementia status key
        const status_key = d.dementiaStatus ? dementia_true : dementia_false

        // Check if status key exists in restructured data, if not, initialize
        if (!(status_key in restructured_data)) {
            restructured_data[status_key] = {}
        }

        // Check if symptom type exists in status key, if not, initialize
        if (!(d.symptomType in restructured_data[status_key])) {
            restructured_data[status_key][d.symptomType] = {
                "name": "age group",
                "name_text": "Age Group",
                "children": [{
                    "name": d.ageGroup,
                    "name_text": ageGroupText,
                    "percentage": `(${r_population_percent}%)`,
                    "children": [{
                        "name": d.symptomStatus,
                        "name_text": typesText,
                        "percentage": `(${r_types_percent}%)`,
                        "value": dataValue
                    }]
                }]
            }
        } else {
            // If symptom type exists, check if age group exists, if not, add it
            let age_group_children = restructured_data[status_key][d.symptomType]["children"]
            if (!age_group_children.map(j => j.name).includes(d.ageGroup)) {
                age_group_children.push({
                    "name": d.ageGroup,
                    "name_text": ageGroupText,
                    "percentage": `(${r_population_percent}%)`,
                    "children": [{
                        "name": d.symptomStatus,
                        "name_text": typesText,
                        "percentage": `(${r_types_percent}%)`,
                        "value": dataValue
                    }]
                })
            } else {
                // If age group exists, add symptom status under it
                for (let element of age_group_children) {
                    if (element["name"] === d.ageGroup) {
                        element["children"].push({
                            "name": d.symptomStatus,
                            "name_text": typesText,
                            "percentage": `(${r_types_percent}%)`,
                            "value": dataValue
                        })
                    }
                }
            }
        }
    }
    return restructured_data
}

// Function to get dementia status
export function get_dementia_status(){
    return [dementia_true, dementia_false]
}

// Function to extract unique symptom types from data
export function extract_symptom_types(data: {
    dementiaStatus: boolean;
    ageGroup: string;
    occupiedPercent: number;
    populationPercent: number;
    symptomStatus: string;
    symptomType: string;
}[]) {
    let symptom_types: string[] = []
    for (let d of data) {
        if (!(symptom_types.includes(d.symptomType))) {
            symptom_types.push(d.symptomType)
        }
    }
    return symptom_types.sort()
}

// Function to extract unique symptom status from data
export function extract_symptom_status(data: {
    dementiaStatus: boolean;
    ageGroup: string;
    occupiedPercent: number;
    populationPercent: number;
    symptomStatus: string;
    symptomType: string;
}[]) {
    let symptom_status: string[] = []
    for (let d of data) {
        if (!(symptom_status.includes(d.symptomStatus))) {
            symptom_status.push(d.symptomStatus)
        }
    }
    return symptom_status
}