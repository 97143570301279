import * as React from 'react'; // Import React
import Main from '../../components/Mains/Main'; // Import the Main component
import { sections } from '../../types/sections'; // Import the sections type
import dementia from "../../../pictures/mains/dementia.png"; // Import the image for Dementia
import RoutePath from '../../components/RoutePath/RoutePath'; // Import the RoutePath component
import { Col, Row, Container } from 'react-bootstrap'; // Import Col, Row, and Container from react-bootstrap
import styles from "./Dementia.module.scss"; // Import styles specific to the Dementia module
import Aspect from '../../components/Aspect/Aspect'; // Import the Aspect component
import { articleContents } from "../../dementiaArticle/articleContents"; // Import articleContents from the dementiaArticle directory
import Article from '../../components/Article/Article'; // Import the Article component
import { useLocation, useNavigate, useParams } from 'react-router-dom'; // Import useLocation, useNavigate, and useParams from react-router-dom
import { knowDementaParams, paths } from '../../types/paths'; // Import knowDementaParams and paths from types/paths

// Function component for the DementiaPage
function DementiaPage() {
    const navigate = useNavigate(); // Get the navigate function from useNavigate
    const { aspect } = useParams(); // Get the aspect parameter from the URL using useParams
    const location = useLocation(); // Get the current location using useLocation
    const [clicked, setClicked] = React.useState([false, false, false, false, false]); // Initialize state for clicked Aspects
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search); // Get the query parameters from the URL
        if (queryParams.get("goTop") === "true"){
            window.scrollTo(0, 0); // Scroll to the top of the page if "goTop" parameter is true
        }
        if (aspect === knowDementaParams.What) {
            setClicked([true, false, false, false, false]); // Set clicked state based on the aspect parameter
        } else if (aspect === knowDementaParams.Types) {
            setClicked([false, true, false, false, false]);
        } else if (aspect === knowDementaParams.Symptoms) {
            setClicked([false, false, true, false, false]);
        } else if (aspect === knowDementaParams.Prevention) {
            setClicked([false, false, false, true, false]);
        } else if (aspect === knowDementaParams.Facts) {
            setClicked([false, false, false, false, true]);
        } else {
            navigate("404"); // Navigate to 404 page if aspect parameter is not recognized
        }
    }, [aspect, navigate, location]); // Run this effect when aspect, navigate, or location change
    return (
        <>
            <Main image={dementia} mainTitle='About Dementia' /> {/* Render the Main component */}
            <RoutePath path={[sections.Home, sections.KnowDementia]} /> {/* Render the RoutePath component */}
            <div className={styles.wrapper}>
                <Container>
                    <Row className={styles.Row}>
                        <Col xs={12} md={4}>
                            <div className={styles.leftNav}>
                                <div className={styles.title}><i className="fa-solid fa-star"></i> Aspects</div> {/* Render the title for Aspects */}
                                <Aspect index={0} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.What) }} title="What is Dementia" /> {/* Render the Aspect component for "What is Dementia" */}
                                <Aspect index={1} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Types) }} title="Types of Dementia" /> {/* Render the Aspect component for "Types of Dementia" */}
                                <Aspect index={2} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Symptoms) }} title="Symptoms of Dementia" /> {/* Render the Aspect component for "Symptoms of Dementia" */}
                                <Aspect index={3} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Prevention) }} title="Dementia Prevention" /> {/* Render the Aspect component for "Dementia Prevention" */}
                                <Aspect index={4} clicked={clicked} onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Facts) }} title="Some Dementia Facts" /> {/* Render the Aspect component for "Some Dementia Facts" */}
                            </div>
                        </Col>
                        <Col xs={12} md={8}>
                            <div className={styles.rightContent}>
                                {clicked[0] && <Article content={articleContents[0]} />} {/* Render the Article component for "What is Dementia" if clicked */}
                                {clicked[1] && <Article content={articleContents[1]} />} {/* Render the Article component for "Types of Dementia" if clicked */}
                                {clicked[2] && <Article content={articleContents[2]} />} {/* Render the Article component for "Symptoms of Dementia" if clicked */}
                                {clicked[3] && <Article content={articleContents[3]} />} {/* Render the Article component for "Dementia Prevention" if clicked */}
                                {clicked[4] && <Article content={articleContents[4]} />} {/* Render the Article component for "Some Dementia Facts" if clicked */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default DementiaPage; // Export the DementiaPage component
