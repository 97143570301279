import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import DementiaTypesBubbleChart from '../../D3Graphs/DementiaTypesBubbleChart/DementiaTypesBubbleChart';
import styles from "./DataVisPageDementiaTypes.module.scss"

function DataVisPageDementiaTypes() {
    return (
        <>
            <Row><Col><div className={styles.title}>Breakdown of Dementia by Disease Subtypes</div></Col></Row>
            {/* Visualization and details for "Dementia Types Distribution" */}
            <Row>
                <Col xs={{ span: 12, order: 1 }} xxl={{ span: 6, order: 1 }}>
                    <DementiaTypesBubbleChart
                        width={700}
                        height={500}
                        margin={10}
                        data_url={process.env.REACT_APP_DEMENTIA_TYPES}
                    />
                </Col>

                <Col xs={{ span: 12, order: 2 }} xxl={{ span: 6, order: 2 }}>
                    {/* Descriptive text and details about various types of dementia */}
                    <div className={styles.starting}>Dementia encompasses a range of conditions that deteriorate cognitive abilities. Here are its main types, each with distinct symptoms and causes:</div>
                    <div className={styles.dTypeWrapper}>
                        {[
                            { title: "Alzheimer’s Disease (60%)", description: "This is the most prevalent type of dementia, making up 60-70% of cases. It features amyloid plaques and neurofibrillary tangles in the brain, which lead to the death of brain cells." },
                            { title: "Vascular Dementia (15%)", description: "This form arises from decreased blood flow to the brain, often due to a stroke, and can appear suddenly." },
                            { title: "Lewy Body Dementia (10%)", description: "This type is identified by the accumulation of Lewy bodies, abnormal proteins in the brain. Common symptoms include hallucinations and disruptions in sleep." },
                            { title: "Frontotemporal Dementia (FTD) (2%)", description: "FTD primarily impacts the brain's frontal and temporal lobes, causing shifts in behavior and a decline in language and social skills. It's more frequently seen in individuals under 65." },
                            { title: "Alcohol-Related Brain Injuries (5%)", description: " Results from long-term heavy drinking; affects memory and thinking skills." },
                            { title: "Other Dementia Types (8%)", description: "This type involves more than one dementia subtype, complicating diagnosis and management." },
                        ].map((d, i) => {
                            // Unique key for each item in the map for React's rendering efficiency
                            return <React.Fragment key={i}>
                                <div className={styles.subTitle}>{d.title}</div>
                                <div className={styles.description}>{d.description}</div>
                            </React.Fragment>
                        })}
                    </div>
                    <div className={styles.ending}>Understanding, progression, and treatments of all types of dementia, especially the rarer forms, remain underdeveloped, awaiting further research and insights as awareness and resources increase.</div>
                </Col>
            </Row>
        </>
    );
}

export default DataVisPageDementiaTypes;