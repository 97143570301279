import * as React from 'react';
import styles from "./HomeDementia.module.scss"
import { Col, Container, Row } from 'react-bootstrap';
import Rectangle from '../../Rectangle/Rectangle';
import what_is_dementia from '../../../../pictures/homeDementia/what_is_dementia1.png'
import types_of_dementia from '../../../../pictures/homeDementia/types_of_dementia.png'
import symptoms_of_dementia from '../../../../pictures/homeDementia/symptoms.png'
import risk_of_dementia from '../../../../pictures/homeDementia/drink.png'
import facts_of_dementia from '../../../../pictures/homeDementia/ds_facts.png'
import { useNavigate } from 'react-router-dom';
import { knowDementaParams, paths } from '../../../types/paths';

// HomeDementia component definition
function HomeDementia() {
    // Navigation hook
    const navigate = useNavigate();

    // Rendering HomeDementia component
    return (
        <>
            {/* Background section */}
            <div className={styles.bg}>
                <Container>
                    {/* Title and subtitle */}
                    <Row>
                        <Col>
                            {/* Title of the section */}
                            <div className={styles.title}>Dementia Details</div>
                            {/* Subtitle describing the purpose of the section */}
                            <div className={styles.subtitle}>Helps you to know more about dementia through different aspects</div>
                        </Col>
                    </Row>
                    {/* Rectangles section */}
                    <Row>
                        <Col xs={12}>
                            <div className={styles.flexRow}>
                                {/* Rectangle components for different aspects of dementia */}
                                <Rectangle
                                    picture={what_is_dementia}
                                    brief="Dementia is an umbrella term for ..."
                                    subtitle="What is Dementia"
                                    onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.What + "?goTop=true") }}
                                />
                                <Rectangle
                                    picture={types_of_dementia}
                                    brief="Dementia comes in different forms ..."
                                    subtitle="Types of Dementia"
                                    onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Types + "?goTop=true") }}
                                />
                                <Rectangle
                                    picture={symptoms_of_dementia}
                                    brief="When someone has dementia, they ..."
                                    subtitle="Symptoms of Dementia"
                                    onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Symptoms + "?goTop=true") }}
                                />
                                <Rectangle
                                    picture={risk_of_dementia}
                                    brief="The best way to prevent dementia ..."
                                    subtitle="Risk Factors of Dementia & Prevention"
                                    onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Prevention + "?goTop=true") }}
                                />
                                <Rectangle
                                    picture={facts_of_dementia}
                                    brief="In Australia, dementia is a ..."
                                    subtitle="Some Dementia Facts"
                                    onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Facts + "?goTop=true") }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default HomeDementia;
