import React from "react"
import "./keypad.css"

// Define the props type for the Keypad component
type KeypadProps = {
  handleClick: (val: number) => void // Function to handle key clicks
}

// Define the Keypad functional component
const Keypad: React.FC<KeypadProps> = ({ handleClick }) => {
  // Generate an array of key elements
  let keys = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
    return <div onClick={() => handleClick(num)} className="key" key={num}>{num}</div>
  })
  // Render the keypad component with key elements
  return (
    <div className="keypad">
      {keys}
    </div>
  )
}

export default Keypad // Export the Keypad component as default