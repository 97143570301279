import * as React from 'react';
import dataVis from "../../../pictures/mains/data_vis.png"
import Main from '../../components/Mains/Main';
import RoutePath from '../../components/RoutePath/RoutePath';
import { sections } from '../../types/sections';
function DataVisPage() {
    return (
        <>
            <Main image={dataVis} mainTitle='Data Visulization' />
            <RoutePath path={[sections.Home, sections.DataVis]} />
        </>
    );
}

export default DataVisPage;