import React, { useEffect, useState } from "react";
import { Board, Keypad, FunctionButtons } from "./components"; // Importing components
import "./app.css";// Importing CSS file
import axios from "axios";// Importing Axios for HTTP requests
import Alerts from "./components/Alerts/Alerts"; // Importing Alerts component

// Define types for history and selected tile
export type History = {
  prev: number;
  row: number;
  col: number;
};

export type SelectedTile = {
  row: number;
  col: number;
  isMutable: boolean;
};

// Define the Sudoku functional component
const Sudoku: React.FC = () => {
  const [grid, setGrid] = useState<number[][]>([
    [4, 5, 0, 0, 0, 0, 1, 7, 0],
    [3, 0, 0, 6, 5, 8, 4, 0, 0],
    [0, 6, 9, 7, 1, 0, 0, 0, 0],
    [8, 0, 4, 0, 0, 2, 0, 1, 0],
    [0, 9, 0, 0, 8, 0, 0, 0, 0],
    [0, 2, 7, 0, 0, 1, 0, 5, 3],
    [0, 0, 2, 9, 0, 0, 0, 3, 0],
    [5, 4, 6, 0, 0, 3, 9, 0, 8],
    [9, 0, 3, 0, 2, 6, 0, 4, 7],
  ]);
  const [solvedGrid, setSolvedGrid] = useState<number[][]>([
    [4, 5, 8, 2, 3, 9, 1, 7, 6],
    [3, 7, 1, 6, 5, 8, 4, 9, 2],
    [2, 6, 9, 7, 1, 4, 3, 8, 5],
    [8, 3, 4, 5, 6, 2, 7, 1, 9],
    [1, 9, 5, 3, 8, 7, 2, 6, 4],
    [6, 2, 7, 4, 9, 1, 8, 5, 3],
    [7, 8, 2, 9, 4, 5, 6, 3, 1],
    [5, 4, 6, 1, 7, 3, 9, 2, 8],
    [9, 1, 3, 8, 2, 6, 5, 4, 7],
  ]);

  const [selectedTile, setSelectedTile] = useState<SelectedTile>({
    row: -1,
    col: -1,
    isMutable: false,
  });
  const [history, setHistory] = useState<History[]>([]);
  const [showModal, setShowModal] = useState(false);

  // useEffect hook to initialize a new game when component mounts
  useEffect(() => {
    newGame()
  }, []);
  // Function to generate a new Sudoku game
  const newGame = () => {
    // Make HTTP request to generate Sudoku puzzle
    axios
      .get("https://sudoku-generator1.p.rapidapi.com/sudoku/generate", {
        headers: {
          "X-RapidAPI-Key": "55d0c23536msh6f6e5bb657fddfdp1d2b9bjsnc3c416008d49",
          "X-RapidAPI-Host": "sudoku-generator1.p.rapidapi.com",
        },
      })
      .then((res) => {
        // Extract puzzle from response data and convert to 2D array
        const puzzle: number[] = res.data.puzzle.split('').map(val => val === "." ? 0 : parseInt(val));
        const newPuzzle: number[][] = [];

        while (puzzle.length) {
          newPuzzle.push(puzzle.splice(0, 9));
        }
        setGrid(newPuzzle);

        // Request to solve the puzzle to get the solution
        return axios.get("https://sudoku-generator1.p.rapidapi.com/sudoku/solve", {
          headers: {
            "X-RapidAPI-Key": "55d0c23536msh6f6e5bb657fddfdp1d2b9bjsnc3c416008d49",
            "X-RapidAPI-Host": "sudoku-generator1.p.rapidapi.com",
          },
          params: {
            puzzle: res.data.puzzle,
          },
        });
      })
      .then((resSol) => {
        // Extract solution from response data and convert to 2D array
        const solution: number[] = resSol.data.solution.split('').map(val => parseInt(val));
        const newSolution: number[][] = [];
        while (solution.length) {
          newSolution.push(solution.splice(0, 9));
        }
        setSolvedGrid(newSolution);
      })
      .catch(() => {
        setShowModal(true); // Show modal if an error occurs
      });
  };


// Function to handle click on a tile in the Sudoku grid
  const handleTileClick = (
    row: number,
    col: number,
    val: number,
    isUserInput: boolean
  ) => {
    setSelectedTile((prev) => {
      const sameAsPrev = prev.row === row && prev.col === col;
      return sameAsPrev
        ? { row: -1, col: -1, isMutable: false }
        : { row: row, col: col, isMutable: isUserInput };
    });
  };

  // Function to handle click on a keypad button
  const handleKeypadClick = async (val: number) => {
    const hasSelectedTile: boolean =
      selectedTile.row !== -1 && selectedTile.col !== -1;
    if (hasSelectedTile && selectedTile.isMutable) {
      await setHistory((prevHistory) => [
        ...prevHistory,
        {
          prev: grid[selectedTile.row][selectedTile.col],
          row: selectedTile.row,
          col: selectedTile.col,
        },
      ]);
      await setGrid((prevGrid) => {
        const newGrid = [...prevGrid];
        newGrid[selectedTile.row][selectedTile.col] = val;
        return newGrid;
      });
      if (JSON.stringify(grid) === JSON.stringify(solvedGrid)) {
        alert("You Win");
      }
    }
  };

  const handleFnBtnClick = (fn: string) => {
    switch (fn) {
      // Undo btn
      case "undo": {
        const updatedHistory = [...history];
        const lastHistory = updatedHistory.pop();

        if (!lastHistory) break;

        let updatedGrid = grid.map((arr) => arr.slice());
        updatedGrid[lastHistory.row][lastHistory.col] = lastHistory.prev;

        setGrid(updatedGrid);
        setHistory(updatedHistory);
        break;
      }
      // Erase btn
      case "erase": {
        if (
          selectedTile.row === -1 ||
          selectedTile.col === -1 ||
          !selectedTile.isMutable
        )
          break;
        if (grid[selectedTile.row][selectedTile.col] === 0) break;

        eraseTile();

        break;
      }
      // Pencil btn
      case "newgame": {
        newGame();
        break;
      }
      default: {
        console.log("Function specified does not exist");
      }
    }
  };
  async function eraseTile() {
    await setHistory((prevHistory) => [
      ...prevHistory,
      {
        prev: grid[selectedTile.row][selectedTile.col],
        row: selectedTile.row,
        col: selectedTile.col,
      },
    ]);
    await setGrid((prev) => {
      const newGrid = [...prev];
      newGrid[selectedTile.row][selectedTile.col] = 0;
      return newGrid;
    });
  }
  return (
    <div className="App">

      <div className="aligner">
        <div className="my_container">
          <Board
            gridState={grid}
            solvedGrid={solvedGrid}
            handleClick={handleTileClick}
            selectedTile={selectedTile}
            history={history}
          />
          <div className="buttons">
            <FunctionButtons handleClick={handleFnBtnClick} />
            <Keypad handleClick={handleKeypadClick} />
          </div>
        </div>
        {/* <Alerts /> */}
      </div>
      {showModal}

    </div>
  );
};

export default Sudoku;
