import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Importing necessary hooks from react-router-dom for navigation and parameter retrieval.
import styles from "./DataVisSwitchButton.module.scss" // Importing CSS module for styling.
import { paths } from '../../types/paths'; // Importing predefined paths for routing.

// Functional component definition with destructured props for better readability.
function DataVisSwitchButton(props: {
    showVis: string,
    title: string,
    image_black: string,
    image_white: string,
    border: { left: string, right: string }
}) {
    const navigate = useNavigate(); // Hook to navigate programmatically.
    const [entered, setEntered] = React.useState(false) // State to track mouse hover.
    const [showChange, setShowChange] = React.useState(false) // State to manage style changes based on conditions.
    const { visNum } = useParams() // Retrieve parameters from the URL.

    // Effect hook to update the component based on URL parameter or hover state changes.
    React.useEffect(() => {
        if (visNum === props.showVis || entered) {
            setShowChange(true); // Activate style changes when the current visual matches the button or on hover.
        } else {
            setShowChange(false); // Deactivate style changes otherwise.
        }
    }, [entered, visNum]); // Depend on entered and visNum state to re-run effect.

    return (
        <div style={showChange ? {
            borderLeft: props.border.left, // Apply specific border style from props when condition is met.
            borderRight: props.border.right,
            backgroundColor: "#61ce70" // Change background color when active or hovered.
        } : {
            borderLeft: props.border.left, // Default border style from props.
            borderRight: props.border.right
        }}
            className={styles.wrapper} // CSS module class for consistent styling.
            onMouseEnter={() => { setEntered(true) }} // Set entered state to true when mouse enters.
            onMouseLeave={() => { setEntered(false) }} // Reset entered state when mouse leaves.
            onClick={() => { navigate(paths.DataVis + "/" + props.showVis) }} // Navigate to a different visualization on click.
        >
            <div className={showChange ? styles.title_change : styles.title}>{props.title}</div>
            <img className={showChange ? styles.icon_change : styles.icon} // Image with conditional styling.
                src={showChange ? props.image_white : props.image_black} // Change image source based on state.
                alt="my_icon" // Accessibility text for the image.
            />
        </div>
    );
}

export default DataVisSwitchButton; // Export component as the default export of the module.
