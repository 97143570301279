import * as React from 'react'; // Import React library
import styles from "./HomePrediction.module.css"; // Import CSS module
import { Col, Container, Row } from 'react-bootstrap'; // Import Bootstrap components
import predict from "../../../../pictures/homePrediction/predict.png"; // Import image
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { paths } from '../../../types/paths'; // Import paths from types

function HomePrediction() {
    const navigate = useNavigate(); // Initialize useNavigate hook
    return (
        <>
            {/* Background container */}
            <div className={styles.bg}>
                <Container> {/* Bootstrap container */}
                    <Row className={styles.row}>{/* Row with custom style */}
                        <Col xs={12} lg={4}>
                            <img className={styles.image} src={predict} alt="Hello" /> {/* Image */}
                        </Col>
                        <Col xs={12} lg={8}>
                            <div className={styles.wrapper}>
                                <div className={styles.smallword}><i className="fa-solid fa-star"></i> Powered by Light Gradient Boost Machine</div> {/* Small word */}
                                <div className={styles.title}>Risk Prediction</div>{/* Title */}
                                <div className={styles.subtitle}>Predict your risk of developing dementia based on your lifestyle.</div>{/* Subtitle */}
                                <div className={styles.message}>Note that the prediction result is intended for advice only and not as a formal diagnosis. Please consult a doctor for a comprehensive assessment if needed.</div>{/* Message */}
                                <button onClick={() => navigate(paths.Prediction)} className={styles.mainButton}>Check Your Risk <i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default HomePrediction; // Export HomePrediction component