import * as React from 'react'; // Import React library
import long_logo from "../../../pictures/logos/updated_logo/logo_white.svg" // Import white logo image
import long_logo_black from "../../../pictures/logos/updated_logo/logo_black.svg" // Import black logo image
import styles from "./NavBar.module.scss" // Import styles from NavBar module SCSS file
import { useAppSelector } from '../../../redux/hooks'; // Import custom Redux hook for selecting state
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for navigation
import { games, knowDementaParams, paths } from '../../types/paths'; // Import paths and parameters


// NavBar functional component
function NavBar() {
    // State for handling mobile menu toggle
    const [clicked, setClicked] = React.useState(false)
    // Redux selector for getting window width
    const width = useAppSelector(state => state.windowWidth.width)
    // Redux selector for getting window scroll position
    const scrolled = useAppSelector(state => state.windowScroll.scrolled)
    // Navigate function for navigation
    const navigate = useNavigate();

    return (
        <>
            {/* Conditional rendering based on window width */}
            {
                width <= 880 ?
                    // Mobile navigation bar
                    <nav className={styles.nav_mobile}>
                        <div className={styles.mobile}></div>
                        {/* Logo image */}
                        <img onClick={() => navigate(paths.Home)} src={long_logo_black} alt="AGESHMART" />
                        {/* Mobile menu button */}
                        <div className={styles.mobile} onClick={() => { setClicked(!clicked) }}>
                            {/* Close button */}
                            <i style={{ color: "black" }} className="fas fa-bars"></i>
                        </div>
                        <div className={`${styles.navbar_mobile} ${clicked ? styles.active : ""}`}>
                            <div className={styles.mobile} onClick={() => { setClicked(false) }}>
                                <i style={{ color: "white" }} className='fas fa-times'></i>
                            </div>
                            <ul>
                                    {/* Navigation items */}
                                <li><div onClick={() => {
                                    navigate(paths.KnowDementia + "/" + knowDementaParams.What + "?goTop=true")
                                    setClicked(false)
                                }} className={styles.text}>About Dementia</div></li>
                                <li><div onClick={() => {
                                    navigate(paths.Prediction)
                                    setClicked(false)
                                }} className={styles.text}>Risk Prediction</div></li>
                            </ul>
                        </div>
                    </nav> :
                    // Desktop navigation bar
                    <>
                        <nav className={scrolled === 0 ? styles.nav : styles.nav_scroll}>
                            {/* Logo */}
                            <img onClick={() => navigate(paths.Home)} className={styles.logo} src={scrolled === 0 ? long_logo : long_logo_black} alt="AGESHMART" />
                            {/* Navigation items */}
                            <ul className={styles.navbar}>
                                 {/* About Dementia dropdown */}
                                <li className={styles.navbar_item}>
                                    <div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.What + "?goTop=true")} className={scrolled === 0 ? styles.text : styles.scroll_text}>About Dementia <i className="fa-solid fa-chevron-down"></i></div>
                                    {/* Dropdown items */}
                                    <ul className={styles.dropdown}>
                                        <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.What + "?goTop=true")} className={styles.dropdownText}>What is Dementia</div></li>
                                        <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.Types + "?goTop=true")} className={styles.dropdownText}>Types of Dementia</div></li>
                                        <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.Symptoms + "?goTop=true")} className={styles.dropdownText}>Symptoms of Dementia</div></li>
                                        <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.Prevention + "?goTop=true")} className={styles.dropdownText}>Dementia Prevention</div></li>
                                        <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.Facts + "?goTop=true")} className={styles.dropdownText}>Some Dementia Facts</div></li>
                                    </ul>
                                </li>
                                {/* Risk Prediction */}
                                <li className={styles.navbar_item}>
                                    <div onClick={() => navigate(paths.Prediction)} className={scrolled === 0 ? styles.text : styles.scroll_text}>Risk Prediction</div>
                                </li>
                                <li className={styles.navbar_item}>
                                    <div onClick={() => navigate(paths.DataVis + "/1" + "?goTop=true")} className={scrolled === 0 ? styles.text : styles.scroll_text}>Dementia Statistics <i className="fa-solid fa-chevron-down"></i></div>
                                    <ul className={styles.dropdown}>
                                        <li><div onClick={() => navigate(paths.DataVis + "/1" + "?goTop=true")} className={styles.dropdownText}>Dementia Types Distribution</div></li>
                                        <li><div onClick={() => navigate(paths.DataVis + "/2" + "?goTop=true")} className={styles.dropdownText}>Dementia Cases Distribution</div></li>
                                        <li><div onClick={() => navigate(paths.DataVis + "/3" + "?goTop=true")} className={styles.dropdownText}>Dementia Prevalence Rate</div></li>
                                    </ul>
                                </li>
                                <li className={styles.navbar_item}>
                                    <div onClick={() => navigate(paths.BrainHealth + "/" + games.Tetris + "?goTop=true")} className={scrolled === 0 ? styles.text : styles.scroll_text}>Brain Games <i className="fa-solid fa-chevron-down"></i></div>
                                    <ul className={styles.dropdown}>
                                        <li><div onClick={() => navigate(paths.BrainHealth + "/" + games.Crossword + "?goTop=true")} className={styles.dropdownText}>Crossword</div></li>
                                        <li><div onClick={() => navigate(paths.BrainHealth + "/" + games.Tetris + "?goTop=true")} className={styles.dropdownText}>Tetris</div></li>
                                        <li><div onClick={() => navigate(paths.BrainHealth + "/" + games.Sudoku + "?goTop=true")} className={styles.dropdownText}>Sudoku</div></li>
                                    </ul>
                                </li>
                                <li className={styles.navbar_item}>
                                    <div className={scrolled === 0 ? styles.text : styles.scroll_text}>Other Iterations <i className="fa-solid fa-chevron-down"></i></div>
                                    <ul className={styles.dropdown}>
                                        <li><div onClick={() => navigate("/iteration1")} className={styles.dropdownText}>Iteration One</div></li>
                                        <li><div onClick={() => navigate("/iteration2")} className={styles.dropdownText}>Iteration Two</div></li>
                                        <li><div onClick={() => navigate("/")} className={styles.dropdownText}>Current Iteration</div></li>
                                    </ul>
                                </li>
                                {/* <div style={{ width: "20%" }}></div> */}
                            </ul>
                        </nav>
                    </>
            }
        </>
    );
}

export default NavBar;  // Export NavBar component as default