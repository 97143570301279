import * as React from 'react';
import styles from "./TetrisGame.module.scss"

function TetrisGameSingle(props: {
    setPreviousMode: (mode: number) => void
    setGameMode: (mode:number) => void
}) {
    return (
        <div className={styles.gameWrapper}>
            <button className={styles.backButton} onClick={() => {
                props.setGameMode(0)
            }}>
                <i className="fa-solid fa-arrow-left"></i> Back
            </button>
            <button className={styles.instructionButton} onClick={() => { 
                props.setPreviousMode(1)
                props.setGameMode(3)
            }}>
                Guides <i className="fa-solid fa-arrow-right"></i>
            </button>
            Game Single
        </div>
    );
}

export default TetrisGameSingle;