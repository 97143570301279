import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CrosswordGame from "../CrosswordGame/components/Crossword"
import styles from "./CrosswordEntryPage.module.scss"
import crossword_img from "../../../../pictures/brainGames/crossword_image.png"

function CrosswordEntryPage() {
    const [displayCrossword, setDisplayCrossword] = React.useState("none")
    React.useEffect(() => {
        function handleFullscreenChange() {
            if (!document.fullscreenElement) {
                setDisplayCrossword("none");
            }
        }

        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, []);


    return (<>
        <div className={styles.bg}>
            <Container>
                <Row>
                    <Col lg={6} xs={12}><img className={styles.my_img} src={crossword_img} alt="suduku" /></Col>
                    <Col lg={6} xs={12}>
                        <div className={styles.descriptions}>
                            Engaging in complex mentally stimulating activities such as Crossword is <a target="_blank" rel="noopener noreferrer" href='https://www.unsw.edu.au/newsroom/news/2021/09/do-brain-training-apps--games-and-puzzles-enhance-brain-health-'><u>associated with a reduced risk of dementia</u></a>. According to Dr. Nicole Kochan from UNSW Medicine & Health, activities like Sudoku, Crosswords, and other cognitive challenges can build a "cognitive reserve" that helps mitigate the brain changes linked to aging and neurocognitive disorders like Alzheimer's disease. This cognitive reserve is thought to potentially delay or reduce the progression of cognitive decline, contributing to a healthier brain as individuals age.
                        </div>
                        <div className={styles.descriptions}>
                            Let's try our simple Crossword Game to exercise your brain.
                        </div>
                        <div
                            className={styles.mainButton}
                            onClick={() => {
                                let elem = document.getElementById("crossword")!;
                                elem.requestFullscreen();
                                setDisplayCrossword("block")
                            }}
                        >Play Crossword in Full Screen Mode <i className="fa-solid fa-arrow-right"></i></div>
                        <div id="crossword" style={{ display: displayCrossword }}>
                            <div className={styles.crossword_bg}>
                                <button
                                    onClick={() => {
                                        if (document.fullscreenElement) {
                                            document.exitFullscreen()
                                        }
                                    }}
                                    className={styles.exitButton}
                                >
                                    <i className="fa-solid fa-left-from-bracket"></i> Exit Fullscreen Mode
                                </button>
                                <div className={styles.crosswordWrapper}>
                                    <CrosswordGame />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    </>);
}

export default CrosswordEntryPage;