import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import DementiaDistributionBarChart from '../../D3Graphs/DementiaDistributionBarChart/DementiaDistributionBarChart';
import styles from "./DataVisPageDementiaCases.module.scss"
import { useNavigate } from 'react-router';
import { paths } from '../../../../types/paths';

function DataVisPageDementiaCases() {
    const navigate = useNavigate();
    return (
        <>
            {/* Title Row: Displaying the title of the visualization */}
            <Row><Col><div className={styles.title}>Distribution of Dementia Cases by Gender among different Age Groups in Australia (June 2023)</div></Col></Row>
            <Row>
                <Col xs={{ span: 12, order: 1 }} xxl={{ span: 6, order: 1 }}>
                    <DementiaDistributionBarChart
                        width={700}
                        height={600}
                        margin={{ top: 20, right: 30, bottom: 50, left: 100 }}
                        data_url={process.env.REACT_APP_DEMENTIA_AGEWISE_DISTRIBUTION}
                    />
                </Col>
                {/* Main Content Row: Contains the bar chart and associated textual information */}
                <Col xs={{ span: 12, order: 2 }} xxl={{ span: 6, order: 2 }}>
                    <div className={styles.subTitle}>What is the strongest risk factor?</div>
                    <div className={styles.content}><span style={{ fontWeight: "900", color: "yellow" }}>Age</span> is the strongest known risk factor for dementia. The likelihood of developing dementia rises significantly as people get older, particularly after the age of 65. The incidence of dementia doubles roughly every five years after the age of 65.</div>
                    <div className={styles.subTitle}>Do women have a higher risk of getting dementia?</div>
                    <div className={styles.content}>Around twice as many women have Alzheimer's disease - the most common type of dementia - compared to men.</div>
                    <div className={styles.content}>The main reason for this greater risk is because women live longer than men and old age is the biggest risk factor for this disease.</div>
                    <div className={styles.subTitle}>Limitation of the chart</div>
                    <div className={styles.content}>The chart doesn't show total population of Women and Men in different age groups, so we cannot infer women is more susceptible of getting dementia.</div>
                    <div className={styles.centercenter}>
                        {/* Navigation Button: Provides a link to another related visualization */}
                        <button className={styles.myBut} onClick={() => {
                            navigate(paths.DataVis + "/3" + "?goTop=false")
                        }}>View Dementia Rates by Age and Gender <i className="fa-solid fa-arrow-right"></i></button>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default DataVisPageDementiaCases;