import styles from "./Main.module.scss" // Import styles from Main module SCSS file

// Main functional component with props for mainTitle and image
function Main(props: {
    mainTitle: string,
    image: string
}) {
    return (
        <>
            {/* Wrapper for image */}
            <div className={styles.imageWrapper}>
                {/* Image element */}
                <img className={styles.picture} src={props.image} alt="Hello"></img>
                {/* Overlay element */}
                <div className={styles.overlay}></div>
                {/* Text wrapper for main title */}
                <div className={styles.textWrapper}> {/* Main title */}
                    {props.mainTitle}
                </div>
            </div>
        </>
    );
}

export default Main;// Export Main component as default