import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import tetris_img from "../../../../pictures/brainGames/tetris_image.png"
import styles from "./TetrisEntryPage.module.scss"
import TetrisGameWelcome from '../TetrisGame/TetrisGame/TetrisGameWelcome';

function TetrisEntryPage() {
    const [displayTetris, setDisplayTetris] = React.useState("none")
    const [isTetrisActive, setIsTetrisActive] = React.useState(false);

    React.useEffect(() => {
        function handleFullscreenChange() {
            if (!document.fullscreenElement) {
                setDisplayTetris("none");
                setIsTetrisActive(false);
            }
        }

        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, []);


    return (<>
        <div className={styles.bg}>
            <Container>
                <Row>
                    <Col lg={6} xs={12}><img className={styles.my_img} src={tetris_img} alt="tetris" /></Col>
                    <Col lg={6} xs={12}>
                        <div className={styles.descriptions}>
                            <a target="_blank" rel="noopener noreferrer" href='https://www.socialworktoday.com/archive/exc_020811.shtml#:~:text=%E2%80%9CWe%20have%20no%20research%20on,perspective%2C%20it%20is%20quite%20complex.'>
                                <u>Research</u>
                            </a> indicates that playing Tetris may offer cognitive benefits that could be useful in reducing the risk of dementia. This popular video game has shown to create positive changes in brain areas associated with critical thinking, language processing, and coordinated movements. These areas are crucial as they are linked to reasoning, problem-solving, and memory functions that typically decline with age. Although direct research on Tetris's impact on older adults is limited, the principle of "use it or lose it" suggests that regularly engaging in complex tasks like Tetris could maintain or even enhance these cognitive functions, potentially acting as a preventive measure against cognitive decline and dementia.
                        </div>
                        <div className={styles.descriptions}>
                            Try our <b>self-made</b> Tetris Game to exercise your brain.
                        </div>
                        <div
                            className={styles.mainButton}
                            onClick={() => {
                                let elem = document.getElementById("tetris")!;
                                elem.requestFullscreen();
                                setDisplayTetris("block")
                                setIsTetrisActive(true);
                            }}
                        >
                            Play Tetris in Full Screen Mode <i className="fa-solid fa-arrow-right"></i>
                        </div>
                        <div id="tetris" style={{ display: displayTetris }} className={styles.tetris}>
                            <button
                                onClick={() => {
                                    if (document.fullscreenElement) {
                                        document.exitFullscreen()
                                    }
                                }}
                                className={styles.exitButton}
                            >
                                <i className="fa-solid fa-left-from-bracket"></i> Exit Fullscreen Mode
                            </button>
                            <div className={styles.tetris_bg}>
                                {isTetrisActive && <TetrisGameWelcome />}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    </>);
}

export default TetrisEntryPage;