import * as React from 'react';
import styles from "./VisBlock.module.scss"

function VisBlock(props: {
    picture: string,
    title: string,
    description: string,
    tag: string,
    marginLeft: string
    borderBottom?: string
    onClick?:()=>void
}) {
    return (
        <>
            <div
                className={styles.wrapper}
                onClick={props.onClick}
                style={
                    props.borderBottom ? {
                        marginLeft: props.marginLeft,
                        borderBottom: props.borderBottom
                    } : {
                        marginLeft: props.marginLeft
                    }}
            >
                <img className={styles.vis_img} src={props.picture} alt="my_img" />
                <div className={styles.content}>
                    <div className={styles.title}>{props.title}</div>
                    <div className={styles.description}>{props.description}</div>
                </div>
            </div>
        </>
    );
}

export default VisBlock;