export enum paths {
    Home = "/iteration2",
    KnowDementia = "/iteration2/dementia",
    DataVis = "/iteration2/data_vis",
    BrainHealth = "/iteration2/brain_health",
    Prediction = "/iteration2/predict",
}

export enum knowDementaParams {
    What = "what",
    Types = "types",
    Symptoms = "symptoms",
    Prevention = "prevent",
    Facts = "facts",
}

export enum games {
    Crossword = "crossword",
    Tetris = "tetris",
    Sudoku = "sudoku"
}