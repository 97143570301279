import * as React from 'react'; // Import React library
import styles from "./Rectangle.module.scss"; // Import styles for Rectangle component

// Rectangle component definition
function Rectangle(props:{
    picture:string, // Prop for image source
    brief:string, // Prop for brief description
    subtitle:string, // Prop for subtitle
    onClick:()=>void // Prop for click event handler
}) {
    return (
        <>
            {/* Rectangle container with onClick event */}
            <div onClick={props.onClick} className={styles.rectangle}>
                {/* Image container */}
                <div className={styles.picture}>
                    <img src={props.picture} alt="Hello"></img> {/* Image element */}
                </div>
                {/* Brief description */}
                <div className={styles.brief}>{props.brief}</div>
                {/* Bottom section */}
                <div className={styles.bottom}>
                    {/* Read More text */}
                    <div className={styles.readMore}>Read More</div>
                    {/* Subtitle */}
                    <div className={styles.subtitle}>{props.subtitle}</div>
                </div>
            </div>
        </>
    );
}

export default Rectangle; // Export Rectangle component
