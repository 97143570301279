import * as React from 'react';
import styles from "./HomeBrainGames.module.scss"
import { Col, Container, Row } from 'react-bootstrap';
import cross_green from "../../../../pictures/logos/brain_games/crossword_green.svg"
import cross_black from "../../../../pictures/logos/brain_games/crossword_black.svg"
import tetris_green from "../../../../pictures/logos/brain_games/tetris_green.svg"
import tetris_black from "../../../../pictures/logos/brain_games/tetris_black.svg"
import sudoku_green from "../../../../pictures/logos/brain_games/sudoku_green.svg"
import sudoku_black from "../../../../pictures/logos/brain_games/sudoku_black.svg"
import { useNavigate } from 'react-router-dom';
import { paths, games } from '../../../types/paths';
import GameBox from '../../Gamebox/GameBox';


// Component for rendering the home page with brain games section
function HomeBrainGames() {
    const navigate = useNavigate();
    // Set the width and height for the game boxes
    const box_width = "300px"
    const box_height = "400px"

    return (
        <>
            {/* Container for the section */}
            <Container>
                <Row>
                    <Col>
                        {/* Flex row layout */}
                        <div className={styles.flexRow}>
                            {/* Main box introducing brain games */}
                            <div className={styles.box} style={{ width: box_width, height: box_height }}>
                                {/* Star message */}
                                <div className={styles.starMsg}><i className="fa-solid fa-star"></i> Cognitive Training</div>
                                {/* Title */}
                                <div className={styles.title}>Brain Games</div>
                                {/* Description */}
                                <div className={styles.descriptions}><a target="_blank" rel="noopener noreferrer" href='https://www.chenmed.com/blog/connection-between-brain-games-and-dementia-prevention'>Research</a> indicates that participating in brain games can greatly support the preservation of cognitive abilities in elderly individuals, so here are 3 different brain games to maintain your brain health.</div>
                                {/* Main button */}
                                <button onClick={() => navigate(paths.BrainHealth + "/" + games.Crossword + "?goTop=true")} className={styles.mainButton}>Let's Try <i className="fa-solid fa-arrow-right"></i></button>
                            </div>
                            {/* Game boxes */}
                            <GameBox
                                picture={cross_green}
                                picture_hover={cross_black}
                                title="Crossword"
                                description="Practice in word retrieval and momory use is crucial for keeping the language areas of brain active. It can also contribute to building a cognitive reserve to delay the onset of dementia symptoms."
                                box_width={box_width}
                                box_height={box_height}
                                game_num="Game 01"
                                onClick={() => navigate(paths.BrainHealth + "/" + games.Crossword + "?goTop=true")}
                            />
                            <GameBox
                                picture={tetris_green}
                                picture_hover={tetris_black}
                                title="Tetris"
                                description="Improve your spatial reasoning and visual-spatial ability, enhance your concentration and attention, which can potentially delay the cognitive decline associated with dementia."
                                box_width={box_width}
                                box_height={box_height}
                                game_num="Game 02"
                                onClick={() => navigate(paths.BrainHealth + "/" + games.Tetris + "?goTop=true")}
                            />
                            <GameBox
                                picture={sudoku_green}
                                picture_hover={sudoku_black}
                                title="Sudoku"
                                description="Practice your logical thinking, problem-solving, short-term memory and concentration, which can potentially increase your neural plasticity, help you to combate cognitive decline."
                                box_width={box_width}
                                box_height={box_height}
                                game_num="Game 03"
                                onClick={() => navigate(paths.BrainHealth + "/" + games.Sudoku + "?goTop=true")}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HomeBrainGames;