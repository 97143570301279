import * as React from 'react';
import { TetrisState } from '../gameTypes/types';
import styles from "../TetrisGame/TetrisGame.module.scss"
import { cloneTetrisState, addShapeToBoard, addProjectionToBoard } from '../TetrisGame/others/tetrisGameCommonLogics';


function TetrisBoardComponent(props: {
    tetrisState: TetrisState
}) {
    const [state, setState] = React.useState<TetrisState>(props.tetrisState)
    React.useEffect(()=>{
        let newState = cloneTetrisState(props.tetrisState)
        newState = addProjectionToBoard(newState)
        newState = addShapeToBoard(newState)
        setState(newState)
    }, [props.tetrisState])

    return (
        <div className={styles.boardWrapper}>
            {state.board.map((block_row, i) => {
                return (<div key={i} className={styles.boardRowWrapper}>
                    {block_row.map((b, j) => {
                        const class_name = b === null ? "cell_empty_board" : b
                        return (
                            <div key={j} className={styles[class_name]}></div>
                        )
                    })}
                </div>)
            })}
        </div>
    );
}

export default TetrisBoardComponent;