/* Import React and styles */
import * as React from 'react';
import styles from "./HomeDataVis.module.scss"
/* Import images */
import home_data_vis from "../../../../pictures/homeDataVis/data_vis.png"
import bar from "../../../../pictures/homeDataVis/bar.png"
import bubble from "../../../../pictures/homeDataVis/bubble.png"
import pie from "../../../../pictures/homeDataVis/pie.png"
import line from "../../../../pictures/homeDataVis/line.png"
/* Import components */
import VisBlock from '../../VisBlock/VisBlock';
import { Col, Container, Row } from 'react-bootstrap';
/* Import router */
import { useNavigate } from 'react-router-dom';
/* Import paths */
import { paths } from '../../../types/paths';

/* Define the functional component */
function HomeDataVis() {
    /* Define styles */
    const borderBottom = "1px dashed #61ce70"
    const navigate = useNavigate();
    /* Render the component */
    return (
        <>
            {/* Background */}
            <div className={styles.bg}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={styles.wrapper}>
                                {/* Left side */}
                                <div className={styles.left}>
                                    {<img src={home_data_vis} alt="my_vis" />}
                                    {/* Information block */}
                                    <div className={styles.infoBlock}>
                                        <div className={styles.starMsg}><i className="fa-solid fa-star"></i> Data Visualization</div>
                                        <div className={styles.title}>Dementia Statistics</div>
                                        <div className={styles.description}>Graphs explaining about various types of dementia and behavioural symptoms of dementia</div>
                                        {/* Button to navigate */}

                                        <button onClick={() => navigate(paths.DataVis + "/1" + "?goTop=true")} className={styles.mainButton}>Look more <i className="fa-solid fa-arrow-right"></i></button>
                                    </div>
                                </div>
                                {/* Right side */}
                                <div className={styles.right}>
                                    {/* Visualization blocks */}
                                    <VisBlock
                                        onClick={()=> navigate(paths.DataVis + "/1" + "?goTop=true")}
                                        picture={bubble}
                                        title="Distribution of Dementia Types"
                                        description="A bubble chart that is showing prevalence of each dementia type in Australia"
                                        tag="bubble chart"
                                        marginLeft="180px"
                                        borderBottom={borderBottom}
                                    />
                                    <VisBlock
                                        onClick={()=> navigate(paths.DataVis + "/2" + "?goTop=true")}
                                        picture={bar}
                                        title="Distribution of Dementia Cases"
                                        description="A double bar chart that is showing distribution of dementia cases in different age groups in Australia"
                                        tag="bar chart"
                                        marginLeft="250px"
                                        borderBottom={borderBottom}
                                    />
                                    <VisBlock
                                        onClick={()=> navigate(paths.DataVis + "/3" + "?goTop=true")}
                                        picture={line}
                                        title="Prevalence Rate of Dementia"
                                        description="A line chart that is showing the prevalence rate of dementia in different age groups in Australia"
                                        tag="line chart"
                                        marginLeft="180px"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    );
}

/* Export the component */
export default HomeDataVis;