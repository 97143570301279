import React, { useEffect, useRef } from "react"
import { useTetris } from "./useTetris"

export function useTetrisPlayer(props: {
    playerNumber: number
}) {
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const quickSpeed = 100
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowUp':
                    props.playerNumber === 2 && dispatchTetrisState({ type: "RotateAntiClockWise" })
                    break;
                case 'ArrowDown':
                    if (props.playerNumber === 2) {
                        dispatchTetrisState({ type: "Down" })
                        if (!intervalRef.current) {
                            intervalRef.current = setInterval(() => {
                                dispatchTetrisState({ type: "Down" });
                            }, quickSpeed);
                        }
                    }
                    break;
                case 'ArrowLeft':
                    if (props.playerNumber === 2) {
                        dispatchTetrisState({ type: "Left" })
                        if (!intervalRef.current) {
                            intervalRef.current = setInterval(() => {
                                dispatchTetrisState({ type: "Left" });
                            }, quickSpeed);
                        }
                    }
                    break;
                case 'ArrowRight':
                    if (props.playerNumber === 2) {
                        dispatchTetrisState({ type: "Right" })
                        if (!intervalRef.current) {
                            intervalRef.current = setInterval(() => {
                                dispatchTetrisState({ type: "Right" });
                            }, quickSpeed);
                        }
                    }
                    break;
                case ',':
                    props.playerNumber === 2 && dispatchTetrisState({ type: "Hold" })
                    break;
                case '.':
                    props.playerNumber === 2 && dispatchTetrisState({ type: "Jump" })
                    break;
                case '/':
                    props.playerNumber === 2 && dispatchTetrisState({ type: "RotateClockwise" })
                    break;
                case '`':
                    props.playerNumber === 1 && dispatchTetrisState({ type: "Hold" })
                    break;
                case 'Tab':
                    event.preventDefault();
                    props.playerNumber === 1 && dispatchTetrisState({ type: "Jump" })
                    break;
                case 'CapsLock':
                    event.preventDefault();
                    props.playerNumber === 1 && dispatchTetrisState({ type: "RotateClockwise" })
                    break;
                case 'w':
                case 'W':
                    props.playerNumber === 1 && dispatchTetrisState({ type: "RotateAntiClockWise" })
                    break;
                case 'a':
                case 'A':
                    if (props.playerNumber === 1) {
                        dispatchTetrisState({ type: "Left" })
                        if (!intervalRef.current) {
                            intervalRef.current = setInterval(() => {
                                dispatchTetrisState({ type: "Left" });
                            }, quickSpeed);
                        }
                    }
                    break;
                case 's':
                case 'S':
                    if (props.playerNumber === 1) {
                        dispatchTetrisState({ type: "Down" })
                        if (!intervalRef.current) {
                            intervalRef.current = setInterval(() => {
                                dispatchTetrisState({ type: "Down" });
                            }, quickSpeed);
                        }
                    }
                    break;
                case 'd':
                case 'D':
                    if (props.playerNumber === 1) {
                        if (!intervalRef.current) {
                            dispatchTetrisState({ type: "Right" })
                            intervalRef.current = setInterval(() => {
                                dispatchTetrisState({ type: "Right" });
                            }, quickSpeed);
                        }
                    }
                    break;
                case ' ':
                    event.preventDefault();
                    break;
                default:
                    break;
            }
        };

        const handleKeyUp = (event) => {
            if (event.key === 'ArrowDown' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'a' ||
                event.key === 'd' ||
                event.key === 's' ||
                event.key === 'A' ||
                event.key === 'D' ||
                event.key === 'S'
            ) {
                clearInterval(intervalRef.current as NodeJS.Timeout);
                intervalRef.current = null;
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
            clearInterval(intervalRef.current as NodeJS.Timeout)
        };
    }, []);

    const [tetrisState, dispatchTetrisState] = useTetris()

    const initalizeGame = () => {
        dispatchTetrisState({ type: "Init" })
    }

    const newGame = () => {
        dispatchTetrisState({ type: "Blank" })
    }

    const gameTick = () => {
        dispatchTetrisState({ type: "Tick" })
    }

    const commitChanges = ()=>{
        dispatchTetrisState({ type: "Commit" })
    }

    return { tetrisState, initalizeGame, newGame, gameTick, commitChanges }
}
