import * as React from 'react'; // Import React library
import { sections } from "../../types/sections"; // Import sections type
import styles from "./RoutePath.module.scss"; // Import styles for RoutePath component
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { knowDementaParams, paths } from '../../types/paths'; // Import paths and knowDementaParams

// RoutePath component definition
function RoutePath(props: {
    path: sections[] // Prop for the path sections
}) {
    const navigate = useNavigate(); // useNavigate hook for navigation
    return (
        <>
            <div className={styles.container}>
                <div className={styles.path}>
                    {props.path.map((v, i) => (
                        i === props.path.length - 1 ?
                            <div className={styles.textLast} key={i}>{v}</div> // Render last section without separator
                            :
                            <React.Fragment key={i}>
                                {/* Render section with navigation functionality */}
                                <div
                                    onClick={() => {
                                        if (v === sections.Home) {
                                            navigate(paths.Home); // Navigate to Home path
                                        } else if (v === sections.KnowDementia) {
                                            navigate(paths.KnowDementia + "/" + knowDementaParams.What); // Navigate to KnowDementia path
                                        } else if (v === sections.DataVis) {
                                            navigate(paths.BrainHealth); // Navigate to BrainHealth path
                                        } else if (v === sections.BrainHealth) {
                                            navigate(paths.BrainHealth); // Navigate to BrainHealth path
                                        } else if (v === sections.Prediction) {
                                            navigate(paths.Prediction); // Navigate to Prediction path
                                        }
                                    }}
                                    className={styles.text}>
                                    {v}
                                </div>
                                <i className="fa-solid fa-minus"></i> {/* Render separator icon */}
                            </React.Fragment>
                    ))}
                </div>
            </div>
        </>
    );
}

export default RoutePath; // Export RoutePath component
