import * as React from 'react'; // Importing React library
import long_logo from "../../../pictures/logos/logo_long_white.svg"; // Importing image for white logo
import long_logo_black from "../../../pictures/logos/logo_long_black.svg"; // Importing image for black logo
import styles from "./NavBar.module.scss"; // Importing styles
import { useAppSelector } from '../../../redux/hooks'; // Importing Redux hook for selecting state
import { useNavigate } from 'react-router-dom'; // Importing hook for navigation
import { knowDementaParams, paths } from '../../types/paths'; // Importing paths and parameters

// NavBar component definition
function NavBar() {
    const [clicked, setClicked] = React.useState(false); // State for mobile menu toggle
    const width = useAppSelector(state => state.windowWidth.width); // Getting window width from Redux store
    const scrolled = useAppSelector(state => state.windowScroll.scrolled); // Getting scroll position from Redux store
    const navigate = useNavigate(); // Initializing navigate function for navigation

    // Rendering NavBar component
    return (
        <>
            {/* Conditional rendering based on screen width */}
            {width <= 880 ? (
                // Mobile navigation
                <nav className={styles.nav_mobile}>
                    <div className={styles.mobile}></div>
                    <img onClick={() => navigate(paths.Home)} src={long_logo_black} alt="AGESHMART" />
                    <div className={styles.mobile} onClick={() => { setClicked(!clicked) }}>
                        <i style={{ color: "black" }} className="fas fa-bars"></i>
                    </div>
                    {/* Mobile navigation menu */}
                    <div className={`${styles.navbar_mobile} ${clicked ? styles.active : ""}`}>
                        <div className={styles.mobile} onClick={() => { setClicked(false) }}>
                            <i style={{ color: "white" }} className='fas fa-times'></i>
                        </div>
                        <ul>
                            {/* Navigation items for mobile */}
                            <li><div onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.What + "?goTop=true"); setClicked(false) }} className={styles.text}>About Dementia</div></li>
                            <li><div onClick={() => { navigate(paths.Prediction); setClicked(false) }} className={styles.text}>Risk Prediction</div></li>
                        </ul>
                    </div>
                </nav>
            ) : (
                // Desktop navigation
                <>
                    <nav className={scrolled === 0 ? styles.nav : styles.nav_scroll}>
                        {/* Logo */}
                        <img onClick={() => navigate(paths.Home)} className={styles.logo} src={scrolled === 0 ? long_logo : long_logo_black} alt="AGESHMART" />
                        {/* Navigation items */}
                        <ul className={styles.navbar}>
                            {/* About Dementia dropdown */}
                            <li className={styles.navbar_item}>
                                <div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.What + "?goTop=true")} className={scrolled === 0 ? styles.text : styles.scroll_text}>About Dementia <i className="fa-solid fa-chevron-down"></i></div>
                                {/* Submenu for About Dementia */}
                                <ul className={styles.dropdown}>
                                    <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.What + "?goTop=true")} className={styles.dropdownText}>What is Dementia</div></li>
                                    <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.Types + "?goTop=true")} className={styles.dropdownText}>Types of Dementia</div></li>
                                    <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.Symptoms + "?goTop=true")} className={styles.dropdownText}>Symptoms of Dementia</div></li>
                                    <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.Prevention + "?goTop=true")} className={styles.dropdownText}>Dementia Prevention</div></li>
                                    <li><div onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.Facts + "?goTop=true")} className={styles.dropdownText}>Some Dementia Facts</div></li>
                                </ul>
                            </li>
                            {/* Risk Prediction */}
                            <li className={styles.navbar_item}>
                                <div onClick={() => navigate(paths.Prediction)} className={scrolled === 0 ? styles.text : styles.scroll_text}>Risk Prediction</div>
                            </li>
                            {/* Uncomment below to add more navigation items */}
                            <li className={styles.navbar_item}>
                                <div className={scrolled === 0 ? styles.text : styles.scroll_text}>Other Iterations <i className="fa-solid fa-chevron-down"></i></div>
                                <ul className={styles.dropdown}>
                                    <li><div onClick={() => navigate("/iteration1")} className={styles.dropdownText}>Iteration One</div></li>
                                    <li><div onClick={() => navigate("/iteration2")} className={styles.dropdownText}>Iteration Two</div></li>
                                    <li><div onClick={() => navigate("/")} className={styles.dropdownText}>Current Iteration</div></li>
                                </ul>
                            </li>
                            <div style={{ width: "40%" }}></div>
                        </ul>
                    </nav>
                </>
            )}
        </>
    );
}

export default NavBar; // Exporting NavBar component
