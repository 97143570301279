import * as React from 'react';
import styles from "./TetrisGame.module.scss"
import TetrisGameSingle from './TetrisGameSingle';
import TetrisGameDouble from './TetrisGameDouble';
import TetrisGameInstruction from './TetrisGameInstruction';

function TetrisGameWelcome() {
    const [mode, setMode] = React.useState(0)
    const [prvMode, setPrvMode] = React.useState(0)
    return (
        <div className={styles.tetris_bg}>
            {mode === 0 && <TetrisWelcome setGameMode={setMode} />}
            {mode === 1 && <TetrisGameSingle setGameMode={setMode} setPreviousMode={setPrvMode} />}
            {mode === 2 && <TetrisGameDouble setGameMode={setMode} setPreviousMode={setPrvMode} />}
            {mode === 3 && <TetrisGameInstruction goBack={() => { setMode(prvMode) }} />}
        </div>
    );
}

function TetrisWelcome(props: {
    setGameMode: (mode: number) => void
}) {
    return (<>
        <div className={styles.title}>Welcome to our self-made Tetris Game!</div>
        {/* <div
            onClick={() => { props.setGameMode(1) }}
            className={styles.mode_but}
        >Single Mode</div> */}
        <div
            onClick={() => { props.setGameMode(2) }}
            className={styles.mode_but}
        >Two Players Battle</div>
    </>)
}


export default TetrisGameWelcome;