import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface windowScrollState {
    scrolled: number
}

const initialState: windowScrollState = {
    scrolled: window.scrollY
}

export const windowScrollSlice = createSlice({
    name: 'windowScroll',
    initialState,
    reducers: {
        windowScrollReducer: (state:windowScrollState, action: PayloadAction<number>) => {
            state.scrolled = action.payload
        }
    }
})

// const { originalPropertyName: newVariableName } = objectToDestructure;
export const { windowScrollReducer: windowScrollActionCreator } = windowScrollSlice.actions;