import * as React from 'react';
import Main from '../../components/Mains/Main';
import predict from "../../../pictures/mains/prediction.png"
import RoutePath from '../../components/RoutePath/RoutePath';
import { sections } from '../../types/sections';
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css"
import styles from "./PredictionPage.module.scss"
import { knowDementaParams, paths } from '../../types/paths';
import { useNavigate } from 'react-router';

function Prediction() {
    const [age, setAge] = React.useState("60")
    const [weight, setWeight] = React.useState("30")
    const [gender, setGender] = React.useState("Male")
    const [eduLv, setEduLv] = React.useState("Primary School")
    const [famHis, setFamHis] = React.useState("Yes")
    const [smoke, setSmoke] = React.useState("Current Smoker")
    const [phys, setPhys] = React.useState("Sedentary")
    const [sleep, setSleep] = React.useState("Excellent")
    const [chronic, setChronic] = React.useState("Diabetes")
    const [alcohol, setAlcohol] = React.useState("None")
    const result_status = ["ready", "error", "fetching", "done"]
    const [result, setResult] = React.useState({
        status: result_status[0],
        error_msg: "",
        risk_level: "",
        result_css: "",
        recommend_msg: ""
    })
    const navigate = useNavigate();
    const handleTabChange = ({ prevIndex: currentIndex, _ }: {
        prevIndex: number;
        _: number;
    }) => {
        if (currentIndex === 8 && result.status !== result_status[0]) {
            setResult({
                status: result_status[0],
                error_msg: "",
                risk_level: "",
                result_css: "",
                recommend_msg: ""
            })
        }
    }

    const generateResult = (level: string) => {
        if (level === "low") {
            return {
                status: result_status[3],
                risk_level: "LOW",
                result_css: styles.low,
                recommend_msg: "Congratulation! We have predicted you have low risk of getting dementia in future, you may take a look at other risk factors that might further lower your risk of getting Dementia. ",
                error_msg: ""
            }
        } else if (level === "medium") {
            return {
                status: result_status[3],
                risk_level: "MEDIUM",
                result_css: styles.medium,
                recommend_msg: "We have predicted that you have a medium risk of developing dementia in the future. We recommend you take a look at other risk factors that might further lower your risk of getting dementia. ",
                error_msg: ""
            }
        } else {
            return {
                status: result_status[3],
                risk_level: "HIGH",
                result_css: styles.high,
                recommend_msg: "has shown that a healthy lifestyle can help avert 40% of dementia cases. Let's take a look at what you can do to lower your risk.",
                error_msg: ""
            }
        }
    }

    const handleComplete = async () => {
        const data = {
            "AlcoholLevel": alcohol,
            "Weight": weight,
            "Age": age,
            "Education_Level": (eduLv === "Diploma" || eduLv === "Undergraduate" || eduLv === "Postgraduate") ? "Diploma/Degree" : eduLv,
            "Gender": gender,
            "Family_History": famHis,
            "Smoking_Status": smoke,
            "Physical_Activity": phys === "High Intensity" ? "Moderate Activity" : phys,
            "Sleep_Quality": sleep === "Excellent" ? "Good" : (sleep === "Fair" ? "Poor" : sleep),
            "Chronic_Health_Conditions": chronic,
            "Dominant_Hand": "Left"
        }
        try {
            setResult({
                status: result_status[2],
                error_msg: "",
                risk_level: "",
                result_css: "",
                recommend_msg: ""
            })
            const url = process.env.REACT_APP_MACHINE_LEARNING + "/predict"
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            let result = await response.json()

            setTimeout(() => {
                console.log(result)
                setResult(generateResult(result["risk_level"]));
                (document.querySelector(".wizard-footer-right button") as HTMLElement)!.style.display = "none";
            }, 3000);
        } catch (e) {
            setResult({
                status: result_status[1],
                error_msg: e + "",
                risk_level: "",
                result_css: "",
                recommend_msg: ""
            })
        }
    }

    return (
        <>
            <Main image={predict} mainTitle='Risk Prediction' />
            <RoutePath path={[sections.Home, sections.Prediction]} />
            <div className={styles.wizardWrapper}>
                <FormWizard
                    finishButtonText="Predict"
                    onTabChange={handleTabChange}
                    onComplete={handleComplete}
                    color="#61ce70"
                    title="Dementia Prediction"
                    subtitle="Predict your risk of getting dementia based on our Machine Learning Model"
                >
                    <FormWizard.TabContent title="Basic Info" icon="fa-solid fa-1">
                        <h3 className={styles.questionTitle}>Please select your <span className={styles.word}>Age</span> and <span className={styles.word}>Weight</span>:</h3>
                        <button className={styles.myBut} onClick={() => { navigate(paths.DataVis + "/" + 2) }}>How Age affect Risk of Dementia <i className="fa-solid fa-arrow-right"></i></button>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrapper}>
                                <div className={styles.inputLabel}>Your Age:</div>
                                <select onChange={(e) => setAge(e.currentTarget.value)} value={age} className="form-select">
                                    <option value="60">60 - 64</option>
                                    <option value="65">65 - 69</option>
                                    <option value="70">70 - 74</option>
                                    <option value="75">75 - 79</option>
                                    <option value="80">80 - 84</option>
                                    <option value="85">85 - 89</option>
                                    <option value="90">90 or above</option>
                                </select>
                            </div>
                            <div className={styles.inputWrapper}>
                                <div className={styles.inputLabel}>Your Weight (kg):</div>
                                <select onChange={(e) => setWeight(e.currentTarget.value)} value={weight} className="form-select">
                                    <option value="30">30 - 39</option>
                                    <option value="40">40 - 49</option>
                                    <option value="50">50 - 59</option>
                                    <option value="60">60 - 69</option>
                                    <option value="70">70 - 79</option>
                                    <option value="80">80 - 89</option>
                                    <option value="90">90 - 99</option>
                                    <option value="100">100 or above</option>
                                </select>
                            </div>
                        </div>
                    </FormWizard.TabContent>
                    <FormWizard.TabContent title="Education Level" icon="fa-solid fa-2">
                        <h3 className={styles.questionTitle}>What is your <span className={styles.word}>Education Level</span>?</h3>
                        <button className={styles.myBut} onClick={() => { window.open("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3193875/") }}>How Education Level affect Risk of Dementia <i className="fa-solid fa-arrow-right"></i></button>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrapper}>
                                <div className={styles.inputLabel}>Education Level:</div>
                                <select onChange={(e) => setEduLv(e.currentTarget.value)} value={eduLv} className="form-select">
                                    <option value="Primary School">Primary School</option>
                                    <option value="Secondary School">Secondary School</option>
                                    <option value="Diploma">Diploma</option>
                                    <option value="Undergraduate">Undergraduate</option>
                                    <option value="Postgraduate">Postgraduate or higher</option>
                                    <option value="No School">No School</option>
                                </select>
                            </div>
                        </div>
                    </FormWizard.TabContent>
                    <FormWizard.TabContent title="Sex" icon="fa-solid fa-3">
                        <h3 className={styles.questionTitle}>What is your <span className={styles.word}>Sex</span>?</h3>
                        <button className={styles.myBut} onClick={() => { navigate(paths.DataVis + "/" + 3) }}>How Sex affects Risk of Dementia <i className="fa-solid fa-arrow-right"></i></button>
                        <div className={styles.inputWrapper}>
                            <div className={styles.inputLabel}>Sex:</div>
                            <select onChange={(e) => setGender(e.currentTarget.value)} value={gender} className="form-select">
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                    </FormWizard.TabContent>
                    <FormWizard.TabContent title="Family History" icon="fa-solid fa-4">
                        <h3 className={styles.questionTitle}>Do you have any family members with a <span className={styles.word}>History of Dementia</span>?</h3>
                        <button className={styles.myBut} onClick={() => { window.open("https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8689157/#:~:text=The%20average%20lifetime%20risk%20of,a%20family%20history%20of%20dementia.&text=This%20increased%20risk%20can%20be,on%20from%20parents%20to%20offspring.") }}>How Family History affects Risk of Dementia <i className="fa-solid fa-arrow-right"></i></button>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrapper}>
                                <div className={styles.inputLabel}>History of Dementia:</div>
                                <select onChange={(e) => setFamHis(e.currentTarget.value)} value={famHis} className="form-select">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                    </FormWizard.TabContent>
                    <FormWizard.TabContent title="Smoking Status" icon="fa-solid fa-5">
                        <h3 className={styles.questionTitle}>What is your <span className={styles.word}>Smoking Status</span>?</h3>
                        <button className={styles.myBut} onClick={() => { window.open("https://www.alzheimers.org.uk/about-dementia/managing-the-risk-of-dementia/reduce-your-risk-of-dementia/smoking#:~:text=There%20is%20strong%20evidence%20to,Alzheimer's%20disease%20and%20vascular%20dementia.") }}>How Smoking affects Risk of Dementia <i className="fa-solid fa-arrow-right"></i></button>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrapper}>
                                <div className={styles.inputLabel}>Smoking Status:</div>
                                <select onChange={(e) => setSmoke(e.currentTarget.value)} value={smoke} className="form-select">
                                    <option value="Current Smoker">Current Smoker</option>
                                    <option value="Former Smoker">Former Smoker</option>
                                    <option value="Never Smoked">Never Smoked</option>
                                </select>
                            </div>
                        </div>
                    </FormWizard.TabContent>
                    <FormWizard.TabContent title="Physical Activity" icon="fa-solid fa-6">
                        <h3 className={styles.questionTitle}>What is your <span className={styles.word}>Physical Activity Status</span>?</h3>
                        <button className={styles.myBut} onClick={() => { window.open("https://www.dementia.org.au/brain-health/reducing-your-risk-dementia/physical-exercise") }}>How Physical Activity affects Risk of Dementia <i className="fa-solid fa-arrow-right"></i></button>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrapper}>
                                <div className={styles.inputLabel}>Physical Activity Status:</div>
                                <select onChange={(e) => setPhys(e.currentTarget.value)} value={phys} className="form-select">
                                    <option value="Sedentary">Sedentary</option>
                                    <option value="Mild Activity">Mild Activity</option>
                                    <option value="Moderate Activity">Moderate Activity</option>
                                    <option value="High Intensity">High Intensity</option>
                                </select>
                            </div>
                        </div>
                    </FormWizard.TabContent>
                    <FormWizard.TabContent title="Sleep Quality" icon="fa-solid fa-7">
                        <h3 className={styles.questionTitle}>What is your <span className={styles.word}>Sleeping Quality</span>?</h3>
                        <button className={styles.myBut} onClick={() => { window.open("https://www.dementia.org.au/brain-health/reducing-your-risk-dementia/sleep") }}>How Sleep Quality affects Risk of Dementia <i className="fa-solid fa-arrow-right"></i></button>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrapper}>
                                <div className={styles.inputLabel}>Sleep Quality:</div>
                                <select onChange={(e) => setSleep(e.currentTarget.value)} value={sleep} className="form-select">
                                    <option value="Excellent">Excellent</option>
                                    <option value="Good">Good</option>
                                    <option value="Fair">Fair</option>
                                    <option value="Poor">Poor</option>
                                </select>
                            </div>
                        </div>
                    </FormWizard.TabContent>
                    <FormWizard.TabContent title="Chronic Health" icon="fa-solid fa-8">
                        <h3 className={styles.questionTitle}>Do your have following <span className={styles.word}>Chronic Health Conditions</span>?</h3>
                        <button className={styles.myBut} onClick={() => { window.open("https://www.cdc.gov/aging/publications/chronic-diseases-brief.html") }}>How Chronic Disease affects Risk of Dementia <i className="fa-solid fa-arrow-right"></i></button>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrapper}>
                                <div className={styles.inputLabel}>Chronic Health Conditions:</div>
                                <select onChange={(e) => setChronic(e.currentTarget.value)} value={chronic} className="form-select">
                                    <option value="Diabetes">Diabetes</option>
                                    <option value="Hypertension">Hypertension</option>
                                    <option value="Heart Disease">Heart Disease</option>
                                    <option value="None">None</option>
                                </select>
                            </div>
                        </div>
                    </FormWizard.TabContent>
                    <FormWizard.TabContent title="Alcohol Consumption" icon="fa-solid fa-9">
                        <h3 className={styles.questionTitle}>How many <span className={styles.word}>Standard Alcoholic Drinks</span> do you normally consume per week?<br />1 standard drink = 375ml 3.5% beer. More definition is available <a target="_blank" rel="noopener noreferrer" href='https://www.cancervic.org.au/cancer-information/preventing-cancer/limit-alcohol/standard-drink'><u>here</u></a>.</h3>
                        <button className={styles.myBut} onClick={() => { window.open("https://www.dementia.org.au/brain-health/reducing-your-risk-dementia/alcohol-tobacco-and-other-drugs") }}>How Alcohol affects Risk of Dementia <i className="fa-solid fa-arrow-right"></i></button>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrapper}>
                                <div className={styles.inputLabel}>I normally consume this amount per week:</div>
                                <select onChange={(e) => setAlcohol(e.currentTarget.value)} value={alcohol} className="form-select">
                                    <option value="None">I don't drink</option>
                                    <option value="Sometimes">5 drinks or less</option>
                                    <option value="Often">in between 6 and 10 drinks</option>
                                    <option value="Usually">11 drinks or more</option>
                                </select>
                            </div>
                        </div>
                    </FormWizard.TabContent>
                    <FormWizard.TabContent title="Prediction Result" icon="fa-solid fa-gears">
                        {result.status === result_status[0] && <h3 className={styles.questionTitle}>Ready to get your prediction result?<br />Please click <span className={styles.wordButton}>Predict</span> to obtain your <span className={styles.word}>Risk Level</span>.</h3>}
                        {result.status === result_status[1] && <h3 className={styles.questionTitle}><span className={styles.errorText}>{result.error_msg}</span></h3>}
                        {result.status === result_status[2] && <div className={styles.predicting}>
                            <h3 className={styles.questionTitle}>Our Machine Learning Model is Predicting your <span className={styles.word}>Risk Level</span>. </h3>
                            <div className={styles.loader}></div>
                        </div>}
                        {result.status === result_status[3] && <>
                            <h3 className={styles.questionTitle}>Your risk level is: </h3>
                            <div className={result.result_css}>{result.risk_level}</div>
                            <div className={styles.recommend}> {result.risk_level === "HIGH" && <a target="_blank" rel="noopener noreferrer" href='https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(20)30367-6/fulltext'><u>Research</u></a>} {result.recommend_msg} <button onClick={() => { navigate(paths.KnowDementia + "/" + knowDementaParams.Prevention) }}>Learn More <i className="fa-solid fa-arrow-right"></i></button></div>
                        </>}
                    </FormWizard.TabContent>
                </FormWizard>
            </div>
        </>
    );
}

export default Prediction;