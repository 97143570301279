import * as React from 'react';
import * as d3 from "d3"
import styles from "./DementiaPrevalenceRateLineChart.module.scss"

// Component to render a line chart showing the prevalence rate of dementia across different age groups.
function DementiaPrevalenceRateLineChart(props) {
    const tooltipRef = React.useRef(null); // Reference for the tooltip element.
    const graphRef = React.useRef(null); // Reference for the SVG element where the graph will be drawn.
    const data_url = props.data_url

    // useEffect to run once on component mount.
    React.useEffect(() => {
        // Async function to fetch data and create the SVG.
        async function fetchDataAndCreateSVG() {
            try {
                const response = await fetch(data_url, { method: 'GET' });
                const data = await response.json(); // Parsing the JSON data from response.
                var svgWidth = props.width; // SVG width derived from props.
                var svgHeight = props.height; // SVG height derived from props.
                var margin = { top: 20, right: 150, bottom: 50, left: 80 }; // Margins for the SVG.
                var width = svgWidth - margin.left - margin.right; // Effective width after accounting for margins.
                var height = svgHeight - margin.top - margin.bottom; // Effective height after margins.

                // Append SVG to the body
                var svg = d3.select(graphRef.current)
                    .attr("width", svgWidth)
                    .attr("height", svgHeight)
                    .append("g")
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

                // Scale functions
                var xScale = d3.scaleBand()
                    .domain(data.map(function (d) { return d.ageGroup; }))
                    .range([0, width])
                    .padding(0.1);

                var yScale = d3.scaleLinear()
                    .domain([0, d3.max(data, function (d) { return d.percentage })])
                    .range([height, 0]);

                // Add X axis
                const xAxis = svg.append("g")
                    .attr("transform", "translate(0," + height + ")")
                    .call(d3.axisBottom(xScale))

                xAxis.selectAll("text")
                    .attr("fill", "white")
                    .style("font-size", "15px")

                xAxis.selectAll("line, path")
                    .attr("stroke", "white")
                    .style("stroke-width", "2px")

                // Add Y axis
                const yAxis = svg.append("g")
                    .call(d3.axisLeft(yScale));

                // Add horizontal grid lines
                yAxis.selectAll("line")
                    .attr("x2", width)
                    .attr("stroke", "#ddd") // color of the grid lines
                    .style("stroke-width", "1px"); // width of the grid lines

                yAxis.selectAll("text")
                    .attr("fill", "white")
                    .style("font-size", "15px")

                yAxis.selectAll("line, path")
                    .attr("stroke", "white")
                    .style("stroke-width", "2px")

                // Add title to X axis
                svg.append("text")
                    .attr("class", "axis-title")
                    .attr("text-anchor", "middle")
                    .attr("x", width / 2)
                    .attr("y", height + margin.top + 20)
                    .text("Age group (years)")
                    .attr("fill", "white")
                    .style("font-size", "15px")

                // Add title to Y axis
                svg.append("text")
                    .attr("class", "axis-title")
                    .attr("text-anchor", "middle")
                    .attr("transform", "rotate(-90)")
                    .attr("x", -height / 2)
                    .attr("y", -margin.left + 40)
                    .text("Percentage")
                    .attr("fill", "white")
                    .style("font-size", "15px")

                // Define line function
                var line = d3.line()
                    .x(function (d) { return xScale(d.ageGroup) + xScale.bandwidth() / 2; })
                    .y(function (d) { return yScale(d.percentage); });

                // Draw lines
                var colors = ["steelblue", "salmon"];
                var legendData = ["Male", "Female"];
                svg.selectAll(styles.line)
                    .data([
                        data.filter(function (d) { return d.sex === 'Men'; }),
                        data.filter(function (d) { return d.sex === 'Women'; })
                    ])
                    .enter().append("path")
                    .attr("class", styles.line)
                    .attr("d", line)
                    .style("stroke", function (d, i) { return colors[i]; }); // Different colors for male and female;

                // Draw data points
                // Add data points as circles.
                svg.selectAll(".dot")
                    .data(data)
                    .enter().append("circle")
                    .attr("class", "dot")
                    .attr("cx", function (d) { return xScale(d.ageGroup) + xScale.bandwidth() / 2; })
                    .attr("cy", function (d) { return yScale(d.percentage) })
                    .attr("r", 6) // Radius of the data points
                    .style("fill", "yellow") // Color of the data points
                    .style("cursor", "pointer")
                    .style("stroke", function (d) { return colors[legendData.indexOf(d.sex)]; }) // Stroke color based on sex
                    .style("stroke-width", 2) // Stroke width of the data points
                    .on("mouseover", function (event, d) {
                        tooltip.style("display", "inline");
                    })
                    .on("mousemove", function (event, d) {
                        var percentage = d.percentage;
                        tooltip.html("Percentage: " + percentage + "%")
                            .style("left", (event.pageX + 10) + "px")
                            .style("top", (event.pageY - 15) + "px");
                    })
                    .on("mouseout", function (d) {
                        tooltip.style("display", "none");
                    })

                // Add legend
                var legend = svg.selectAll(".legend")
                    .data(legendData)
                    .enter()
                    .append("g")
                    .attr("class", "legend")
                    .attr("transform", function (d, i) { return "translate(100," + i * 30 + ")"; });

                legend.append("line")
                    .attr("x1", width - 30)
                    .attr("y1", -3)
                    .attr("x2", width - 15)
                    .attr("y2", -3)
                    .attr("stroke", function (d, i) { return colors[i]; })
                    .attr("class", styles.legendLine);

                legend.append("text")
                    .attr("x", width - 10)
                    .attr("y", 0)
                    .attr("dy", ".35em")
                    .attr("fill", function (d, i) { return colors[i]; })
                    .attr("class", styles.legendText)
                    .style("text-anchor", "start")
                    .text(function (d) { return d; });

                // Define tooltip
                var tooltip = d3.select(tooltipRef.current);

            } catch (e) {
                // Error handling: Display error message within the chart container.
                document.querySelector(".prevalenceRateWrapper").innerHTML = `<div>${e}</div>`
            }
        }
        fetchDataAndCreateSVG()
    }, [])
    return (
        <>
            <div className="prevalenceRateWrapper" style={{
                margin: props.margin + "px",
                width: props.width + "px",
                height: props.height + "px"
            }}>
                <svg ref={graphRef}></svg>
                <div className={styles.tooltip_rate} ref={tooltipRef}></div>
            </div>
        </>
    );
}

export default DementiaPrevalenceRateLineChart;