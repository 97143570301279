import fact from "../../../pictures/knowDementia/facts.png"
import smoking from "../../../pictures/knowDementia/smoke.png"
import brains from "../../../pictures/knowDementia/types_of_dementia.png"
import symptoms from "../../../pictures/knowDementia/symptoms.png"
import old_women from "../../../pictures/knowDementia/old_women.png"
import { ArticleType } from "../../types/article"
import { knowDementaParams, paths } from "../../types/paths"

export const articleContents: ArticleType[] = [
    {
        image: old_women,
        title: "What is Dementia",
        aspect_description: "Here are some key points on Dementia:",
        keyPoints: [
            "Dementia is an umbrella term for when someone loses memory, thinking, reasoning, social abilities, and the ability to perform everyday activities.",
            "Dementia includes various forms, such as Alzheimer's disease, vascular dementia, frontotemporal dementia, and Lewy body disease.",
            "While anyone can develop dementia, it tends to be more frequent in people over 65."
        ],
        more_details: [],
        button: "More Information",
        buttonLink: "https://www.dementia.org.au/about-dementia"
    },
    {
        image: brains,
        title: "Types of Dementia",
        aspect_description: "Dementia comes in different forms, each with its own causes and symptoms. Here are some major types of Dementia:",
        keyPoints: [],
        more_details: [
            {
                name: "Alzheimer's Disease (60%)",
                block_description: "Alzheimer's is the most common form of dementia. It affects memory, thinking, and behavior.",
                causes: [
                    "Build-up of proteins (amyloid plaques and tau tangles) in the brain.",
                    "Genetic factors.",
                    "Age-related changes in the brain."
                ],
                symptoms: [
                    "Difficulty in recalling, particularly recent occurrences",
                    "Reiterating statements over and over",
                    "Lack of precision in daily discussions",
                    "Challenges with planning, problem-solving, organizing, and logical thinking",
                    "Increased time needed to complete routine activities",
                    "Issues with language, including finding appropriate words",
                    "Difficulties in remembering the current time, location, and identifying people",
                    "Resistance in initiating tasks due to lack of motivation",
                    "Noticeable shifts in behavior, personality, and emotional state"
                ]
            },
            {
                name: "Vascular Dementia (15%)",
                block_description: "Second most common dementia type, caused by conditions that block or reduce blood flow to the brain.",
                causes: [
                    "Stroke (either significant or small, unnoticed ones).",
                    "Atherosclerosis (narrowing of blood vessels to the brain).",
                    "High blood pressure, high cholesterol, diabetes."
                ],
                symptoms: [
                    "Impaired judgment or ability to make decisions, plan, or organize, rather than memory loss",
                    "Disorientation",
                    "Confusion",
                    "Trouble speaking or understanding speech",
                    "Unsteady walk",
                    "Sudden headache",
                    "Frequent mood changes"
                ]
            },
            {
                name: "Lewy Body Dementia (10%)",
                block_description: "Characterized by the presence of Lewy bodies, abnormal aggregates of protein in the brain's nerve cells.",
                causes: [
                    "The exact cause is unknown.",
                    "Development of Lewy bodies (abnormal deposits of a protein called alpha-synuclein) in the brain."
                ],
                symptoms: [
                    "Visual hallucinations",
                    "Movement disorders similar to Parkinson's disease (stiffness, slow movement)",
                    "Poor regulation of body functions (blood pressure, temperature)",
                    "Cognitive problems similar to Alzheimer's",
                    "Sleep disturbances",
                    "Fluctuating attention",
                    "Depression"
                ]
            },
            {
                name: "Frontotemporal Dementia (2%)",
                block_description: "An umbrella term for a group of brain disorders that primarily affect the frontal and temporal lobes of the brain, which are associated with personality, behavior, and language.",
                causes: [
                    "The exact cause is often unknown.",
                    "Genetic mutations in some cases."
                ],
                symptoms: [
                    "Changes in personality and behavior",
                    "Impulsivity",
                    "Apathy or inertia",
                    "Language difficulties",
                    "Trouble planning and sequencing (organizing thoughts and actions)",
                    "Poor judgment"
                ]
            },
            {
                name: "Alcohol-related Brain Injury (5%)",
                block_description: "Damage to the brain caused by excessive alcohol consumption over time.",
                causes: [
                    "Long-term excessive drinking",
                    "Thiamine (vitamin B1) deficiency, common in heavy drinkers"
                ],
                symptoms: [
                    "Memory loss",
                    "Impaired thinking",
                    "Poor judgment",
                    "Personality changes",
                    "Reduced coordination and balance",
                    "Learning difficulties"
                ]
            }
        ],
        button: "More Information",
        buttonLink: "https://www.dementia.org.au/about-dementia/types-dementia"
    },
    {
        image: symptoms,
        title: "Symptoms of Dementia",
        aspect_description: "Different types of dementia can vary in their symptoms, as each type may affect the brain in distinct ways, leading to variations in memory, behavior, and cognitive functions. Let's look at different types of Dementia for more information:",
        keyPoints: [],
        more_details: [],
        button: "Types of Dementia",
        buttonLink: paths.KnowDementia + "/" + knowDementaParams.Types
    },
    {
        image: smoking,
        title: "Dementia Prevention",
        aspect_description: " That's why the most effective strategy to avoid dementia is to be aware of your risk factors and reduce them through healthy living habits. While you can't alter some risk factors, such as your age and genetic background, there are numerous actions you can take to maintain your brain's youthfulness.",
        keyPoints: [],
        more_details: [
            {
                name: "Low Cognitive Reserve",
                block_description: "Engaging in activities that challenge your mind throughout your life helps to fortify the links between neurons in your brain, thereby enhancing the brain's ability to withstand damage as you age, a concept referred to as cognitive reserve. A higher cognitive reserve can lower the chances of developing dementia.",
                prevention: "Commit to being a lifelong learner—pick up a new language or musical instrument, or start a new hobby such as painting, carpentry, or cooking. Engage in games like chess, puzzles, card games, crosswords, sudoku, or video gaming. It's also vital to keep up strong connections with your friends and family."
            },
            {
                name: "Poor Sleep",
                block_description: "Sleep problems, like insomnia and issues with breathing during sleep, have been linked to a higher chance of getting dementia.",
                prevention: "Stay away from caffeine, alcohol, and eating right before you go to bed; make exercise a regular part of your life, create a consistent bedtime schedule, and reserve your bedroom just for sleep. If you're worried about your sleep, make sure to discuss it with your healthcare provider."
            },
            {
                name: "Smoking",
                block_description: "Research strongly shows that smoking can raise your chances of getting dementia. When you smoke, it makes the blood vessels in your brain and heart smaller, which means less oxygen and food reach your brain, causing it to age. Smoking is also a big risk for other health issues like diseases of the blood vessels in the brain, stroke, and heart disease, all of which can also make it more likely for you to get dementia.",
                prevention: "However, there's a silver lining: if you stop smoking, your risk of getting dementia could go down to that of a person who has never smoked."
            },
            {
                name: "Physical Inactivity",
                block_description: "Doing regular exercise might be one of the best ways to lower your chances of getting dementia. People who are active, doing moderate to high levels of exercise, tend to have a lower risk of developing dementia compared to those who don't move much. Exercise helps increase blood flow to your brain, providing it with oxygen and nutrients. Being active also helps prevent diseases like diabetes, heart disease, stroke, high blood pressure, obesity, and depression, all of which can make dementia more likely.",
                prevention: "Try to make exercise a part of your everyday routine. Choose activities that you find fun, such as walking, swimming, or cycling, and think about joining group classes for a chance to meet people. Begin with easy steps and set achievable goals."
            },
            {
                name: "Poor Diet",
                block_description: "Eating a nutritious and balanced diet can help lower the chances of getting dementia by reducing obesity and protecting the brain from oxidative damage. The Mediterranean and MIND diets, which suggest consuming lots of fruits, vegetables, legumes, whole grains, nuts, olive oil, and fish while minimizing processed foods, meat, sweets, and dairy, are linked to a lower risk of dementia. Choosing healthy foods also lessens the likelihood of other conditions that can lead to dementia, such as heart disease, stroke, obesity, and diabetes.",
                prevention: "Make sure to eat a variety of colorful fruits and vegetables, avoid foods that are highly processed, choose water as your main beverage, and try to cook meals at home more frequently. You might want to explore the Mediterranean or MIND diets or talk to your doctor about getting a referral to a dietitian nearby."
            },
            {
                name: "Heavy Alcohol Consumption",
                block_description: "Drinking a lot of alcohol is linked to a higher chance of developing dementia, along with other negative health effects in the short and long term. Drinking heavily over a long period can cause damage to the brain.",
                prevention: "You can lower the risks to your health over time by keeping your alcohol intake within the advised limits."
            },
            {
                name: "High Blood Pressure",
                block_description: "High blood pressure, or hypertension, leads to the hardening and narrowing of the body's arteries, which limits the supply of oxygen and nutrients to the brain. Using medications to lower high blood pressure has been linked to a lower risk of dementia. Managing high blood pressure can also decrease the likelihood of heart disease and stroke, which are significant risk factors for dementia.",
                prevention: "Be aware of your blood pressure levels, maintain a healthy diet, increase your physical activity, and follow your doctor's medication advice."
            },
            {
                name: "Underweight",
                block_description: "Individuals who are underweight during their middle years might face an increased risk of dementia later on. While weight loss in older age is frequently linked to chronic diseases and health issues that can raise dementia risk, being underweight could also suggest that you're not meeting your nutritional requirements, which might harm your brain.",
                prevention: "If you're losing weight without knowing why or if you're struggling to keep your weight at a healthy level, it's important to get help from a professional. Consulting a dietitian to make sure your body gets the necessary nutrients can also be beneficial."
            },
            {
                name: "Obesity",
                block_description: "Being overweight in your middle years increases the likelihood of facing dementia and is linked to other risk factors for dementia such as high blood pressure, diabetes, heart disease, and stroke.",
                prevention: "Focus on enhancing your overall health and wellness, not merely on shedding pounds. Aim for a diet rich in nutrients and engage in regular exercise to boost your health and physical condition. Always consult with your doctor before beginning any program for weight loss or exercise."
            },
            {
                name: "Existing Chronic Conditions",
                block_description: "Many medical problems, particularly those related to the heart or blood vessels, such as heart disease, stroke, diabetes, and chronic obstructive pulmonary disease, are linked to the risk of developing dementia.",
                prevention: "It's important to regularly visit your healthcare provider and manage any health conditions you may have. Keep an eye on important health metrics like your blood pressure, cholesterol, blood sugar levels, and weight. Follow your doctor's instructions for medications, strive to fully understand your health issues, and take an active role in your treatment. Effectively dealing with chronic conditions often requires changes to your lifestyle, such as losing weight, increasing physical activity, and improving your diet. Investing time and effort into these healthy lifestyle adjustments can also help lower your chances of getting dementia."
            },
            {
                name: "Mood Disorders",
                block_description: "Depression, anxiety, and similar mental health issues can raise the chance of developing dementia. This might be because they cause more inflammation in the brain, which can lead to brain damage, or because they increase the likelihood of other illnesses that are risk factors for dementia.",
                prevention: "If you're experiencing depression, anxiety, or any mental health problems, it's crucial to get help from a professional. Your healthcare provider can determine whether you need treatment, which might involve therapy or medication. Making small changes in your lifestyle can also be beneficial—make sure to exercise regularly, eat a healthy diet, and stay connected with friends, family, and your wider community."
            },
            {
                name: "High level of stress",
                block_description: "While it's normal to face some stress every day, long-term stress can contribute to the onset of dementia because the continual presence of stress hormones can harm brain cells. Minimizing stress in your life or improving your stress management skills can not only lower your dementia risk but also decrease the likelihood of other related risk factors, such as high blood pressure and depression.",
                prevention: "It's important to recognize early signs of stress, find ways to lessen stress in your daily life, and adopt effective strategies for handling stress to mitigate its negative impact. Managing stress effectively might involve setting achievable goals, engaging in regular exercise, practicing meditation, or seeking help from a healthcare provider or a mental health professional."
            },
            {
                name: "Low Sense of Community Belonging",
                block_description: "Research indicates that individuals who have lower levels of social interaction or feel isolated experience worse health outcomes and are at a higher risk for dementia than those who are more socially involved in their communities.",
                prevention: "Think about how you can be more active within your community, such as joining local groups, becoming part of a church community, or volunteering for something you're passionate about. It's also important to keep up close relationships with friends and family."
            },
        ],
        button: "More Information",
        buttonLink: "https://www.projectbiglife.ca/dementia-resources"
    },
    {
        image: fact,
        title: "Some Dementia Facts",
        aspect_description: "Dementia facts in Australia.",
        keyPoints: [
            "Dementia ranks as the second most common cause of death among Australians, with preliminary data indicating it may soon become the primary cause.",
            "It is the foremost cause of death among Australian women.",
            "As of 2024, it's estimated that over 421,000 Australians are living with dementia. If there are no significant medical advancements, this figure is predicted to rise to over 812,500 by 2054.",
            "In 2024, nearly 29,000 Australians are believed to have younger onset dementia, a number that is expected to increase to almost 41,000 by 2054. This condition affects individuals in their 30s, 40s, and 50s.",
            "Approximately two-thirds of individuals with dementia are estimated to reside within the community.",
            "Over two-thirds (68.1%) of individuals in aged care have moderate to severe cognitive impairments."
        ],
        more_details: [],
        button: "More Information",
        buttonLink: "https://www.dementia.org.au/about-dementia/dementia-facts-and-figures"
    },
]
