import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import DementiaPrevalenceRateLineChart from '../../D3Graphs/DementiaPrevalenceRateLineChart/DementiaPrevalenceRateLineChart';
import styles from "./DataVisPageDementiaRate.module.scss"

function DataVisPageDementiaRate() {
    return (
        <>
            <Row><Col><div className={styles.title}>Dementia Prevalence Rate by Gender among different Age Groups in Australia (June 2023)</div></Col></Row>
            <Row>
                {/* Bar Chart Column: Renders a bar chart showing the distribution of dementia cases */}
                <Col xs={{ span: 12, order: 1 }} xxl={{ span: 6, order: 1 }}>
                    <DementiaPrevalenceRateLineChart
                        width={700}
                        height={700}
                        margin={10}
                        data_url={process.env.REACT_APP_DEMENTIA_AGEWISE_PREVALENCE_RATES}
                    />
                </Col>
                {/* Information Column: Provides detailed textual analysis and insights */}
                <Col xs={{ span: 12, order: 2 }} xxl={{ span: 6, order: 2 }}>
                    <div className={styles.subTitle}>What is showing on the chart?</div>
                    <div className={styles.content}>We need to compare the susceptibility to dementia between genders by analyzing an equal number of individuals within the same age group.</div>
                    <div className={styles.content}>For instance, in the age group of 85-89 years, <span style={{ color: "yellow" }}>237 women out of 1000 women</span> have dementia, whereas only <span style={{ color: "yellow" }}>173 out of 1000 men</span> in the same age group are affected by the condition. </div>
                    <div className={styles.subTitle} style={{ marginTop: "50px" }}>Findings from the Chart</div>
                    <div className={styles.content}>
                        The chart illustrates an increase in dementia prevalence with age for both genders, notably accelerating after 75 years. It also shows that women consistently exhibit higher rates of dementia than men, especially significant in the 90+ age bracket where 47.9% of women versus 33.7% of men are affected. This indicates that women are disproportionately more susceptible to dementia in older age.
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default DataVisPageDementiaRate;