import * as React from 'react';
import styles from "./Rectangle.module.scss"
function Rectangle(props:{
    picture:string,
    brief:string,
    subtitle:string,
    onClick:()=>void
}) {
    return (
        <>
            <div onClick={props.onClick} className={styles.rectangle}>
                <div className={styles.picture}>
                    <img src={props.picture} alt="Hello"></img>
                </div>
                <div className={styles.brief}>{props.brief}</div>
                <div className={styles.bottom}>
                    <div className={styles.readMore}>Read More</div>
                    <div className={styles.subtitle}>{props.subtitle}</div>
                </div>
            </div>
        </>
    );
}

export default Rectangle;