import * as React from 'react';
import styles from "../TetrisGame/TetrisGame.module.scss"
import { Block, TetrisState, msgStyle } from '../gameTypes/types';
import TetrisBlockShape from './TetrisBlockShape';
import TetrisBoardComponent from './TetrisBoardComponent';
import { score_speed_conversion_double } from '../TetrisGame/others/tetrisGameCommonLogics';

function TetrisGamePanel(props: {
    state: TetrisState
}) {
    return (
        <div className={styles.panelWrapper}>
            {props.state.message ?
                <div style={msgStyle[props.state.message]} className={styles.banner}>{props.state.message}</div> :
                <div className={styles.banner}></div>}
            <div className={styles.panel}>
                <div className={styles.panel_left}>
                    <div className={styles.hold}>
                        <div className={styles.hold_label}>Hold</div>
                        <div className={styles.hold_content}>
                            {props.state.hold && <TetrisBlockShape block={props.state.hold} />}
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.info_label}>Score</div>
                        <div className={styles.info_content}>{props.state.score}</div>
                        <div className={styles.info_label}>Level</div>
                        <div className={styles.info_content}>{props.state.level}</div>
                    </div>
                </div>
                <div className={styles.panel_mid}>
                    {<TetrisBoardComponent tetrisState={props.state} />}
                </div>
                <div className={styles.panel_right}>
                    <div className={styles.next}>
                        <div className={styles.next_label}>Next</div>
                        <div className={styles.next_content}>
                            {(!props.state.upcoming.some(v => v === null)) && props.state.upcoming.map((b, i) => {
                                return <TetrisBlockShape key={i} block={b!} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TetrisGamePanel;