import holding_hands from "../../../../pictures/old_people/holding_hands.png"; // Importing image
import styles from "./HomeMain.module.scss"; // Importing styles
import flower from "../../../../pictures/flower/flower.svg"; // Importing image
import { useNavigate } from "react-router-dom"; // Importing hook for navigation
import { knowDementaParams, paths } from "../../../types/paths"; // Importing paths and parameters

// Main component definition
function Main() {
    const navigate = useNavigate(); // Initializing navigate function

    // Rendering Main component
    return (
        <>
            {/* Main image wrapper */}
            <div className={styles.imageWrapper}>
                {/* Main image */}
                <img src={holding_hands} alt="Holding hands" />
                {/* Overlay for text */}
                <div className={styles.overlay}></div>
                {/* Text block */}
                <div className={styles.textBlock}>
                    {/* Big word with highlighting */}
                    <div className={styles.bigWord}>
                        <span className={styles.kelso}>AGESHMART </span>
                        Is Here For you
                        <span className={styles.star}><i className="fa-solid fa-star"></i></span>
                    </div>
                    {/* Small word with highlighting */}
                    <div className={styles.smallWord}>
                        Be smarter than <span className={styles.dementia}>DEMENTIA</span>
                    </div>
                    {/* Buttons */}
                    <div className={styles.buttons}>
                        {/* Button to navigate to "What is Dementia" page */}
                        <button onClick={() => navigate(paths.KnowDementia + "/" + knowDementaParams.What)} className={styles.mainButton}>What is Dementia<i className="fa-solid fa-arrow-right"></i></button>
                        {/* Uncomment below to add more buttons */}
                        {/* <button className={styles.mainButton}>Obtain Brain Health<i className="fa-solid fa-arrow-right"></i></button> */}
                    </div>
                </div>
                {/* Animated element */}
                <div className={styles.animatedElement}>
                    <img src={flower} alt="flower" />
                </div>
            </div>
        </>
    );
}

export default Main; // Exporting Main component
