import * as React from 'react';
import { sections } from "../../types/sections"
import styles from "./RoutePath.module.scss"
import { useNavigate } from 'react-router-dom';
import { knowDementaParams, paths } from '../../types/paths';


function RoutePath(props: {
    path: sections[]
}) {
    const navigate = useNavigate();
    return (
        <>
            <div className={styles.container}>
                <div className={styles.path}>
                    {props.path.map((v, i) => (
                        i === props.path.length - 1 ?
                            <div className={styles.textLast} key={i}>{v}</div>
                            :
                            <React.Fragment key={i}>
                                <div
                                    onClick={() => {
                                        if (v === sections.Home) {
                                            navigate(paths.Home)
                                        } else if (v === sections.KnowDementia) {
                                            navigate(paths.KnowDementia + "/" + knowDementaParams.What)
                                        } else if (v === sections.DataVis) {
                                            navigate(paths.BrainHealth)
                                        } else if (v === sections.BrainHealth) {
                                            navigate(paths.BrainHealth)
                                        } else if (v === sections.Prediction) {
                                            navigate(paths.Prediction)
                                        }
                                    }}
                                    className={styles.text}>
                                    {v}
                                </div>
                                <i className="fa-solid fa-minus"></i>
                            </React.Fragment>
                    ))}
                </div>
            </div>
        </>
    );
}

export default RoutePath;