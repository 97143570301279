import React, { Component } from "react";
import Grid from "./Grid";
import Swal from "sweetalert2";
import '../Crossword.css'
export default class Crossword extends Component {
    constructor(props) {
        // console.log("CW-constructor");
        super(props);
        this.state = {
            data: {
                height: 13,
                width: 13,
                wordList: [],
                clues: [],
                answers: [],
                attempts: [],
                refs: [],
                revealedWords: [],
                currentFocus: 0,
                numberOfWords: 0,
                currentWord: null,
                clearNext: null,
                clearAll: false,
                orientation: ''
            },
            debug: false
        };
    }

    handleKeyPress = (event) => {
        // console.log("CW-handleKeyPress");
        const { currentWord, numberOfWords, currentFocus } = this.state.data;

        if (event.key === "Escape") {
            // console.log(event.key);
            this.setState({ debug: !this.state.debug });
        }

        if (event.key === "Backspace") {
            event.preventDefault();  // 阻止默认的后退浏览器行为
            if (currentFocus > 0) {
                const prevCell = currentFocus - 1;
                this.clearCellValue(prevCell);  // 清除单元格内容
                this.moveToNextCell(true);  // 移动光标到前一个单元格
            }

        }

        if (event.key === "Tab") {
            event.preventDefault();
            this.handleClueClick(
                event,
                currentWord === numberOfWords - 1 ? 0 : currentWord + 1
            );
        }
    };
    clearCellValue = (cellIndex) => {
        const { refs } = this.state.data;
        const cell = refs[cellIndex].current;
        if (cell && typeof cell.clearValue === 'function') {
            cell.clearValue();  // 调用 Cell 组件的 clearValue 方法清除内容
        }
    };



    componentDidUpdate(prevProps) { }

    componentDidMount() {
        // 示例数据
        const sampleCrosswordData = {
            wordList: [
                { word: 'teapot', clue: 'Nursery rhyme, I am a little.. .', x: 2, y: 1, orientation: 'across', number: 0 },
                { word: 'entitle', clue: 'Authorise.', x: 3, y: 1, orientation: 'down', number: 1 },
                { word: 'polio', clue: 'Paralysis disease.', x: 5, y: 1, orientation: 'down', number: 2 },
                { word: 'traitors', clue: 'betrayers.', x: 7, y: 1, orientation: 'down', number: 3 },
                { word: 'trout', clue: 'Freshwater fish.', x: 3, y: 5, orientation: 'across', number: 4 },
                { word: 'party', clue: 'Celebration.', x: 9, y: 2, orientation: 'down', number: 5 },
                { word: 'yob', clue: 'Loud.', x: 9, y: 6, orientation: 'across', number: 6 },
                { word: 'abuses', clue: 'Misuses or mistreats, often repeatedly.', x: 11, y: 5, orientation: 'down', number: 7 },
                { word: 'instalments', clue: 'repayments.', x: 1, y: 10, orientation: 'across', number: 8 },
                { word: 'non', clue: 'Unwelcome guest, persona ... grata.', x: 9, y: 10, orientation: 'down', number: 9 },
                { word: 'alarm', clue: 'Warning bell.', x: 5, y: 8, orientation: 'down', number: 10 },
            ]
        };

        // 更新状态以包含单词列表、线索及其他相关信息
        this.setState({
            data: {
                ...this.state.data,
                wordList: sampleCrosswordData.wordList,
                clues: sampleCrosswordData.wordList.map(item => item.clue),
                numberOfWords: sampleCrosswordData.wordList.length,
                answers: sampleCrosswordData.wordList.map((word, index) => ({
                    word: word.word,
                    number: index
                })),
                height: 13,
                width: 13,
                attempts: [],
                refs: [],
                revealedWords: [],
                currentFocus: 0,
                currentWord: null,
                clearNext: null,
                clearAll: false,

            }
        });
    }


    deleteClearedWord = (word) => {
        // console.log("CW-deleteClearedWord", word);
        let newAttempts = [];
        if (word === null) {
            this.setState((prevState) => ({
                data: {
                    ...this.state.data,
                    clearAll: false
                }
            }));
        } else {
            newAttempts = this.state.data.attempts.filter(
                (attempt) => word !== attempt.number
            );
        }

        this.setState(
            (prevState) => ({
                data: {
                    ...this.state.data,
                    attempts: newAttempts,
                    clearNext: null,
                    clearAll: false
                }
            })
            // console.log("DCW")
        );
    };

    addSolvedWord = (tuple) => {
        // console.log("CW-addSolvedWord", tuple);
        let { attempts } = this.state.data;
        let answeredIndices = [];

        tuple.word = tuple.word.toLowerCase();

        //prepare a list of indices already answered
        for (let i = 0; i < attempts.length; i++) {
            answeredIndices.push(attempts[i].number);
        }

        if (attempts.length !== 0) {
            if (answeredIndices.includes(tuple.number)) {
                //[0,2,3], tuple.number===2
                attempts[answeredIndices.indexOf(tuple.number)].word =
                    tuple.word;

                this.setState(
                    (prevState) => ({
                        data: {
                            ...this.state.data,
                            attempts: attempts
                        }
                    })
                    // console.log("Edited attempt ", tuple)
                );
            } else {
                //add an attempt
                if (!this.state.data.clearAll) {
                    this.setState(
                        (prevState) => ({
                            data: {
                                ...this.state.data,
                                attempts: [...this.state.data.attempts, tuple]
                            }
                        })
                        // console.log("Added attempt 1  ", tuple)
                    );
                }
            }
        } else {
            //add an attempt, check if word hasn't already been cleared

            if (!this.state.data.clearAll) {
                this.setState(
                    (prevState) => ({
                        data: {
                            ...this.state.data,
                            attempts: [...this.state.data.attempts, tuple]
                        }
                    })
                    // console.log("Added attempt 2", tuple)
                );
            }
        }
    };

    checkAnswers = () => {
        // console.log("CW-checkAnswers");
        const { attempts, answers } = this.state.data;

        // sortedAnswers
        let sa = attempts.slice(0);

        sa.sort((a, b) => {
            return a.number - b.number;
        });

        let score = 0;
        let newAttempts = sa;

        if (newAttempts.length === answers.length) {
            newAttempts.forEach((attempt, index) => {
                if (
                    answers[attempt.number].word === attempt.word &&
                    answers[index].number === attempt.number
                ) {
                    score += 1;
                }
            });

            if (score === answers.length) {
                Swal.fire("Correct!");
            } else {
                Swal.fire("Incorrect!");
            }
        } else {
            Swal.fire(
                "Please answer all " + attempts.length + " of " + answers.length
            );
        }
    };

    clearEverything = (reset) => {
        // console.log("CW-clearEverything");
        //first clear all revealed words, if any
        let { revealedWords, attempts } = this.state.data;

        if (revealedWords.length) {
            const len = revealedWords.length;
            for (let index = 0; index < len; index++) {
                revealedWords.pop();
            }
            this.setState((prevState) => ({
                data: {
                    ...this.state.data,
                    revealedWords: revealedWords,
                    clearNext: Math.random()
                }
            }));
        }

        //then clear all attempts
        if (attempts.length) {
            const len = attempts.length;

            for (let index = 0; index <= len; index++) {
                attempts.pop();
            }
            this.setState((prevState) => ({
                data: {
                    ...this.state.data,
                    attempts: attempts,
                    clearAll: true
                }
            }));
        }
    };

    checkThis = () => {
        const { currentWord, attempts, answers } = this.state.data;

        // 首先检查当前单词索引是否有效
        if (currentWord === null || currentWord === undefined) {
            Swal.fire("Please select a word to check.");
            return;
        }

        // 使用find查找attempts和answers中对应的项
        let attempt = attempts.find((a) => a.number === currentWord);
        let answer = answers.find((a) => a.number === currentWord);

        // 确保找到的对象存在并且包含必要的信息
        if (attempt && answer && attempt.word && answer.word) {
            if (attempt.word === answer.word) {
                Swal.fire("Correct");
            } else {
                Swal.fire("Incorrect");
            }
        } else {
            // 如果找不到对应的尝试或答案，或者缺少关键信息，通知用户
            Swal.fire("Incomplete or missing data for the selected word.");
        }
    };


    revealThis = (all) => {
        // console.log("CW-revealThis");
        let newRevealedWords = [];
        const { revealedWords } = this.state.data;

        if (all === true && !revealedWords.length) {
            this.state.data.wordList.forEach((data, index) =>
                newRevealedWords.push(index)
            );

            this.setState((prevState) => ({
                data: {
                    ...this.state.data,
                    revealedWords: newRevealedWords
                }
            }));
        } else {
            if (
                this.state.data.currentWord !== null &&
                this.state.data.revealedWords.indexOf(
                    this.state.data.currentWord
                ) === -1
            ) {
                // console.log("reveal this", this.state.data.currentWord);
                newRevealedWords.push(this.state.data.currentWord);

                this.setState((prevState) => ({
                    data: {
                        ...this.state.data,
                        revealedWords: [
                            ...this.state.data.revealedWords,
                            this.state.data.currentWord
                        ]
                    }
                }));
            }
        }
    };

    clearThis = () => {
        // console.log("CW-clearThis", currentWord);
        const { revealedWords, currentWord } = this.state.data;

        //currentWord is revealed
        if (revealedWords.includes(currentWord)) {
            let newRevealedWords = revealedWords.filter(
                (r) => r !== currentWord
            );

            this.setState((prevState) => ({
                data: {
                    ...this.state.data,
                    revealedWords: newRevealedWords,
                    clearNext: currentWord
                }
            }));
        } else {
            this.setState(
                (prevState) => ({
                    data: {
                        ...this.state.data,
                        clearNext: currentWord
                    }
                })
                // console.log("set clear word")
            );
        }
    };

    revealAll = () => {
        // console.log("CW-revealAll");
        this.revealThis(true);
    };

    /*     handleClueClick = (e, index) => {
            // console.log("CW-handleClueClick");
            let startingCell = 0;
    
            for (let i = 0; i < index; i++) {
                startingCell =
                    index === 0
                        ? 0
                        : (startingCell += this.state.data.wordList[i].word.length);
            }
    
            this.setState(
                { currentFocus: startingCell, currentWord: index },
                this.state.data.refs[startingCell].current.focus()
            );
        }; */
    handleClueClick = (index) => {
        // 计算开始单元格位置
        let startingCell = this.state.data.wordList[index].startIndex;

        // 设置当前焦点和当前单词
        this.setState({
            data: {
                ...this.state.data,
                currentFocus: startingCell,
                currentWord: index
            }
        }, () => {
            // 确保光标移动到正确的单元格
            if (this.state.data.refs[startingCell] && this.state.data.refs[startingCell].current) {
                this.state.data.refs[startingCell].current.focus();
            }
        });
    }

    /* moveToNextCell = (backwards) => {
        // console.log("CW-moveToNextCell");
        //all the cell change logic is in changeActiveCell
        //here we will just call changeActiveCell with parameters in a
        //loop

        const { currentFocus, refs } = this.state.data;
        let nextCell = 0;

        if (currentFocus < refs.length - 1) {
            if (backwards === true) {
                // console.log(currentFocus);
                nextCell = currentFocus === 0 ? 0 : currentFocus - 1;
            } else {
                nextCell = currentFocus + 1;
            }

            this.setState(
                { currentFocus: nextCell },
                this.state.data.refs[nextCell].current.focus()
            );
        } else {
            nextCell = 0;
            this.setState(
                { currentFocus: nextCell },
                this.state.data.refs[nextCell].current.focus()
            );
        }
    }; */
    moveToNextCell = (backwards) => {
        const { currentFocus, refs } = this.state.data;
        let nextCell;

        if (backwards) {
            // Move to previous cell or wrap around to the last cell if at the beginning
            nextCell = currentFocus > 0 ? currentFocus - 1 : refs.length - 1;
        } else {
            // Move to next cell or wrap around to the first cell if at the end
            nextCell = currentFocus < refs.length - 1 ? currentFocus + 1 : 0;
        }
        console.log('currentfocus: ' + currentFocus)

        // Check if the calculated next cell is within the bounds of refs
        if (nextCell >= 0 && nextCell < refs.length && refs[nextCell].current) {
            this.setState({ data: { ...this.state.data, currentFocus: nextCell } },
                () => refs[nextCell].current.focus()
            );
        }
    };

    /* changeActiveCell = (activeCell) => {
        // console.log("CW-changeActiveCell");
        let newActiveCell = 0,
            allPrevWords = 0,
            allCurWordChars = activeCell.index;

        for (let i = 0; i < activeCell.wordNum; i++) {
            allPrevWords += this.state.data.wordList[i].length;
        }

        newActiveCell = allPrevWords + allCurWordChars;

        this.setState((prevState) => ({
            data: {
                ...this.state.data,
                currentFocus: newActiveCell,
                currentWord: activeCell.wordNum
            }
        }));
    }; */
    changeActiveCell = (activeCell) => {
        let { wordList } = this.state.data;
        let newActiveCell = 0;
        let currentWordIndex = activeCell.wordNum;
        let startingIndex = 0;

        for (let i = 0; i < currentWordIndex; i++) {
            startingIndex += wordList[i].word.length;  // 假设每个单词之后有一个空白或分隔符
        }

        newActiveCell = startingIndex + activeCell.index;  // index 是当前单词中的字符位置

        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                currentFocus: newActiveCell,
                currentWord: currentWordIndex
            }
        }));
    };


    addToRefs = (ref) => {
        // console.log("CW-addToRefs");
        const { data } = this.state;
        this.setState((prevState) => ({
            data: {
                ...data,
                refs: prevState.data.refs.concat(ref)
            }
        }));
    };

    handleNewCurrentWord = (neWord) => {
        // console.log("CW-handleNewCurrentWord");
        this.setState((prevState) => ({
            data: {
                ...this.state.data,
                currentWord: neWord
            }
        }));
    };

    dumpDebugData = (e) => {
        // console.log("CW-dumpDebugData");
        e.preventDefault();
        const d = e.target.innerHTML;
        console.log(`=${d}=`, this.state.data[d]);
    };

    render() {
        // console.log("CW-render");

        const { wordList } = this.state.data;

        // Filter clues based on orientation
        const cluesAcross = wordList.filter(word => word.orientation === 'across')
            .map(word => ({
                clue: this.state.data.clues[word.number],
                globalIndex: word.number  // 使用全局索引
            }));

        const cluesDown = wordList.filter(word => word.orientation === 'down')
            .map(word => ({
                clue: this.state.data.clues[word.number],
                globalIndex: word.number  // 使用全局索引
            }));



        return (
            <div className="Container">
                <div className="CW-container" onKeyDown={this.handleKeyPress}>
                    <Grid
                        className="grid"
                        data={this.state.data}
                        addSolvedWord={this.addSolvedWord}
                        deleteClearedWord={this.deleteClearedWord}
                        addToRefs={this.addToRefs}
                        moveToNextCell={this.moveToNextCell}
                        changeActiveCell={this.changeActiveCell}
                        currentWord={this.state.data.currentWord}
                        handleNewCurrentWord={this.handleNewCurrentWord}
                        revealCurrentWord={this.revealThis}
                        checkCurrentWord={this.checkThis}
                        clearCurrentWord={this.clearThis}
                        revealAllWords={this.revealAll}
                        clearAllWords={this.clearEverything}
                        resetClearAllFlag={this.resetClearAllFlag}
                        resetAttempts={this.resetAttempts}
                    ></Grid>
                    {/*                     <div className="clues">
                        {this.state.data.clues.map((clue, index) => {
                            return (
                                <div
                                    className={
                                        this.state.data.currentWord === index
                                            ? "clue editing"
                                            : "clue "
                                    }
                                    key={clue}
                                >
                                    <li
                                        onClick={(e) =>
                                            this.handleClueClick(e, index)
                                        }
                                    >
                                        {clue}&nbsp;(
                                        {this.state.data.wordList[index].length}
                                        )
                                    </li>
                                </div>
                            );
                        })}
                    </div> */}
                    <div className="clues-container">
                        <div className="clues-across">
                            <h2>Across</h2>
                            {cluesAcross.map((item, index) => (
                                <div key={index} onClick={() => this.handleClueClick(item.globalIndex)}
                                    className={this.state.data.currentWord === item.globalIndex ? "clue editing" : "clue"}>
                                    {(item.globalIndex + 1) + '. ' + item.clue}
                                </div>
                            ))}
                        </div>
                        <div className="clues-down">
                            <h2>Down</h2>
                            {cluesDown.map((item, index) => (
                                <div key={index} onClick={() => this.handleClueClick(item.globalIndex)}
                                    className={this.state.data.currentWord === item.globalIndex ? "clue editing" : "clue"}>
                                    {(item.globalIndex + 1) + '. ' + item.clue}
                                </div>
                            ))}
                        </div>
                    </div>


                </div>
                <div className="buttons_crossword">
                    <button className="button" onClick={this.checkThis}>
                        Check This
                    </button>
                    <button className="button" onClick={this.revealThis}>
                        Reveal This
                    </button>
                    <button className="button" onClick={this.clearThis}>
                        Clear This
                    </button>
                    <button
                        className="button check-all"
                        onClick={this.checkAnswers}
                    >
                        Check All
                    </button>
                    <button className="button" onClick={this.revealAll}>
                        Reveal All
                    </button>
                    <button
                        className="button clear-all"
                        onClick={this.clearEverything}
                    >
                        Clear All
                    </button>
                </div>
            </div>
        );
    }
}
