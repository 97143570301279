import { combineSlices, configureStore } from '@reduxjs/toolkit'
import { windowWidthSlice } from './windowWidthSlice'
import { windowScrollSlice } from './windowScrollSlice'

const rootReducer = combineSlices(windowWidthSlice, windowScrollSlice)

export const store = configureStore({
    reducer: rootReducer
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch