import React from "react";
import "./functionButtons.css";
import eraser from '../eraser.jpg'; // Import image for eraser button
import undo from '../undo.png'; // Import image for undo button
import start from '../start.webp'; // Import image for start/new game button

// Define the props type for the FunctionButtons component
type FnBtnProps = {
  handleClick: (fn: string) => void; // Function to handle button clicks
}

// Define the FunctionButtons functional component
const FunctionButtons: React.FC<FnBtnProps> = ({ handleClick }) => {
  return (
    <div className="function-buttons">
      {/* Button for undo functionality */}
      <div className="fn-btn-wrapper">
        <div onClick={() => handleClick("undo")} className="fn-btn">
          <img src={undo} alt="undo"></img>{/* Display undo icon */}
        </div>
        Undo{/* Button label */}
      </div>
      {/* Button for eraser functionality */}
      <div className="fn-btn-wrapper">
        <div onClick={() => handleClick("erase")} className="fn-btn">
          <img src={eraser} alt="eraser" />{/* Display eraser icon */}
        </div>
        Eraser{/* Button label */}
      </div>

      {/* Button for starting a new game */}
      <div className="fn-btn-wrapper">
        <div onClick={() => handleClick("newgame")} className="fn-btn">
          <img src={start} alt="New Game" /> {/* Display start/new game icon */}
        </div>
        New Game
      </div>
    </div>
  )
}

export default FunctionButtons // Export the FunctionButtons component as default