import styles from "./PlaceDetails.module.scss"
import '../QRCode/GeneratePng.css'
import CustomImageDownloader from '../QRCode/GeneratePng'
import { Container, Col, Row } from "react-bootstrap"
import icon_address from "../../../pictures/hiking/icons/address.svg"
import icon_phone from "../../../pictures/hiking/icons/phone-number.svg"
import icon_rating from "../../../pictures/hiking/icons/rating.svg"
import icon_users from "../../../pictures/hiking/icons/users.svg"
import icon_fontGate from "../../../pictures/hiking/icons/front-gate-open.svg"
import icon_dog from "../../../pictures/hiking/icons/dog.svg"
import icon_sun from "../../../pictures/hiking/icons/sun.svg"
import icon_temperature from "../../../pictures/hiking/icons/temperature.svg"
import icon_airQuality from "../../../pictures/hiking/icons/air-quality.svg"

import icon_monday from "../../../pictures/hiking/icons/monday.svg"
import icon_tuesday from "../../../pictures/hiking/icons/tuesday.svg"
import icon_wednesday from "../../../pictures/hiking/icons/wednesday.svg"
import icon_thursday from "../../../pictures/hiking/icons/thursday.svg"
import icon_friday from "../../../pictures/hiking/icons/friday.svg"
import icon_saturday from "../../../pictures/hiking/icons/saturday.svg"
import icon_sunday from "../../../pictures/hiking/icons/sunday.svg"

export const PlaceDetails = ({ place, airData, reviews, placeDetail, weather }) => {
    if (!place || !airData || !reviews) return (
        <div className={styles.placeDetails}>
            <div className={styles.reminders}> Please select an address from Search Box.</div>
        </div>
    );

    // UV level
    function getUVDescription(uvi) {
        if (uvi >= 0 && uvi <= 2) {
            return "Weak"; // Weak
        } else if (uvi >= 3 && uvi <= 4) {
            return "Moderately weak"; // Moderately weak
        } else if (uvi >= 5 && uvi <= 6) {
            return "Strong"; // Strong
        } else if (uvi >= 7 && uvi <= 9) {
            return "Very strong"; // Very strong
        } else if (uvi >= 10) {
            return "Extremely strong"; // Extremely strong
        } else {
            return "No data"; // No data
        }
    }

    return (
        <div className={styles.placeDetails} id="place-details">
            <div className={styles.title}>{place.name}</div>
            <Container>
                <Row>
                    <Col xs={12}>
                        {place.photos && place.photos.length > 0 ? (
                            <img src={place.photos[0].getUrl()} alt="Place_img" style={{ width: "100%" }} />
                        ) : (
                            <p>No image available</p>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={styles.subTitle}>Basic Information</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Row><Col>
                            <div className={styles.info}>
                                <img src={icon_address} alt="icon_address" />
                                <p><span className={styles.key}>Address:</span> {place.vicinity}</p>
                            </div>
                        </Col></Row>
                        <Row><Col>
                            <div className={styles.info}>
                                <img src={icon_phone} alt="icon_phone" />
                                <p><span className={styles.key}>Phone Number:</span>
                                    {placeDetail.nationalPhoneNumber !== null && placeDetail.nationalPhoneNumber !== undefined ?
                                        (placeDetail.nationalPhoneNumber)
                                        : ('Not Available')}</p>
                            </div>
                        </Col></Row>
                        <Row><Col>
                            <div className={styles.info}>
                                <img src={icon_rating} alt="icon_rating" />
                                <p><span className={styles.key}>Rating:</span> {place.rating + ' (out of 5)' || 'Not Available'}</p>
                            </div>
                        </Col></Row>
                        <Row><Col>
                            <div className={styles.info}>
                                <img src={icon_users} alt="icon_users" />
                                <p><span className={styles.key}>Total Rating Users:</span> {place.user_ratings_total || 'Not Available'}</p>
                            </div>
                        </Col></Row>
                    </Col>
                    <Col xs={6}>
                        <Row><Col>
                            <div className={styles.info}>
                                <img src={icon_fontGate} alt="icon_fontGate" />
                                <p><span className={styles.key}>Status:</span> {
                                    place.opening_hours ? (
                                        <span>{place.opening_hours.open_now ? ' Open Now' : ' Closed Now'}</span>
                                    ) : (
                                        <span> Opening hours not available</span>
                                    )
                                }</p>
                            </div>
                        </Col></Row>
                        <Row><Col>
                            <div className={styles.info}>
                                <img src={icon_dog} alt="icon_dog" />
                                <p><span className={styles.key}>Dogs Allowed:</span> {
                                    placeDetail.allowsDogs ? (
                                        <span>{placeDetail.allowsDogs ? ' Allowed' : ' Not Allowed'}</span>)
                                        : (<span>No Data</span>)
                                }</p>
                            </div>
                        </Col></Row>
                        <Row><Col>
                            <div className={styles.info}>
                                <img src={icon_sun} alt="icon_sun" />
                                <p>
                                    <span className={styles.key}>UV Level:</span>
                                    {weather?.uvi !== undefined && weather?.uvi !== null ? (
                                        <>
                                            <span>{weather.uvi}</span>
                                            <span> ({getUVDescription(weather.uvi)})</span>
                                        </>
                                    ) : (
                                        <span>No Data</span>
                                    )}
                                </p>

                            </div>
                        </Col></Row>
                        <Row><Col>
                            <div className={styles.info}>

                                <img src={icon_temperature} alt="icon_temperature" />
                                <p><span className={styles.key}>Temperature: </span> {
                                    weather?.temp ? (
                                        <span>{weather.temp} °C</span>
                                    ) : (
                                        <span>No Data</span>
                                    )
                                }</p>
                            </div>
                        </Col></Row>
                        <Row><Col>
                            <div className={styles.info}>
                                <img src={icon_airQuality} alt="icon_airQuality" />
                                <p>
                                    <span className={styles.key}>Air Quality:</span> {
                                        // Check if category and aqi exists, otherwise display 'Not Available'
                                        airData?.indexes?.[0]?.category && airData?.indexes?.[0]?.aqi
                                            ? `${airData.indexes[0].category} (AQI: ${airData.indexes[0].aqi})`
                                            : 'Not Available'
                                    }
                                </p>

                            </div>
                        </Col></Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={styles.subTitle}>Opening Hours</div>
                    </Col>
                    <Col xs={12}>
                        {placeDetail && placeDetail.regularOpeningHours && placeDetail.regularOpeningHours.weekdayDescriptions && placeDetail.regularOpeningHours.weekdayDescriptions.length > 0 ?
                            <Row>
                                {placeDetail.regularOpeningHours.weekdayDescriptions.map((hours, index) => (
                                    <Col xs={6}>
                                        <div key={index} className={styles.info}>
                                            {hours.includes("Monday") && <><img src={icon_monday} alt="icon_monday" /> <p>{hours}</p></>}
                                            {hours.includes("Tuesday") && <><img src={icon_tuesday} alt="icon_tuesday" /> <p>{hours}</p></>}
                                            {hours.includes("Wednesday") && <><img src={icon_wednesday} alt="icon_wednesday" /> <p>{hours}</p></>}
                                            {hours.includes("Thursday") && <><img src={icon_thursday} alt="icon_thursday" /> <p>{hours}</p></>}
                                            {hours.includes("Friday") && <><img src={icon_friday} alt="icon_friday" /> <p>{hours}</p></>}
                                            {hours.includes("Saturday") && <><img src={icon_saturday} alt="icon_saturday" /> <p>{hours}</p></>}
                                            {hours.includes("Sunday") && <><img src={icon_sunday} alt="icon_sunday" /> <p>{hours}</p></>}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            : <p>No regular opening hours available.</p>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={styles.subTitle}>Reviews</div>
                    </Col>
                    <Col>
                        {reviews.map((review, index) => (
                            review.text?.text ? (
                                <div key={index} className={styles.reviewItem}>
                                    <p>{review.authorAttribution.displayName}:{' '}{review.text.text}</p>
                                    <hr />
                                </div>
                            ) : (<>No review provided.</>)
                        ))}
                    </Col>
                </Row>
            </Container>
            {/* <CustomImageDownloader place={place} airData={airData} reviews={reviews} placeDetail={placeDetail} weather={weather} /> */}
        </div>
    );
};

