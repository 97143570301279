import * as React from 'react';
import styles from "../TetrisGame/TetrisGame.module.scss"
import { Block, blockShapes } from '../gameTypes/types';

function TetrisBlockShape(props: {
    block: Block // I, J, O, L, Z, S, T
}) {
    const shape = blockShapes[props.block][0]
    return (
        <div className={styles.blockWrapper}>
            {shape.map((block_row, i) => {
                return (
                    <div key={i} className={styles.blockRowWrapper}>
                        {block_row.map((cell, j) => {
                            const class_name = cell?props.block : "cell_empty_hold"
                            return (
                                <div key={j} className={styles[class_name]}></div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    );
}

export default TetrisBlockShape;