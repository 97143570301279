import { Col, Container, Row } from 'react-bootstrap'; // Importing necessary components from react-bootstrap
import RoutePath from '../../components/RoutePath/RoutePath'; // Importing the RoutePath component
import { sections } from '../../types/sections'; // Importing sections from types
import styles from "./NotFoundPage.module.scss" // Importing styles specific to the NotFoundPage
import Main from '../../components/Mains/Main'; // Importing the Main component
import got_lost from "../../../pictures/mains/got_lost.png" // Importing the image for the Main component

function NotFoundPage() {
    return (
        <>
            {/* Main component displaying the 404 image */}
            <Main image={got_lost} mainTitle='404' />
            {/* RoutePath component for navigation */}
            <RoutePath path={[sections.Home, sections.NotFound]} />
            <Container>
                <Row>
                    <Col>
                        {/* Displaying the 404 message */}
                        <h1 className={styles.content}>404 not found.<br />You has come to the wrong place.</h1>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default NotFoundPage;
