import React, { useRef, useState, useMemo } from 'react';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import hiking from "../../../pictures/mains/hiking2.png"
import { sections } from "../../types/sections"
import Main from '../../components/Mains/Main';
import RoutePath from '../../components/RoutePath/RoutePath';
// import './suggestplaces.css'
import styles from "./suggestPlaces.module.scss"

import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption
} from "@reach/combobox"
import "@reach/combobox/styles.css"
import { PlaceDetails } from '../PlacesDetails/PlaceDetails';
import { Row, Container, Col } from 'react-bootstrap';

const libraries = ['places'];

const SuggestPlaces = React.memo(function SuggestPlaces() {
    const mapRef = useRef();
    const { isLoaded } = useLoadScript(useMemo(() => ({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries
    }), []));

    const [location, setLocation] = useState();
    const [hikingPlaces, setHikingPlaces] = useState([]);
    const [selected, setSelected] = useState(null);
    const [airData, setAirData] = useState(null)
    const [reviews, setReviews] = useState([])
    const [placeDetail, setPlaceDetail] = useState(null)
    const [weather, setWeather] = useState(null)
    const handleAddressSelect = ({ address, lat, lng }) => {
        const latlng = new window.google.maps.LatLng(lat, lng);
        setLocation(latlng);
        searchNearby(latlng);
    };
    //fetch place details
    // Fetches place details from the Google Places API
    async function fetchPlaceDetails(place_id) {

        const fields = "reviews,nationalPhoneNumber,allowsDogs,regularOpeningHours,formattedAddress";
        const languageCode = "en";

        const url = `https://places.googleapis.com/v1/places/${place_id}?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&fields=${fields}&languageCode=${languageCode}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setReviews(data.reviews)
            setPlaceDetail(data)
        } catch (error) {
            console.error("Failed to fetch place details:", error);
        }
    }
    //get air quality
    const fetchAirQuality = async (latitude, longitude) => {
        const url = `https://airquality.googleapis.com/v1/currentConditions:lookup?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
        const body = {
            location: {
                latitude: latitude,
                longitude: longitude
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data && data.indexes.length > 0) {
                setAirData(data);
            }
        } catch (error) {
            console.error('Failed to fetch air quality:', error);
        }
    };

    //get weather from the API
    const fetchWeather = async (latitude, longitude) => {
        const url = `https://www.ageshmart.com:8802/content/weather?latitude=${latitude}&longitude=${longitude}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            if (data != null && data.temp != null && data.uvi != null) {
                setWeather(data)
                console.log(data)
            }
        } catch (error) {
            console.error('Failed to fetch weather:', error);
        }
    };


    // search nearby info by lat and lng
    const searchNearby = (latlng) => {
        if (!isLoaded || !mapRef.current) return;

        const service = new window.google.maps.places.PlacesService(mapRef.current);
        const request = {
            location: latlng,
            radius: '4000',  // range m
            type: ['park'],  // search type
            keyword: 'hiking',  // key word
            languageCode: 'en'
        };

        service.nearbySearch(request, (results, status) => {
            if (status === "OK") {
                setHikingPlaces(results);
            }
        });
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <>
            <Main image={hiking} mainTitle='Hiking Advisor' />
            <RoutePath path={[sections.Home, sections.Hiking]} />
            <div className={styles.bg}>
                <Container fluid>
                    <Row>
                        <Col xs={12} lg={3}></Col>
                        <Col xs={12} lg={6}>
                            <div className={styles.title}>
                                Walk Your Way to a Lower Dementia Risk
                            </div>
                            <div className={styles.description}>
                                <a target="_blank" rel="noopener noreferrer" href='https://www.healthline.com/health-news/experts-say-walk-far-and-walk-quickly-to-reduce-your-risk-of-dementia#:~:text=Researchers%20say%20walking%20quickly%20as,to%20112%20steps%20per%20minute.'>
                                    <u>Research</u>
                                </a> shows that walking quickly and taking 4,000 to 10,000 steps daily can significantly reduce dementia risk. Our hiking advisor helps you achieve these goals with personalized hiking routes, making it easy to stay active and healthy. Enhance your brain health with our guided hikes, a balanced diet, mental stimulation, and social engagement. Join us to make your hikes both enjoyable and beneficial for your well-being.
                            </div>
                            <div className={styles.disclaimer}>
                                <i className="fa-solid fa-star"></i> Be careful about the hiking location you select to ensure safety.
                            </div>
                        </Col>
                        <Col xs={12} lg={3}></Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className={styles.inputWrapper}>
                                <PlacesAutocomplete onAddressSelect={handleAddressSelect} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <GoogleMap
                                mapContainerClassName={styles.suggestmapContainer}
                                center={location || { lat: -34.397, lng: 150.644 }}
                                zoom={14}
                                onLoad={map => mapRef.current = map}
                            >
                                {hikingPlaces.map(place => (
                                    <Marker
                                        key={place.place_id}
                                        position={place.geometry.location}
                                        onClick={() => {
                                            setSelected(selected => selected && selected.place_id === place.place_id ? null : place);
                                            fetchAirQuality(place.geometry.location.lat(), place.geometry.location.lng()); // Fetch air quality data on marker click
                                            fetchPlaceDetails(place.place_id);
                                            fetchWeather(place.geometry.location.lat(), place.geometry.location.lng());
                                        }}
                                    />
                                ))}
                            </GoogleMap>
                        </Col>
                        <Col xs={12} lg={6}>
                            <PlaceDetails place={selected} airData={airData} reviews={reviews} placeDetail={placeDetail} weather={weather} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );

});

const PlacesAutocomplete = ({ onAddressSelect }) => {
    const { ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions } = usePlacesAutocomplete();


    const handleSelect = async (address, place_id) => {
        setValue(address, false);
        clearSuggestions();
        // convert to geo
        const results = await getGeocode({ address });
        const { lat, lng } = getLatLng(results[0]);
        onAddressSelect({ address, lat, lng })

    }

    return (
        <Combobox onSelect={(address) => handleSelect(address)}>
            <ComboboxInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                className="combobox-input"
                placeholder="Search an address"
            />
            <ComboboxPopover >
                <ComboboxList>
                    {status === "OK" && data.map(({ place_id, description }) => (
                        <ComboboxOption key={place_id} value={description} />
                    ))}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    )
}


export default SuggestPlaces;