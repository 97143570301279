import * as React from 'react';
// Importing images and SVGs used on the page
import dataVis from "../../../pictures/mains/data_vis.png"
import Main from '../../components/Mains/Main';
import RoutePath from '../../components/RoutePath/RoutePath';
import { sections } from '../../types/sections';
// Importing D3 components for different types of charts
import DementiaTypesBubbleChart from '../../components/D3Graphs/DementiaTypesBubbleChart/DementiaTypesBubbleChart';
import DementiaPrevalenceRateLineChart from '../../components/D3Graphs/DementiaPrevalenceRateLineChart/DementiaPrevalenceRateLineChart';
import DementiaDistributionBarChart from '../../components/D3Graphs/DementiaDistributionBarChart/DementiaDistributionBarChart';
import { Col, Container, Row } from 'react-bootstrap';
import styles from "./DataVisPage.module.scss"
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import DataVisSwitchButton from '../../components/DataVisSwitchButton/DataVisSwitchButton';
import svg_bubble_black from "../../../pictures/dataVisPage/svg_bubble_black.svg"
import svg_bubble_white from "../../../pictures/dataVisPage/svg_bubble_white.svg"
import svg_line_black from "../../../pictures/dataVisPage/svg_line_black.svg"
import svg_line_white from "../../../pictures/dataVisPage/svg_line_white.svg"
import svg_bar_white from "../../../pictures/dataVisPage/svg_bar_white.svg"
import svg_bar_black from "../../../pictures/dataVisPage/svg_bar_black.svg"
import { paths } from '../../types/paths';

function DataVisPage() {
    const navigate = useNavigate(); // Hook for navigating programmatically.
    const location = useLocation(); // Access the location object representing the current URL.
    const { visNum } = useParams(); // Access dynamic parameters from the URL.

    // Effect to handle automatic scrolling to the top of the page.
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search); // Parse the query string for parameters.
        if (queryParams.get("goTop") === "true") {
            window.scrollTo(0, 0); // Scrolls the window to the top if the 'goTop' parameter is 'true'.
        }
    }, [location]); // Depend on the location to re-run the effect when it changes.

    return (
        <>
            <Main image={dataVis} mainTitle='Dementia Statistics' /> {/*Main banner component*/}
            <RoutePath path={[sections.Home, sections.DataVis]} /> {/*Breadcrumb component for navigation trace*/}
            <div className={styles.wrapper}>
                <div className={styles.buttonSwitch}>
                    {/* Buttons for switching between different data visualizations */}
                    <DataVisSwitchButton
                        border={{ left: "none", right: "1px solid #556a5b" }}
                        showVis="1"
                        title="Dementia Types Distribution"
                        image_black={svg_bubble_black}
                        image_white={svg_bubble_white}
                    />
                    <DataVisSwitchButton
                        border={{ left: "none", right: "none" }}
                        showVis="2"
                        title="Dementia Cases Distribution"
                        image_black={svg_bar_black}
                        image_white={svg_bar_white}
                    />
                    <DataVisSwitchButton
                        border={{ left: "1px solid #556a5b", right: "none" }}
                        showVis="3"
                        title="Dementia Prevalence Rate"
                        image_black={svg_line_black}
                        image_white={svg_line_white}
                    />
                </div>
                <div className={styles.contentWrapper}>
                    <Container fluid>
                        {/* Conditional rendering based on the visualization number */}
                        {visNum === "1" && <>
                            <Row><Col><div className={styles.title}>Breakdown of Dementia by Disease Subtypes</div></Col></Row>
                            {/* Visualization and details for "Dementia Types Distribution" */}
                            <Row>
                                <Col xs={{ span: 12, order: 1 }} xxl={{ span: 6, order: 1 }}>
                                    <DementiaTypesBubbleChart
                                        width={700}
                                        height={500}
                                        margin={10}
                                        data_url={process.env.REACT_APP_DEMENTIA_TYPES}
                                    />
                                </Col>

                                <Col xs={{ span: 12, order: 2 }} xxl={{ span: 6, order: 2 }}>
                                    {/* Descriptive text and details about various types of dementia */}
                                    <div className={styles.starting}>Dementia encompasses a range of conditions that deteriorate cognitive abilities. Here are its main types, each with distinct symptoms and causes:</div>
                                    <div className={styles.dTypeWrapper}>
                                        {[
                                            { title: "Alzheimer’s Disease (60%)", description: "This is the most prevalent type of dementia, making up 60-70% of cases. It features amyloid plaques and neurofibrillary tangles in the brain, which lead to the death of brain cells." },
                                            { title: "Vascular Dementia (15%)", description: "This form arises from decreased blood flow to the brain, often due to a stroke, and can appear suddenly." },
                                            { title: "Lewy Body Dementia (10%)", description: "This type is identified by the accumulation of Lewy bodies, abnormal proteins in the brain. Common symptoms include hallucinations and disruptions in sleep." },
                                            { title: "Frontotemporal Dementia (FTD) (2%)", description: "FTD primarily impacts the brain's frontal and temporal lobes, causing shifts in behavior and a decline in language and social skills. It's more frequently seen in individuals under 65." },
                                            { title: "Alcohol-Related Brain Injuries (5%)", description: " Results from long-term heavy drinking; affects memory and thinking skills." },
                                            { title: "Other Dementia Types (8%)", description: "This type involves more than one dementia subtype, complicating diagnosis and management." },
                                        ].map((d, i) => {
                                            // Unique key for each item in the map for React's rendering efficiency
                                            return <React.Fragment key={i}>
                                                <div className={styles.subTitle}>{d.title}</div>
                                                <div className={styles.description}>{d.description}</div>
                                            </React.Fragment>
                                        })}
                                    </div>
                                    <div className={styles.ending}>Understanding, progression, and treatments of all types of dementia, especially the rarer forms, remain underdeveloped, awaiting further research and insights as awareness and resources increase.</div>
                                </Col>
                            </Row>
                        </>}
                        {/* Conditionally rendering components based on 'visNum' */}
                        {visNum === "2" && <>
                            {/* Title Row: Displaying the title of the visualization */}
                            <Row><Col><div className={styles.title}>Distribution of Dementia Cases by Gender among different Age Groups in Australia (June 2023)</div></Col></Row>
                            <Row>
                                <Col xs={{ span: 12, order: 1 }} xxl={{ span: 6, order: 1 }}>
                                    <DementiaDistributionBarChart
                                        width={700}
                                        height={600}
                                        margin={{ top: 20, right: 30, bottom: 50, left: 100 }}
                                        data_url={process.env.REACT_APP_DEMENTIA_AGEWISE_DISTRIBUTION}
                                    />
                                </Col>
                                {/* Main Content Row: Contains the bar chart and associated textual information */}
                                <Col xs={{ span: 12, order: 2 }} xxl={{ span: 6, order: 2 }}>
                                    <div className={styles.subTitleSecond}>What is the strongest risk factor?</div>
                                    <div className={styles.contentSecond}><span style={{ fontWeight: "900", color: "yellow" }}>Age</span> is the strongest known risk factor for dementia. The likelihood of developing dementia rises significantly as people get older, particularly after the age of 65. The incidence of dementia doubles roughly every five years after the age of 65.</div>
                                    <div className={styles.subTitleSecond}>Do women have a higher risk of getting dementia?</div>
                                    <div className={styles.contentSecond}>Around twice as many women have Alzheimer's disease - the most common type of dementia - compared to men.</div>
                                    <div className={styles.contentSecond}>The main reason for this greater risk is because women live longer than men and old age is the biggest risk factor for this disease.</div>
                                    <div className={styles.subTitleSecond}>Limitation of the chart</div>
                                    <div className={styles.contentSecond}>The chart didn't show total population of Women and Men in different age groups, so we cannot infer women is more susceptible of getting dementia.</div>
                                    <div className={styles.centercenter}>
                                        {/* Navigation Button: Provides a link to another related visualization */}
                                        <button className={styles.myBut} onClick={() => {
                                            navigate(paths.DataVis + "/3" + "?goTop=false")
                                        }}>View Dementia Rates by Age and Gender <i className="fa-solid fa-arrow-right"></i></button>
                                    </div>
                                </Col>
                            </Row>
                        </>}
                        {/* Conditionally rendering components based on 'visNum' */}
                        {visNum === "3" && <>
                            <Row><Col><div className={styles.title}>Dementia Prevalence Rate by Gender among different Age Groups in Australia (June 2023)</div></Col></Row>
                            <Row>
                                {/* Bar Chart Column: Renders a bar chart showing the distribution of dementia cases */}
                                <Col xs={{ span: 12, order: 1 }} xxl={{ span: 6, order: 1 }}>
                                    <DementiaPrevalenceRateLineChart
                                        width={700}
                                        height={700}
                                        margin={10}
                                        data_url={process.env.REACT_APP_DEMENTIA_AGEWISE_PREVALENCE_RATES}
                                    />
                                </Col>
                                {/* Information Column: Provides detailed textual analysis and insights */}
                                <Col xs={{ span: 12, order: 2 }} xxl={{ span: 6, order: 2 }}>
                                    <div className={styles.subTitleThird}>What is showing on the chart?</div>
                                    <div className={styles.contentThird}>As we want to know which gender is more susceptible of getting dementia, so we need to compare both gender by looking at equal amount of people in that gender and age group.</div>
                                    <div className={styles.contentThird}>For example, <span style={{ color: "yellow" }}>237 women out of 1000 women</span> in 85-89 years old is having dementia, while only <span style={{ color: "yellow" }}>173 men out of 1000 men</span> in the same age group is having dementia.</div>
                                    <div className={styles.subTitleThird} style={{ marginTop: "50px" }}>Insights from the chart</div>
                                    <div className={`${styles.wrapperThird} ${styles.contentThird}`}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Age Group</th>
                                                    <th scope="col">% Change</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>30-59</td>
                                                    <td>-17%</td>
                                                </tr>
                                                <tr>
                                                    <td>60-64</td>
                                                    <td>+20%</td>
                                                </tr>
                                                <tr>
                                                    <td>65-69</td>
                                                    <td>+25%</td>
                                                </tr>
                                                <tr>
                                                    <td>70-74</td>
                                                    <td>+28%</td>
                                                </tr>
                                                <tr>
                                                    <td>75-79</td>
                                                    <td>+29%</td>
                                                </tr>
                                                <tr>
                                                    <td>80-84</td>
                                                    <td>+34%</td>
                                                </tr>
                                                <tr>
                                                    <td>85-89</td>
                                                    <td>+37%</td>
                                                </tr>
                                                <tr>
                                                    <td>90+</td>
                                                    <td>+42%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* Navigation Button: Provides a link to another related visualization */}
                                        <div>
                                            <div className={styles.contentThirdContent} style={{ color: "#61ce70" }}>
                                                The table on the left displays the percentage change in prevalence rates among women compared to men across various age groups.
                                            </div>
                                            <div className={styles.contentThirdContent}>
                                                <ol>
                                                    <li style={{ marginTop: "10px" }}>Starting from the age of 60, women are increasingly more likely to develop dementia compared to men, with this risk continuing to rise up to the age of 90 and beyond. This suggests that the older a woman gets, the higher her risk of developing dementia relative to men.</li>
                                                    <li style={{ marginTop: "10px" }}>The risk of developing dementia increases exponentially from less than 1% in the 30-59 age group to nearly 50% in those aged 90 and above. This implies that nearly half of all women over 90 years old are affected by dementia.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>}
                    </Container>
                </div>
            </div>
        </>
    );
}

export default DataVisPage;