import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SudokuGame from "../SudokuGame/App"
import styles from "./SukokuEntryPage.module.scss"
import sudoku_img from "../../../../pictures/brainGames/sudoku_image.png"

function SukokuEntryPage() {
    const [displaySudoku, setDisplaySudoku] = React.useState("none")
    React.useEffect(() => {
        function handleFullscreenChange() {
            if (!document.fullscreenElement) {
                setDisplaySudoku("none");
            }
        }

        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, []);


    return (<>
        <div className={styles.bg}>
            <Container>
                <Row>
                    <Col lg={6} xs={12}><img className={styles.my_img} src={sudoku_img} alt="suduku" /></Col>
                    <Col lg={6} xs={12}>
                        <div className={styles.descriptions}>
                            Engaging in mentally stimulating activities such as Sudoku <a target="_blank" rel="noopener noreferrer" href='https://www.unsw.edu.au/newsroom/news/2021/09/do-brain-training-apps--games-and-puzzles-enhance-brain-health-'><u>has been linked to a lower risk of dementia</u></a>. According to Dr. Nicole Kochan, engaging in complex activities like Sudoku and crosswords can build a "cognitive reserve" which helps mitigate the brain's natural aging process and buffers against age-related neurocognitive disorders. Research has shown that those who frequently engage in these challenging cognitive activities are less likely to develop mild cognitive impairment, a precursor to dementia. This suggests that activities like Sudoku can be part of a beneficial routine to support long-term brain health and cognitive function.
                        </div>
                        <div className={styles.descriptions}>
                            Let's try our simple Sudoku Game to exercise your brain.
                        </div>
                        <div
                            className={styles.mainButton}
                            onClick={() => {
                                let elem = document.getElementById("sudoku")!;
                                elem.requestFullscreen();
                                setDisplaySudoku("block")
                            }}
                        >Play Sudoku in Full Screen Mode <i className="fa-solid fa-arrow-right"></i></div>
                        <div id="sudoku" style={{ display: displaySudoku }}>
                            <div className={styles.sudoku_bg}>
                                <button
                                    onClick={()=>{
                                        if (document.fullscreenElement) {
                                            document.exitFullscreen()
                                        }
                                    }}
                                    className={styles.exitButton}
                                >
                                    <i className="fa-solid fa-left-from-bracket"></i> Exit Fullscreen Mode
                                </button>
                                <div className={styles.sudokuWrapper}>
                                    <SudokuGame />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    </>);
}

export default SukokuEntryPage;