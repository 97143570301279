import * as React from 'react';
// Importing images and SVGs used on the page
import dataVis from "../../../pictures/mains/data_vis.png"
import Main from '../../components/Mains/Main';
import RoutePath from '../../components/RoutePath/RoutePath';
import { sections } from '../../types/sections';
import { Container } from 'react-bootstrap';
import styles from "./DataVisPage.module.scss"
import { useLocation, useParams } from 'react-router-dom';
import DataVisSwitchButton from '../../components/DataVis/DataVisSwitchButton/DataVisSwitchButton';
import svg_bubble_black from "../../../pictures/dataVisPage/svg_bubble_black.svg"
import svg_bubble_white from "../../../pictures/dataVisPage/svg_bubble_white.svg"
import svg_line_black from "../../../pictures/dataVisPage/svg_line_black.svg"
import svg_line_white from "../../../pictures/dataVisPage/svg_line_white.svg"
import svg_bar_white from "../../../pictures/dataVisPage/svg_bar_white.svg"
import svg_bar_black from "../../../pictures/dataVisPage/svg_bar_black.svg"
import svg_donut_white from "../../../pictures/dataVisPage/svg_donut_white.svg"
import svg_donut_black from "../../../pictures/dataVisPage/svg_donut_black.svg"
import DataVisPageDementiaTypes from '../../components/DataVis/DataVisPage/DataVisPageDementiaTypes/DataVisPageDementiaTypes';
import DataVisPageDementiaCases from '../../components/DataVis/DataVisPage/DataVisPageDementiaCases/DataVisPageDementiaCases';
import DataVisPageDementiaRate from '../../components/DataVis/DataVisPage/DataVisPageDementiaRate/DataVisPageDementiaRate';
import DataVisPageDementiaSymptoms from '../../components/DataVis/DataVisPage/DataVisPageDementiaSymptoms/DataVisPageDementiaSymptoms';

function DataVisPage() {
    const location = useLocation(); // Access the location object representing the current URL.
    const { visNum } = useParams(); // Access dynamic parameters from the URL.

    // Effect to handle automatic scrolling to the top of the page.
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search); // Parse the query string for parameters.
        if (queryParams.get("goTop") === "true") {
            window.scrollTo(0, 0); // Scrolls the window to the top if the 'goTop' parameter is 'true'.
        }
    }, [location]); // Depend on the location to re-run the effect when it changes.

    return (
        <>
            <Main image={dataVis} mainTitle='Dementia Statistics' /> {/*Main banner component*/}
            <RoutePath path={[sections.Home, sections.DataVis]} /> {/*Breadcrumb component for navigation trace*/}
            <div className={styles.wrapper}>
                <div className={styles.buttonSwitch}>
                    {/* Buttons for switching between different data visualizations */}
                    <DataVisSwitchButton
                        border={{ left: "none", right: "1px solid #556a5b" }}
                        showVis="1"
                        title="Dementia Types Distribution"
                        image_black={svg_bubble_black}
                        image_white={svg_bubble_white}
                    />
                    <DataVisSwitchButton
                        border={{ left: "none", right: "1px solid #556a5b" }}
                        showVis="2"
                        title="Dementia Cases Distribution"
                        image_black={svg_bar_black}
                        image_white={svg_bar_white}
                    />
                    <DataVisSwitchButton
                        border={{ left: "none", right: "1px solid #556a5b" }}
                        showVis="3"
                        title="Dementia Prevalence Rate"
                        image_black={svg_line_black}
                        image_white={svg_line_white}
                    />
                    <DataVisSwitchButton
                        border={{ left: "none", right: "none" }}
                        showVis="4"
                        title="Dementia Symptoms"
                        image_black={svg_donut_black}
                        image_white={svg_donut_white}
                    />
                </div>
                <div className={styles.contentWrapper}>
                    <Container fluid>
                        {/* Conditional rendering based on the visualization number */}
                        {visNum === "1" && <DataVisPageDementiaTypes/>}
                        {visNum === "2" && <DataVisPageDementiaCases/>}
                        {visNum === "3" && <DataVisPageDementiaRate/>}
                        {visNum === "4" && <DataVisPageDementiaSymptoms/>}
                    </Container>
                </div>
            </div>
        </>
    );
}

export default DataVisPage;