export enum paths {
    Home = "/",
    KnowDementia = "/dementia",
    DataVis = "/data_vis",
    BrainHealth = "/brain_health",
    Prediction = "/predict",
    Hiking = "/hiking"
}

export enum knowDementaParams {
    What = "what",
    Types = "types",
    Symptoms = "symptoms",
    Prevention = "prevent",
    Facts = "facts",
}

export enum games {
    Crossword = "crossword",
    Tetris = "tetris",
    Sudoku = "sudoku"
}